import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode';
import user_img from '../Assets/user.jpg';
import { MdOutlineQrCodeScanner, MdEdit } from "react-icons/md";
import { FaIdCardAlt } from "react-icons/fa"
import BtnOutlined, { BtnDefault } from './BtnComponent';
import ModalOpener from './ModalOpener';
import { getUserProfile } from '../StateMgmt/Actions/userProfileActions';
import { getUser } from '../utils/utils';

const ProfileCard = ({ setShowProfile }) => {
    const status = true;
    const [action, setAction] = useState('');
    const [openPopup, setOpenPopup] = useState(false);
    const [upi, setUpi] = useState('');
    const [name, setName] = useState('');
    const [role, setRole] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [data, setData] = useState('');
    const [userRole, setUserRole] = useState('Admin')
    const [uData, setUData] = useState({});
    const [userImg, setUserImg]= useState(user_img);


    useEffect(() => {
        getUserProfile().then(({ payload: { userName, role, userAddress, phoneNo, upiId, userData } }) => {
            setUData(userData)
            setUpi(upiId);
            setName(userName);
            setRole(role);
            setAddress(userAddress);
            setPhone(phoneNo);
            setUserImg(userData?.profilePic||user_img);
        });
        const { role } = getUser();
        setUserRole(role)
    }, [openPopup])
    const generateQR = () => {
        if (userRole === 'Technician') {
            setShowProfile(false)
        }
        QRCode.toDataURL(upi, {
            width: 200,
            margin: 2,
            color: {
                dark: '#153B8C'
            }
        }, (err, upi) => {
            if (err) return console.error(err);
            setData(upi)
            setAction('ShowQR');
            setOpenPopup(true);
        })
    }

    const editProfile = () => {
        setOpenPopup(true);
        if (userRole === 'Technician') {
            setShowProfile(false)
            setAction('EditTechnicianProfile');
            setData(uData);
        } else {
            setAction('EditProfile');
            setData(uData);
        }

    }

    const showIDCard = () => {
        setShowProfile(false)
        setAction('ShowIDCard');
        setOpenPopup(true);
        setData(uData);
    }


    return (
        <div className='flex flex-col items-center bg-white rounded-lg p-12 border border-themeBlue-2 hover:bg-themeBlue-6 hover:shadow-themeBlue-2 shadow-xl shadow-themeBlue-4'>
            <div className='relative'>
                {status && <div className='h-4 w-4 bg-green-500 absolute top-1 right-1 rounded-full ring-white ring-4'></div>}
                {!status && <div className='h-4 w-4 bg-red-500 absolute top-1 right-1 rounded-full ring-white ring-4'></div>}
                <img src={userImg}
                    // alt='avatar'
                    className='h-28 ring-2 ring-offset-4 hover:ring-offset-8 hover:ring-themeBlue-1 rounded-full bg-slate-200 cursor-pointer' />
            </div>
            <div className='text-gray-600 font-semibold text-xl mt-4'>{name}</div>
            <div className='text-gray-400 text-md'>{role}</div>
            <div className='bg-themeBlue-4 dark:bg-gray-600 w-full h-0.5 mt-2'></div>

            {/* <div className='flex justify-between items-center my-4 gap-5'></div> */}
            <div className='flex flex-col my-4 gap-2'>
                <div className='flex mx-4 space-x-5 items-center font-semibold'>
                    <div className='text-gray-500'>Phone</div>
                    <div className='text-themeBlue-7 font-bold'>{phone}</div>
                </div>
                {/* <div className='flex mx-4 space-x-5 items-center font-semibold'>
                    <div className='text-gray-500'>Email</div>
                    <div className='text-themeBlue-7 font-bold'>nishant@gmail.com</div>
                </div> */}
                <div className='flex mx-4 space-x-5 items-center font-semibold'>
                    <div className='text-gray-500'>Address</div>
                    <div className='text-themeBlue-7 font-bold w-60'>{address}</div>
                </div>
                <div className='flex mx-4 space-x-5 items-center font-semibold'>
                    <div className='text-gray-500'>UPI Address</div>
                    <div className='text-themeBlue-7 font-bold'>{upi}</div>
                </div>
            </div>
            {/* {qrcode && <>
                <img src={qrcode} />
                <a href={qrcode} download='qrcode.png'>Download</a>
            </>} */}
            {action &&
                <ModalOpener
                    action={action}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    data={data}
                    id={upi}
                />

            }
            <div className='flex mt-3 gap-4'>
                <BtnDefault
                    variant="contained"
                    color="primary"
                    size="medium"
                    text="Edit Profile"
                    type="submit"
                    startIcon=<MdEdit />
                    onClick={editProfile}

                />
                <BtnDefault
                    variant="contained"
                    color="primary"
                    size="medium"
                    text="Show QR"
                    type="submit"
                    startIcon=<MdOutlineQrCodeScanner />
                    onClick={generateQR}
                />
                {userRole === 'Technician' && <BtnDefault
                    variant="contained"
                    color="primary"
                    size="medium"
                    text="View ID Card"
                    type="submit"
                    startIcon=<FaIdCardAlt />
                    onClick={showIDCard}
                />}
            </div>
        </div>
    )
}

export default ProfileCard