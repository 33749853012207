import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Rating, TextField, Typography } from '@mui/material';
import { CustomizeTextField } from '../lib/Styles/textField_style';
import postCustomerFeedback from '../CommonFunctions/postCustomerFeedback';



const CustomerFeedback = () => {
    const { feedback_trackid } = useParams();

    const [ratingValue, setRatingValue] = useState(0);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        data.rating = ratingValue
        data.feedbackTrackId = feedback_trackid
        await postCustomerFeedback(data);;
        navigate('/customer_feedback/thanks',{ state: { message: "Thankyou for your valuable feedback" } });
    }

    return (
        <div className='grid h-screen w-full'>
            <div className='flex flex-col justify-center'>
                <form onSubmit={handleSubmit(onSubmit)} className='max-w-[400px] w-full mx-auto bg-gray-50 shadow-xl shadow-slate-400 p-8 px-8 rounded-lg'>
                    <h2 className='text-2xl text-slate-800 font-bold text-center pb-5'>CUSTOMER FEEDBACK</h2>
                    <div className='flex flex-col text-gray-600 py-2'>
                        <Typography sx={{ color: '#153B8C', textAlign: 'center' }} >
                            Please rate the service provided to you
                        </Typography>
                        <span  style={{zoom: '134%'}} className='flex justify-center'>
                            <Rating
                                name="half-rating"
                                precision={0.5}
                                size="small"
                                value={ratingValue}
                                onChange={(event, newValue) => {
                                    setRatingValue(newValue);
                                }}
                            />
                        </span>
                        <div className='flex flex-col mt-4 gap-2'>
                            <Typography sx={{ color: '#153B8C', textAlign: 'center' }} >
                                Share your feedback with us
                            </Typography>
                            <CustomizeTextField
                                placeholder='Write here...'
                                variant='outlined'
                                multiline
                                rows={3}
                                name='customerFeedback'
                                {...register("customerFeedback", { required: 'Please add customer care feedback' })}

                            />
                        </div>

                    </div>
                    <button
                        className='w-full my-5 py-2 bg-themeBlue-1  hover:shadow-blue-500/30 text-white font-semibold rounded-lg'
                        type="submit"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    )
}


export default CustomerFeedback;
