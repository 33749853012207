import React from 'react';
import { MdOutlineReviews } from "react-icons/md";
import { FcRating } from "react-icons/fc";
import { VscFeedback } from "react-icons/vsc";
import { Rating } from '@mui/material';


const Feedback = ({ feedback = {} }) => {
  const { rating, customerFeedback, customerCareFeedback } = feedback;
  return (
    <div className="max-w-md mx-auto bg-white rounded-xl shadow-sm overflow-hidden md:max-w-2xl">
      <div className='w-full grid md:grid-cols-1 grid-cols-1 gap-4'>
        <div className='flex flex-col'>
          <div className='flex md:flex-row flex-col md:gap-4 items-center'>
            <div className="flex items-center gap-3 m-2">
              <div className='text-themeBlue-1'><FcRating /></div>
              <div className='text-zinc-700 font-semibold'>Rating</div>
            </div>
            <div className='flex items-center m-2 col-span-2'>
              <Rating name="read-only" value={rating} readOnly />
            </div>
          </div>
          <div className='flex md:flex-row flex-col md:gap-4 items-center'>
            <div className="flex col-span-2 items-center gap-3 m-2 text-gray-600 ">
              <div className='text-themeBlue-1'><VscFeedback /></div>
              <div className='text-zinc-700 font-semibold'>Customer Feedback</div>
            </div>
            <div className='flex items-center m-2 '>
              <div className='text-gray-600'>
                {customerFeedback}
              </div>
            </div>
          </div>
          <div className='flex md:flex-row flex-col md:gap-4 items-center'>
            <div className="flex col-span-2 items-center gap-3 m-2 text-gray-600 ">
              <div className='text-themeBlue-1'> <MdOutlineReviews /></div>
              <div className='text-zinc-700 font-semibold'>Customer Care Feedback</div>
            </div>
            <div className='flex items-center m-2'>
              <div className='text-gray-600'>
                {customerCareFeedback}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Feedback