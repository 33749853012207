import React, { useState } from 'react';
import { BsTelephonePlusFill, BsFillPersonFill } from "react-icons/bs";
import { BiCategoryAlt } from "react-icons/bi";
import { MdOutlineQrCodeScanner, MdModeEdit } from "react-icons/md";
import { BiUserX } from "react-icons/bi";
import { AssignmentInd, Preview, PostAdd, YoutubeSearchedFor, MarkChatRead, AddBox, Message, FilterAlt } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AddIcon from '@mui/icons-material/Add';
import AddModal from './Modals/AddModal';
// import DeleteModal from './Modals/DeleteModal';
// import DetailsModal from './Modals/DetailsModal';
import OpenCallForm from './EnterpriseForms/OpenCallForm';
import Report from './Report';
import Add_customer from './EnterpriseForms/Add_customer';
import Edit_customer from './EnterpriseForms/Edit_customer';
import AddMember from './EnterpriseForms/AddMember';
import EditMember from './EnterpriseForms/EditMember';
import RemoveMember from './EnterpriseForms/RemoveMember';
import ViewModal from './Modals/ViewModal';
import MemberDetails from './EnterpriseForms/MemberDetails';
import QRModal from './Modals/QRModal';
import EditProfile from './EnterpriseForms/EditProfile';
import Add_product from './EnterpriseForms/Add_product';
import Edit_product from './EnterpriseForms/Edit_product';
import Add_customer_address from './EnterpriseForms/Add_customer_address';
import ConfirmationModal from './Modals/ConfirmationModal';
import EditTechProfileForm from '../Pages/Technician/Components/Profile/EditTechProfileForm';
import IDCard from '../Pages/Technician/Components/Profile/IDCard';
import IDCardModal from './Modals/IDCardModal';
import GenerateQRForm from '../Pages/QRScanSystem/GenerateQRForm';
import ReadQR from '../Pages/QRScanSystem/ReadQR';
import AddProductTabs from './AddProductTabs';
import PartitionedModal from './Modals/PartitionedModal';
import Edit_SMS_creds from './EnterpriseForms/Edit_SMS_creds';
import ComplaintFilter from './EnterpriseForms/ComplaintFilter';
import InvoiceModal from './Modals/InvoiceModal';
import { VscSettings } from "react-icons/vsc";
import CreateCategory from './EnterpriseForms/CreateCategory';
import Create_category from './EnterpriseForms/Create_category';
import Edit_category from './EnterpriseForms/Edit_category';
import Add_product_brand from './EnterpriseForms/Add_product_brand';
import Edit_product_brand from './EnterpriseForms/Edit_product_brand';

const ModalOpener = (props) => {
    const {
        action,
        openPopup,
        setOpenPopup,
        status,
        data,
        id,
        setAction,
        setResult,
        productId,
        selectedProduct,
        setSoftReaload,
        ProductInvoice,
        setStatusFilter,
        customerNumber,
        setProductInvoice,
        setOpenInvoice,
        openInvoice,
        searchFlag
    } = props;
    console.log(action)
    switch (action) {
        case 'OpenNewCall':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<BsTelephonePlusFill />}
                header='Open New Call'
                formLayout={<OpenCallForm setOpenPopup={setOpenPopup} customerData={data} selectedProduct={selectedProduct} setAction={setAction} setProductInvoice={setProductInvoice} />}
            />
        case 'Assign':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<AssignmentInd />}
                header='Assign Complaint'
                formLayout={<Report status={status} complaintData={data} setOpenPopup={setOpenPopup} openPopup={openPopup} />}
            />
        case 'CheckStatus':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<YoutubeSearchedFor />}
                header='Check Status / Change Assignment'
                formLayout={<Report status={status} complaintData={data} setOpenPopup={setOpenPopup} openPopup={openPopup} />}
            />
        case 'PartPendingInputs':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<PostAdd />}
                header='Add Inputs'
                formLayout={<Report status={status} complaintData={data} setOpenPopup={setOpenPopup} openPopup={openPopup} />}
            />
        case 'ReplacementInputs':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<PostAdd />}
                header='Add Inputs'
                formLayout={<Report status={status} complaintData={data} setOpenPopup={setOpenPopup} openPopup={openPopup} />}
            />
        case 'Completed':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<MarkChatRead />}
                header='Add Inputs and Close Call'
                formLayout={<Report status={status} complaintData={data} setOpenPopup={setOpenPopup} openPopup={openPopup} />}
            />
        case 'Closed':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<Preview />}
                header='View Details'
                formLayout={<Report status={status} complaintData={data} setOpenPopup={setOpenPopup} />}
            />
        case 'Rejected':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<Preview />}
                header='View Details'
                formLayout={<Report status={status} complaintData={data} setOpenPopup={setOpenPopup} />}
            />
        case 'AddCustomer':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<AddIcon />}
                header='Add Customer'
                formLayout={<Add_customer setOpenPopup={setOpenPopup} customerNumber={customerNumber} searchFlag={searchFlag} />}
            />
        case 'EditCustomer':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<EditIcon />}
                header='Edit Customer'
                formLayout={<Edit_customer setOpenPopup={setOpenPopup} currentData={data} />}
            />
        case 'AddNewAddress':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<AddIcon />}
                header='Add New Address'
                formLayout={<Add_customer_address setOpenPopup={setOpenPopup} customerId={id} setSoftReaload={setSoftReaload} />}
            />
        case 'View Product Invoice':
            return <PartitionedModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<AddBox />}
                header='Register Product'
                data={data}
                ProductInvoice={ProductInvoice}
                sections={1}
            />
        case 'Show Product Invoice':
            return <InvoiceModal
                openInvoice={openInvoice}
                setOpenInvoice={setOpenInvoice}
                ProductInvoice={ProductInvoice}
            />
        case 'EditMember':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<EditIcon />}
                header='Edit Team Member'
                formLayout={<EditMember memberData={data} setOpenPopup={setOpenPopup} />}
            />
        case 'AddTeamMember':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<PersonAddAltIcon />}
                header='Add Team Member'
                formLayout={<AddMember setOpenPopup={setOpenPopup} />}
            />
        case 'MemberView':
            return <ViewModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                id={id}
                data={data}
                details=<MemberDetails data={data} />
                icon={<BsFillPersonFill />}
                header='Member Details'
            />
        case 'ShowQR':
            return <QRModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                id={id}
                data={data}
                icon={<MdOutlineQrCodeScanner />}
                header='Scan to Pay'
            />
        case 'EditProfile':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<MdModeEdit />}
                header='Edit Enterprise Profile'
                formLayout={<EditProfile userData={data} setOpenPopup={setOpenPopup} />}
            />
        case 'AddProduct':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<AddIcon />}
                header='Add New Product'
                formLayout={<Add_product setOpenPopup={setOpenPopup} />}
            />
        case 'EditProduct':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<EditIcon />}
                header='Edit Product'
                formLayout={<Edit_product setOpenPopup={setOpenPopup} productData={data} />}
            />
        case 'GetConfirmation':
            return <ConfirmationModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<BiUserX />}
                header='Customer Not Exist'
                setAction={setAction}
                details={<span>Customer not found ,want to add new customer ?</span>}
            />
        case 'EditTechnicianProfile':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<MdModeEdit />}
                header='Edit Technician Profile'
                formLayout={<EditTechProfileForm userData={data} setOpenPopup={setOpenPopup} />}
            />
        case 'ShowIDCard':
            return <IDCardModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                id={id}
                data={data}
                details=<IDCard data={data} />
                icon={<BsFillPersonFill />}
                header='Identity Card'
            />
        case 'GenerateQR':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<MdOutlineQrCodeScanner />}
                header='Generate More QR'
                formLayout={<GenerateQRForm setOpenPopup={setOpenPopup} productId={productId} />}
            />
        case 'ReadQR':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<MdOutlineQrCodeScanner />}
                header='Read QR'
                formLayout={<ReadQR setResult={setResult} setOpenPopup={setOpenPopup} />}
            />
        case 'AddProductList':
            return <PartitionedModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<AddBox />}
                header='Register Product'
                data={data}
                formLayout={<AddProductTabs setOpenPopup={setOpenPopup} data={data} />}
                sections={2}
            />
        case 'SetSMSCreds':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<AddBox />}
                header='Edit SMS Credendials'
                formLayout={<Edit_SMS_creds setOpenPopup={setOpenPopup} data={data} />}
            />
        case 'View Message':
            return <ViewModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<Message />}
                header='Sample Message Body'
                message={true}
                id={id}
            />
        case 'StatusFilter':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<FilterAlt />}
                header='Filter By Status and Date of Creation'
                formLayout={<ComplaintFilter setStatusFilter={setStatusFilter} setOpenPopup={setOpenPopup} />}
            />
        case 'CreateCategory':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<BiCategoryAlt />}
                header='Create Category'
                formLayout={<Create_category setOpenPopup={setOpenPopup} />}
            />
        case 'Editcategory':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<BiCategoryAlt />}
                header='Edit Category'
                formLayout={<Edit_category setOpenPopup={setOpenPopup} currentData={data} />}
            />
        case 'CreateBrand':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<BiCategoryAlt />}
                header='Create Brand'
                formLayout={<Add_product_brand setOpenPopup={setOpenPopup} />}
            />
        case 'Editbrand':
            return <AddModal
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                icon={<BiCategoryAlt />}
                header='Edit Brand'
                formLayout={<Edit_product_brand setOpenPopup={setOpenPopup} currentData={data} />}
            />
        case 'Remove Member':
            return <RemoveMember memberData={data} setOpenPopup={setOpenPopup} openPopup={openPopup} />
        default:
            return <div>Default Modal, no action received</div>
    }
}

export default ModalOpener