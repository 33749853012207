import api from "../utils/axios";
import { HiBell, HiOutlineUsers } from 'react-icons/hi';

export const getCallStats = async () => {
    const response = await api.get(`/api/v1/reports/complainCount?type=Today`);
    const responseObj = response.data.data;
    const data = responseObj.map((c_data, i) => {
        return Object.assign(
            cardData[i], c_data
        )
    })
    return data;
};

export const getCustomerFeedback = async () => {
    const [dayResponse, monthResponse] = await Promise.all([
        api.get(`/api/v1/reports/noFeedbackReciedToday`),
        api.get(`/api/v1/reports/noFeedbackReciedMonth`)
    ]);

    return [
        Object.assign({}, defaultFeedbackData[0], dayResponse.data.data),
        Object.assign({}, defaultFeedbackData[1], monthResponse.data.data)
    ]
};

export const getTechnicianRevenueCards = async () => {
    const response = await api.get(`/api/v1/reports/earning`);
    const responseObj = response.data.data;
    const data = responseObj.map((c_data, i) => {
        return Object.assign(
            technicianData[i], c_data
        )
    })
    return data;
};





const cardData = [
    {
        title: "COMPLAINTS TODAY",
        subTitle: "No. of Registered Complaints Today",
        percentage: "+32.40%",
        value: "5",
        status: "down",
        icon: <HiBell />,
    },
    {
        title: "COMPLAINTS THIS MONTH",
        subTitle: "No. of Registered Complaints This Month",
        percentage: "+38.40%",
        value: "45",
        status: "up",
        icon: <HiBell />,
    }
];

const defaultFeedbackData = [
    {
        title: "CUSTOMER FEEDBACK TODAY",
        subTitle: "No. of customer feedbacks received today",
        percentage: "+32.40%",
        value: "5",
        status: "down",
        icon: <HiOutlineUsers />,
        path: '/complaints1'
    },
    {
        title: "CUSTOMER FEEDBACK MONTH",
        subTitle: "No. of customer feedbacks received this month",
        percentage: "+38.40%",
        value: "45",
        status: "up",
        icon: <HiOutlineUsers />,
        path: '/complaints1'
    }
];

const technicianData = [
    {
        title: "EARNING TODAY",
        subTitle: "Technician Earning for Today",
        percentage: "+32.40%",
        value: "5",
        status: "down",
        icon: <HiBell />,
    },
    {
        title: "EARNING THIS MONTH",
        subTitle: "Technician Earning this Month",
        percentage: "+38.40%",
        value: "45",
        status: "up",
        icon: <HiBell />,
    }
];