import React from 'react';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Stack from '@mui/material/Stack';


const Documents = (props) => {
    const { afetrServiceImg, beforeServiceImg, invoiceFile } = props.docs
    return (
        <div className=''>
            <div className='flex flex-col w-full'>
                <div className="grid md:grid-cols-3 grid-cols-1 gap-2 gap-x-2 ">
                    <Stack className='w-full'>
                        <div class="flex flex-col items-center justify-center w-full">
                            <span className='text-sm text-gray-700 font-semibold'>Invoice</span>
                            <label class="flex flex-col items-center justify-center pl-4 w-full h-24 border-2 border-gray-300 hover:border-[#016FE2] border-solid rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                <div className="flex justify-start gap-2 pt-8 pb-6">
                                    {invoiceFile && (
                                        <div className='mb-2'>
                                            <a href={invoiceFile} className='' target="_blank">
                                                <ReceiptIcon fontSize='large' />
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </label>
                        </div>
                    </Stack>
                    <Stack className='w-full'>
                        <div class="flex flex-col items-center justify-start w-full">
                            <span className='text-sm text-gray-700 font-semibold'>Before Service</span>
                            <label class="flex flex-col items-center justify-center pl-4 w-full h-24 border-2 border-gray-300 hover:border-[#016FE2] border-solid rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                <div className="flex justify-center gap-2 pt-8 pb-6">
                                    {beforeServiceImg && (
                                        <div className='mb-2'>
                                            <img className='w-full h-20' src={beforeServiceImg} alt='image' />
                                        </div>
                                    )}
                                </div>
                            </label>
                        </div>
                    </Stack>
                    <Stack className='w-full'>
                        <div class="flex flex-col items-center justify-start w-full">
                            <span className='text-sm text-gray-700 font-semibold'>After Service</span>
                            <label class="flex flex-col items-center justify-center pl-4 w-full h-24 border-2 border-gray-300 hover:border-[#016FE2] border-solid rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                <div className="flex justify-center gap-2 pt-8 pb-6">
                                    {afetrServiceImg && (
                                        <div className='bg-themeBlue-5 mb-2'>
                                            <img className='w-full h-20' src={afetrServiceImg} alt='image' />
                                        </div>
                                    )}
                                </div>
                            </label>
                        </div>
                    </Stack>
                </div>
            </div>
        </div>

    )
}

export default Documents


