import React, { useState } from 'react'

const RadioOptions = (props) => {

    const { children, selectedIndex, onSelect, index, addressId } = props
    // const [isSelected, setIsSelected] = useState(selectedIndex)
    const isSelected = index === selectedIndex;
    return (
        <div
            className={`flex gap-3 w-80 items-center shadow cursor-pointer transition duration-300 mx-1 rounded-md px-2 py-3 text-xs
             font-bold text-slate-600 hover:shadow-md hover:bg-themeBlue-3 shadow-themeBlue-5 ${isSelected && 'bg-themeBlue-4'}`
            }
            onClick={() => onSelect(index)}
            >
            <div className={`rounded-full w-4 h-4 border border-themeBlue-1 transition ${isSelected && 'border-4 border-themeBlue-2 bg-themeBlue-4'}`}></div>
           {children}
        </div>
    )
}

export default RadioOptions