import React from 'react';
import { Dialog, DialogContent, DialogTitle, Box, Typography } from '@mui/material';
import RenderPdf from '../../Pages/RenderPdf';
import { FaFileInvoiceDollar } from 'react-icons/fa';
// https://sahayakv2.s3.ap-south-1.amazonaws.com/test_Product/Product-4-4-1db911d1-f555-4785-ba25-ff70a2f3d66d.jpeg
const design = {
    '& .MuiDialogContent-root': {
        justifyContent: 'center',
        alignItems: 'center',
    },
}

const PartitionedModal = (props) => {
    const { openPopup, setOpenPopup, formLayout, icon, header, id, data, sections, ProductInvoice } = props;
    console.log(ProductInvoice)
    const closeDialog = () => setOpenPopup(false);
    return (
        <Dialog open={openPopup} onClose={closeDialog} fullWidth maxWidth={sections === 2 ? "2xl" : "xl"} sx={design}>
            <div className={`grid ${sections === 2 ? 'grid-cols-5' : 'grid-cols-3'}`}>
                <div className='col-span-3'>
                    <div className='flex flex-col mt-2 py-4 text-themeBlue-2 bg-themeBlue-4 w-full'>
                        <div className='flex space-x-2 justify-center w-full'>
                            <FaFileInvoiceDollar />
                            <Typography className='text-sm font-extrabold tracking-wide'>
                                Invoice
                            </Typography>
                        </div>
                        {id && <div className='text-[#b67497] text-xs font-bold tracking-wide'>{id}</div>}
                    </div>
                    <DialogContent dividers className='justify-center space-y-2'>
                        {sections === 2 ?
                            <RenderPdf pdfLink={data.invoice} />
                            :
                            <RenderPdf pdfLink={ProductInvoice} />
                        }
                    </DialogContent>
                </div>
                {sections === 2 &&
                    <div className='col-span-2'>
                        <Box p={1} textAlign='center'>
                            <DialogTitle className='flex flex-col text-themeBlue-2 bg-themeBlue-4'>
                                <div className='flex space-x-2 justify-center'>
                                    {icon}
                                    <Typography className='text-sm font-extrabold tracking-wide'>
                                        {header}
                                    </Typography>
                                </div>
                                {id && <div className='text-[#b67497] text-xs font-bold tracking-wide'>{id}</div>}
                            </DialogTitle>
                            <DialogContent dividers className='justify-center space-y-2'>
                                {formLayout}
                            </DialogContent>
                        </Box>
                    </div>
                }

            </div>
        </Dialog>
    )
}

export default PartitionedModal