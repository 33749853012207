import api from "../utils/axios";


const getRoles = async () => {

    const res = await api.post('/api/v1/filter/role/getList');

    const roles = res.data.data.map(role => {
        role.label = role.roleName;
        return role;
    });
    return roles;

}

export default getRoles;