import React from 'react'

const Error = () => {
  return (
    <div>
    <h3>Error Page </h3>
    <span>Page Not Found</span>
    <h6>404</h6>
    </div>
    
  )
}

export default Error