const external_user = [
    {
        cust_id: 'CUST01',
        name: 'Vishal',
        product: 'Toilets-eco',
        phone_no: '7355256619',
        view_invoice: 'https://github.com/nishantdeb2/Sahayak-Client'
    },
    {
        cust_id: 'CUST02',
        name: 'Nishant',
        product: 'Toilets-eco',
        phone_no: '7355256619',
        view_invoice: 'https://github.com/nishantdeb2/Sahayak-Client'
    },
    {
        cust_id: 'CUST03',
        name: 'Subhanshu',
        product: 'Toilets-eco',
        phone_no: '7355256619',
        view_invoice: 'https://github.com/nishantdeb2/Sahayak-Client'
    },
    {
        cust_id: 'CUST04',
        name: 'Kittu',
        product: 'Toilets-eco',
        phone_no: '7355256619',
        view_invoice: 'https://github.com/nishantdeb2/Sahayak-Client'
    },
    {
        cust_id: 'CUST05',
        name: 'Marc',
        product: 'Toilets-eco',
        phone_no: '7355256619',
        view_invoice: 'https://github.com/nishantdeb2/Sahayak-Client'
    },
    // {
    //     cust_id : 'CUST06',
    //     name : 'May 18,2021',
    //     product : 'Toilets-eco',
    //     phone_no : '7355256619',
    //     service : 'Repair',
    //     status : 'Closed'
    // },
    // {
    //     cust_id : 'CUST07',
    //     name : 'Mar 09,2021',
    //     product : 'Toilets-eco',
    //     phone_no : '7355256619',
    //     service : 'Cleaning',
    //     status : 'Open'
    // },
    // {
    //     cust_id : 'CUST08',
    //     name : 'Feb 03,2023',
    //     product : 'Toilets-eco',
    //     phone_no : '7355256619',
    //     service : 'Repair',
    //     status : 'Closed'
    // },
    // {
    //     cust_id : 'CUST09',
    //     name : 'Jan 06,2022',
    //     product : 'Toilets-eco',
    //     phone_no : '7355256619',
    //     service : 'Cleaning',
    //     status : 'Closed'
    // },
    // {
    //     cust_id : 'CUST10',
    //     name : 'Aug 23,2021',
    //     product : 'Toilets-eco',
    //     phone_no : '7355256619',
    //     service : 'Repair',
    //     status : 'Open'
    // },
    // {
    //     cust_id : 'CUST11',
    //     name : 'Oct 19,2022',
    //     product : 'Toilets-eco',
    //     phone_no : '7355256619',
    //     service : 'Cleaning',
    //     status : 'Open'
    // },
    // {
    //     cust_id : 'CUST12',
    //     name : 'Jul 12,2022',
    //     product : 'Toilets-eco',
    //     phone_no : '7355256619',
    //     service : 'Cleaning',
    //     status : 'Open'
    // }
]

export default external_user;
