import React, { useState, useEffect } from 'react';
import Html5QrcodePlugin from './Html5QrcodePlugin.js';

const qrConfig = { fps: 15, qrbox: { width: 300, height: 300 } };
const ReadQR = ({ setResult, setOpenPopup }) => {
  const [data, setData] = useState("No result");
  const [selected, setSelected] = useState("environment");
  const onNewScanResult = (decodedText) => {
    console.log("App [result]", decodedText);
    console.log(decodedText);
    setData(decodedText);
    setResult(decodedText);
    setTimeout(() => { setOpenPopup(false) }, 1000)
};
  console.log(selected)
  return (
    <div>

      <div style={{ position: "relative" }}>
        <div id="reader" width="100%" />
        <Html5QrcodePlugin
                    fps={10}
                    qrbox={{ width: 350, height: 350 }}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
        />
      </div>
      <p>{data}</p>
    </div>
  )
}

export default ReadQR