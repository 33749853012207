import React, { useEffect, useState } from 'react';
import { Chart } from "react-google-charts";
import { getTechnicianPendingCallsBar } from '../../StateMgmt/Actions/StatChartActions';

const PendingCallBar = () => {
    const [barData, setBarData] = useState([])
    useEffect(() => {
        getTechnicianPendingCallsBar().then(({ payload }) => {
            setBarData(payload);
        });
    }, []);
    const options = {
        chart: {
            title: "Weekly Pending Calls",
            subtitle: "No. of calls pending per technician",
        },
    };
    return (
        <>
            <Chart
                chartType="Bar"
                width="100%"
                height="400px"
                data={barData}
                options={options}
                // spreadSheetUrl="https://docs.google.com/spreadsheets/d/1jN0iw0usssnsG1_oi-NXtuKfsUsGme09GsFidbqxFYA"
                // toolbarItems={[
                //     {
                //         type: "csv",
                //         datasource:
                //             "https://spreadsheets.google.com/tq?key=1jN0iw0usssnsG1_oi-NXtuKfsUsGme09GsFidbqxFYA",
                //     },
                // ]}
            />
        </>

    )
}
export default PendingCallBar

// const data = [
//     ["Technician", "Complaints Pending"],
//     ["John", 2],
//     ["Sam", 8],
//     ["Peter", 4],
//     ["Gary", 7],
//     ["Sandy", 6],
//     ["Mark", 3],
//     ["Carl", 4],
//     ["Tony", 9],
//     ["Mike", 2],
//     ["Rikky", 6],
//     ["Santner", 3],
//     ["Mack", 8],
//     ["Sanju", 9],
//     ["Pappu", 2],
//     ["Bunty", 6],
//     ["Kittu", 3],
//     ["Laddu", 8]
// ];
