import React, { useState } from 'react';
import { Box } from '@mui/material'
import CustomTextField from '../lib/Styles/textField_style';

const SearchInput = ({ label, filter }) => {
    return (
        <Box sx={{ minWidth: 120 }}>
            <CustomTextField
                sx={{ input: { color: '#fff' } }}
                placeholder='Type anything'
                variant='outlined'
                label={label}
                name='filter'
                onChange={(event) => { filter(event.target.value) }}
            />
        </Box>
    )
}

export default SearchInput


