import React, { useState } from 'react';
import { Container, Grid, Box, Stack } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
// import NavBar from '../../Components/Technician/NavBar';
import NavBar from '../Technician/Components/AppBar/NavBar';
import AssignedComplaint from './AssignedComplaint';
import theme from './Styles/TechnicianTheme';
import AssignedComplaints from './Components/Tables/AssignedComplaints';
import SimpleFooter from './Components/Footer/SimpleFooter';
import SideNav from './Components/Drawer/SideNav';
import { UIProvider } from './NavContext/Context';
import Profile from './Components/Profile/Profile';
import Complaints from './Components/ComplaintCard/Complaints';


const TechnicianLanding = () => {
    const [open, setOpen] = useState(false);

    return (
        <ThemeProvider theme={theme}>
            <Container
                maxWidth='false'
            // sx={{
            //     background: '#e8ebf4'
            // }}
            >
                <UIProvider>
                    <NavBar />
                    <Complaints />
                    {/* <AssignedComplaints /> */}
                    {/* <SimpleFooter /> */}
                    {/* <SideNav /> */}
                    <Profile />
                </UIProvider>

            </Container>
        </ThemeProvider>

    );
};

export default TechnicianLanding;


{/* <div>
            <NavBar setOpen={setOpen} open={open}/>
            <Grid container>
                <Grid item sm={2} xs={2}>
                    <SideNav setOpen={setOpen} open={open} />
                </Grid>
                <Grid item sm={7} xs={10}>
                    {/* <Feed /> 
                    <ComplaintTable />
                </Grid>
            </Grid>
        </div> */}