import { Divider, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { padding } from '@mui/system'
import React from 'react'
import { AppBarContainer, AppBarHeader, NavList, ActionIconsContainerMobile, ActionIconsContainerDesktop } from '../../Styles/AppBar'
import ProfileAction from './ProfileAction';
import { useUIContext } from '../../NavContext/Context';
// import brandLogo from '../../../../Images/logo.png'
import brandLogo from '../../../../Images/grafdoer.jpg';
import Logo from '../../../../Images/Sahayak Logo-1.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';




const AppBarDesktop = ({ matches, profilePic }) => {

    const { setDrawerOpen, setShowProfile } = useUIContext()

    return (

        <AppBarContainer >
            {/* <AppBarHeader>Technician Portal</AppBarHeader> */}

            <div className='flex'>
                <img src={brandLogo} alt='' />
                <div className='lg:flex items-end pb-2 gap-1 font-extralight text-xs hidden'>
                    <span className='pb-1'>Powered by </span>
                    <img className='h-7' src={Logo} alt="" />
                </div>
            </div>

            {/* <NavList type='row'>
                <Divider orientation='vertical' flexItem />
                <ListItemText sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingLeft: '10px',
                    paddingRight: '10px'
                }}>
                    Home
                </ListItemText>
                <Divider orientation='vertical' flexItem />
                <ListItemText sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingLeft: '10px',
                    paddingRight: '10px'
                }}>
                    Assigned Complaints
                </ListItemText>
                <Divider orientation='vertical' flexItem />
                <ListItemButton>
                    <ListItemIcon></ListItemIcon>
                </ListItemButton>
            </NavList> */}
            <ProfileAction matches={matches} profilePic={profilePic} setShowProfile={setShowProfile} />



        </AppBarContainer>


    )
}

export default AppBarDesktop