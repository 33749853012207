import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import BtnOutlined, { BtnDefault } from '../BtnComponent';
import { addNewProductCategory, addProductBrand } from '../../StateMgmt/Actions/productActions';

const Add_product_brand = ({ setOpenPopup }) => {
    const dispatch = useDispatch()
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [subCategories, setSubCategories] = useState([]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const onSubmit = async (data) => {
        const reqBody = data;
        reqBody.brandName = data.name;
        const resData = await addProductBrand(reqBody)
        setOpenPopup(false);
        dispatch(resData.payload);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
            <div className="grid gap-4 grid-cols-1 gap-x-8 ">
                <CustomizeTextField
                    className='w-full'
                    placeholder='Please enter brand name'
                    variant='outlined'
                    label='Brand Name'
                    name='brand'
                    {...register("name", { required: 'Brand name is required.' })}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                />
            </div>

            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <BtnDefault
                    variant="contained"
                    color="primary"
                    size="medium"
                    text="Submit"
                    type="submit"
                />
                <BtnOutlined
                    variant="outlined"
                    color="error"
                    size="medium"
                    text="Cancel"
                    onClick={() => setOpenPopup(false)}
                />
            </div>

        </form>
    )
}

export default Add_product_brand