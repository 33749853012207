import { styled } from '@mui/material/styles';
import { TextField, FormHelperText } from '@mui/material';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useForm } from "react-hook-form";
import BtnOutlined, { BtnDefault } from '../../Components/BtnComponent';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { getAgentList } from '../../StateMgmt/Actions/reportActions';



const ComplaintFilter = (props) => {
    const endDate = localStorage.getItem('endingDate');
    const startDate = localStorage.getItem('startingDate');
    const temp = localStorage.getItem('selectedStatus');
    const savedSatus =  temp ? JSON.parse(temp) : [];
    const [agentOptions, setAgentOptions] = useState([]);
    const temptech = localStorage.getItem('selectedTechinican');
    const techinicanState =  temp ? JSON.parse(temptech) : [];
    const { setOpenPopup, setStatusFilter } = props;
    const [selectedStatus, setSelectedStatus] = useState(savedSatus??[]);
    const [startingDate, setStartingDate] = useState(startDate ?? null);
    const [endingDate, setEndingDate] = useState(endDate ?? null);
    const [selectedTechinican, setSelectedTechinican] = useState(techinicanState??[]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();


    useEffect(() => {
        const { id } = props;
        getAgentList(id).then(({ payload }) => {
            setAgentOptions(payload);
        });
    }, []);
    const handleSelectStatus = (_, value, reason) => {
        // console.log(value)
        // const values = (value || []).map(val => val.label);
        setSelectedStatus(value);
        localStorage.setItem('selectedStatus', JSON.stringify(value));
    }

    const handleSelectTech = (_, value) => {
        setSelectedTechinican(value);
        localStorage.setItem('selectedTechinican', JSON.stringify(value));
    }


    const onSubmit = async (data) => {
        const reqBody = data;
        reqBody.status = (selectedStatus || []).map(val => val.label);
        reqBody.visitDateTime = {
            'startDate': moment(startingDate).toDate(),
            'endDate': moment(endingDate).toDate()
        }
        debugger
        reqBody.technicain = (selectedTechinican || []).map(val => val.id);
        setStatusFilter(JSON.stringify(reqBody));
        setOpenPopup(false)

    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
            <div className="grid gap-4 grid-cols-1 gap-x-8 ">
                <Stack spacing={2} sx={{ color: '#851851' }}>
                <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                    <Autocomplete
                        multiple
                        error={Boolean(errors.role)}
                        disableClearable
                        value={selectedStatus}
                        onChange={handleSelectStatus}
                        options={statusList}
                        renderInput={(params) => (
                            <CustomTextField
                                {...params}
                                label='Select Complaint Status'
                                name='status'
                            />
                        )}
                    />
                    <Autocomplete
                        multiple
                        error={Boolean(errors.role)}
                        disableClearable
                        value={selectedTechinican}
                        onChange={handleSelectTech}
                        options={agentOptions}
                        renderInput={(params) => (
                            <CustomTextField
                                {...params}
                                label='Select Technicain'
                                name='techician'
                            />
                        )}

                    />
                    </div>
                </Stack>
            </div>
            <FormHelperText>Select Date Range</FormHelperText>
            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Starting Date"
                        value={startingDate}
                        format="DD-MM-YYYY"
                        onChange={(newValue) => {
                            setStartingDate(newValue);
                            localStorage.setItem('startingDate', newValue);
                        }}
                        renderInput={(params) => (
                            <CustomTextField
                                {...params}
                                name='startingDate'
                            // {...register("dateOfPurchase", { required: 'Please provide date of purchase' })}
                            // error={Boolean(errors.dateOfPurchase)}
                            // helperText={errors.dateOfPurchase?.message}
                            />
                        )}
                    // maxDate={dayjs()}
                    />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Ending Date"
                        value={endingDate}
                        format="DD-MM-YYYY"
                        onChange={(newValue) => {
                            setEndingDate(newValue);
                            localStorage.setItem('endingDate', newValue);
                        }}
                        renderInput={(params) => (
                            <CustomTextField
                                {...params}
                                name='endingDate'
                            // {...register("dateOfPurchase", { required: 'Please provide date of purchase' })}
                            // error={Boolean(errors.dateOfPurchase)}
                            // helperText={errors.dateOfPurchase?.message}
                            />
                        )}
                    // maxDate={dayjs()}
                    />
                </LocalizationProvider>
            </div>

            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <BtnDefault
                    variant="contained"
                    color="primary"
                    size="medium"
                    text="Apply"
                    type="submit"
                />
                <BtnOutlined
                    variant="outlined"
                    color="error"
                    size="medium"
                    text="Cancel"
                    border='#016fe2'
                    onClick={() => setOpenPopup(false)}
                />
            </div>

        </form>
    )
}

export default ComplaintFilter

const statusList = [
    { label: 'UNASSIGNED' },
    { label: 'ASSIGNED' },
    { label: 'PART PENDING' },
    { label: 'REPLACEMENT' },
    { label: 'COMPLETED' },
    { label: 'CLOSED' },
    { label: 'REJECTED' },
    { label: 'SERVICE DUE' }
]

const CustomTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#153b8c',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#153b8c',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#016fe2',
        },
        '&:hover fieldset': {
            borderColor: '#153b8c',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#153b8c',
        },
    },
});
