import React, { useState } from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";


const RenderPdf = ({ pdfLink }) => {
    console.log(pdfLink)
    const docs = [
        {
            // uri: require("../Assets/SamplePDf.pdf"),
            uri: pdfLink
        },
    ];

    const [activeDocument, setActiveDocument] = useState(docs[0]);
    const handleDocumentChange = (pdfLink) => {
        setActiveDocument(pdfLink);
    };

    return (
        <div className="">
            {/* <h1>Sample react-doc-viewer</h1> */}
            <DocViewer
                pluginRenderers={DocViewerRenderers}
                documents={docs}
                activeDocument={activeDocument}
                initialActiveDocument={docs[1]}
                onDocumentChange={handleDocumentChange}

                config={{
                    header: {
                        disableHeader: false,
                        disableFileName: false,
                        retainURLParams: false,
                    },
                    csvDelimiter: ",", // "," as default,
                    pdfZoom: {
                        defaultZoom: 1.1, // 1 as default,
                        zoomJump: 0.2, // 0.1 as default,
                    },
                    pdfVerticalScrollByDefault: true, // false as default
                }}
                theme={{
                    primary: "#153B8C",
                    secondary: "#ffffff",
                    tertiary: "#b9c4dd",
                    textPrimary: "#ffffff",
                    textSecondary: "#5296d8",
                    textTertiary: "#00000099",
                    disableThemeScrollbar: false,
                }}
                style={{
                    height: 800,
                    color: '#fff',
                    '#react-doc-viewer #header-bar': {
                        backgroundColor: '#fff'
                    }

                }}
            />
        </div>
    );
}

export default RenderPdf


// {
//     let { state } = useLocation();
//     console.log(state)
//     const [shown, setShown] = useState(false);

//     return (
//         <>
//             <div>View Pdf</div>
//             {/* <div className='container'>
//                 {shown && ReactDOM.createPortal(modalBody(), document.body)}
//             </div> */}
//         </>

//     )
// }