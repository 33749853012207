import api from "../../utils/axios";
import { successResponse } from "../../utils/utils";

export const getTeamMembers = async (filter) => {
    const res = await api.get(`/api/v1/team/getMembers?filter=${filter}`);
    const members = res.data.data
    const memberList = (members || []).map(({ name, Addresses, Role, phoneNumber, userId, id, UserDetail, RoleId, profilePic }) => {
        const [address] = Addresses || [];
        const { bankAccountNumber, bankName, idNumber, idType, ratePerVist, ifscCode } = UserDetail || {};
        const { city, pinCode, state, addressLine } = address || {};
        const { roleName, isActive } = Role
        return {
            name,
            city,
            pinCode,
            roleName,
            isActive,
            phoneNumber,
            userId,
            id,
            state,
            bankAccountNumber,
            bankName,
            idNumber,
            idType,
            ratePerVist,
            addressLine,
            RoleId,
            ifscCode,
            profilePic
        };
    })
    return {
        type: "GET_TEAM_MEMBERS",
        payload: memberList,
    }
};


export const addTeamMember = async (data) => {
    const {
        name: name,
        phone: phoneNo,
        role: roleId,
        pin: pinCode,
        city: city,
        state: state,
        address: addressLine,
        ac_number: bankAccountNumber,
        id_type: idType,
        id_number: idNumber,
        rate: ratePerVist,
        bank: bankName,
        ifsc: ifscCode
    } = data
    const formattedReq = {
        member: {
            name,
            phoneNo,
            roleId
        },
        address: {
            pinCode,
            city,
            state,
            addressLine
        },
        memberDetail: {
            bankAccountNumber,
            idType,
            idNumber,
            ratePerVist,
            bankName,
            ifscCode
        }
    };
    const payload = { userDetail: JSON.stringify(formattedReq) }
    const res = await api.post('/api/v1/team/addMember', payload);
    successResponse("Member added successfully");
    return {
        type: "ADD_TEAM_MEMBER",
        payload: res.data.data
    }
};

export const editTeamMember = async (data) => {
    const {
        name: name,
        phone: phoneNo,
        role: roleId,
        pin: pinCode,
        city: city,
        state: state,
        address: addressLine,
        ac_number: bankAccountNumber,
        id_type: idType,
        id_number: idNumber,
        rate: ratePerVist,
        bank: bankName,
        ifsc: ifscCode,
        id: userId
    } = data

    const formattedResponse = {
        member: {
            name,
            phoneNo,
            roleId
        },
        address: {
            pinCode,
            city,
            state,
            addressLine
        },
        memberDetail: {
            bankAccountNumber,
            idType,
            idNumber,
            ratePerVist,
            bankName,
            ifscCode
        }
    };

    const payload = { userDetail: JSON.stringify(formattedResponse), userId }
    const res = await api.post('/api/v1/team/editMember', payload);
    successResponse("Member data updated successfully");
    return {
        type: "EDIT_TEAM_MEMBER"
    }

};

export const removeTeamMember = async ({id}) => {
    const payload = {
        userId: id
    };
    await api.post('/api/v1/team/blockMember', payload);
    successResponse("Member removed successfully");
    return {
        type: "REMOVE_TEAM_MEMBER"
    }
};

