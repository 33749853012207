import {
	HiOutlineUsers,
	HiOutlineCollection,
	HiOutlineDocumentReport,
	HiOutlineHome,
	HiOutlineUserGroup,
	HiOutlineQuestionMarkCircle,
	HiOutlineCog,
	HiOutlineBell,
	HiOutlineLogout,
} from 'react-icons/hi';
import { GiArchiveRegister } from 'react-icons/gi';
import { TfiViewListAlt } from 'react-icons/tfi';
import { RiBodyScanFill } from 'react-icons/ri';

export const DASHBOARD_SIDEBAR_LINKS = [
	{
		key: 'home',
		label: 'Home',
		path: '/home',
		icon: <HiOutlineHome />,
		admin: true,
		customer: false,
		operator: false
	},
	{
		key: 'r_complaints',
		label: 'Register Complaints',
		path: '/registercomplaints',
		icon: <GiArchiveRegister />,
		admin: true,
		customer: true,
		operator: false
	},
	{
		key: 'complaints',
		label: 'View Complaints',
		path: '/complaints',
		icon: <TfiViewListAlt />,
		admin: true,
		customer: false,
		operator: false
	},
	{
		key: 'customers',
		label: 'Customers',
		path: '/customers',
		icon: <HiOutlineUsers />,
		admin: true,
		customer: false,
		operator: false
	},
	{
		key: 'products',
		label: 'Products',
		path: '/products',
		icon: <HiOutlineCollection />,
		admin: true,
		customer: false,
		operator: false
	},
	{
		key: 'teams',
		label: 'Teams',
		path: '/teams',
		icon: <HiOutlineUserGroup />,
		admin: true,
		customer: false,
		operator: false
	},
	{
		key: 'complaints',
		label: 'User Feedback',
		path: '/cust_complaints',
		icon: <HiOutlineBell />,
		admin: false,
		customer: true,
		operator: false
	},

	{
		key: 'reports',
		label: 'Reports',
		path: '/reports',
		icon: <HiOutlineDocumentReport />,
		admin: true,
		customer: false,
		operator: false
	},
	{
		key: 'ex_users',
		label: 'QR Registrations',
		path: '/external_users',
		icon: <RiBodyScanFill />,
		admin: true,
		customer: false,
		operator: true
	},
	// {
	// 	key: 'settings',
	// 	label: 'Settings',
	// 	path: '#',
	// 	icon: <HiOutlineCog />,
	// 	margin: true
	// },
	// {
	// 	key: 'support',
	// 	label: 'Help & Support',
	// 	path: '#',
	// 	icon: <HiOutlineQuestionMarkCircle />,

	// },
	// {
	// 	key: 'logout',
	// 	label: 'Logout',
	// 	path: '#',
	// 	icon: <HiOutlineLogout />,
	// 	onclick: true

	// }
]

// export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
// 	{
// 		key: 'settings',
// 		label: 'Settings',
// 		path: '#',
// 		icon: <HiOutlineCog />
// 	},
// 	{
// 		key: 'support',
// 		label: 'Help & Support',
// 		path: '#',
// 		icon: <HiOutlineQuestionMarkCircle />
// 	}
// ]
