import React, { useState } from 'react';
import RadioOptions from './RadioOptions';
import ModalOpener from './ModalOpener';
import { BtnDefault } from './BtnComponent';
import { MdAddLocationAlt } from "react-icons/md";
import { setUserAddressId } from '../CommonFunctions/setAddressId';

const RadioGrp = ({ options, onChange, labelText, customerId, setSoftReaload }) => {
    
    const [openPopup, setOpenPopup] = useState(false);
    
    const [action, setAction] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(0);
    const onSelect = (index, addressId) => {
        setUserAddressId(addressId);
        setSelectedIndex(index);
        onChange && onChange(index);
    }
    return (
        <div className='flex flex-col bg-white rounded-md mx-4 p-4 mt-6 border border-themeBlue-1'>
            <div>
                {labelText && <label className='block text-gray-600 mb-2'>{labelText}</label>}
                <div className='flex justify-between'>
                    <div className='flex gap-2'>
                        {(options||[]).map((el, index) => (
                            <RadioOptions
                                key={index}
                                index={index}
                                selectedIndex={selectedIndex}
                                onSelect={(index => onSelect(index, el.id))}
                                addressId={el.id}
                            >
                                {/* {el} */}
                                <div className='flex'>
                                    <div className='flex flex-col gap-2'>
                                        <span className='text-sm'>{el.addressLine}</span>
                                        <span className=''>{el.city}</span>
                                        <span>{el.pinCode} {el.state}</span>
                                        <span>{el.country}</span>
                                    </div>
                                    {/* <FaRegAddressCard className='w-4' /> */}
                                </div>
                            </RadioOptions>
                        ))}
                    </div>
                    <div className='mt-20'>
                        <BtnDefault
                            variant="contained"
                            startIcon={<MdAddLocationAlt />}
                            color="error"
                            size="small"
                            text="Add New Address"
                            onClick={() => {
                                setOpenPopup(true)
                                setAction('AddNewAddress')
                            }}
                        />
                    </div>

                </div>
            </div>
            {action &&
                <ModalOpener
                    action={action}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    setSoftReaload={setSoftReaload}
                    id={customerId}
                />

            }
        </div>
    )
}

export default RadioGrp