import UploadFile from './UploadFile';

const GetStepperData = async (data) => {

    const {
        entName: accountName,
        description,
        regName: firstName,
        pinCode: { value: { postOffice, pinCode, district: city, state } },
        address,
        upi: upiId,
        logo
    } = data;

    const { imageUrl } = await uploadImage(logo);
    const formattedResponse = {
        entDetail: {
            accountName,
            description,
            logo: imageUrl
        },
        userDetail: {
            firstName,
            lastName: "",
            pinCode,
            city,
            state,
            addressLine: `${address}, ${postOffice}`
        },
        paymentDetail: { upiId }
    };
    return formattedResponse;
}


export default GetStepperData;

export const uploadImage = async (file, category='Account') => {
       const fileReader = new FileReader();
       fileReader.readAsDataURL(file);
       return new Promise((resolve,reject)=>{
           fileReader.onload = async (fileLoadedEvent) => {
               const file = fileLoadedEvent.target.result;
               const url = await UploadFile(file, category);
               resolve(url);
           }
       })
}

