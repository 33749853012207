import api from "../utils/axios";

const getProductData = async (productId) => {
    const res = await api.post('/api/v1/product/getDetails', { productId: productId });
    const product = res.data.data;
    const {
        ProductCategory: { categoryName },
        ProductSubCategory: { subCategoryName },
        ProductRange: { rangeName },
        Brand: { brandName },
        serviceOffered,
        warranty,
        image,
        ProductRangeId,
        BrandId,
        ProductCategoryId,
        ProductSubCategoryId,
    } = product;
    return { categoryName, subCategoryName, rangeName, brandName, serviceOffered, warranty, image, ProductRangeId, BrandId, ProductCategoryId, ProductSubCategoryId };
}

export default getProductData;