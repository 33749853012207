import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Rating from '@mui/material/Rating';
import BtnOutlined, { BtnDefault } from '../BtnComponent';
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import { Typography } from '@mui/material';
import { addCustomerCareFeedback } from '../../StateMgmt/Actions/reportActions';


const CustomerCareInputs = (props) => {

    const { setOpenPopup, status, callId } = props;
    const [ratingValue, setRatingValue] = useState(0);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const onSubmit = async (data) => {
        data.rating = ratingValue
        data.callId = callId
        await addCustomerCareFeedback(data);
        setOpenPopup(false)
    }


    return (
        <div className='mt-5'>
            <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
                <div className="grid gap-4 md:grid-cols-3 grid-cols-1 gap-x-8 ">
                    <CustomizeTextField
                        placeholder='Please enter description'
                        variant='outlined'
                        multiline
                        rows={2}
                        label='Customer Feedback'
                        name='customerFeedback'
                        {...register("customerFeedback", { required: 'Please add customer feedback' })}

                    />
                    <div style={{zoom: '125%'}}>
                        <Typography sx={{ color: '#153B8C' }}>Rating</Typography>
                        <Rating
                            name="half-rating"
                            precision={0.5}
                            size="small"
                            defaultValue={2}
                            value={ratingValue}
                            onChange={(event, newValue) => {
                                console.log(newValue)
                                setRatingValue(newValue);
                            }}
                        />
                    </div>

                    <CustomizeTextField
                        placeholder='Please enter description'
                        variant='outlined'
                        multiline
                        rows={2}
                        label='Customer Care Feedback'
                        name='customerCareFeedback'
                        {...register("customerCareFeedback", { required: 'Please add customer care feedback' })}

                    />
                </div>
                <div className="grid gap-4 md:grid-cols-2 grid-cols-1 gap-x-8 ">
                    <BtnDefault
                        variant="contained"
                        color="primary"
                        size="medium"
                        text="Save Changes"
                        type="submit"
                    />
                    <BtnOutlined
                        variant="outlined"
                        color="error"
                        size="medium"
                        text="Cancel"
                        border='#016fe2'
                        onClick={() => setOpenPopup(false)}
                    />
                </div>

            </form>
        </div>

    )
}

export default CustomerCareInputs


