import React from 'react';
import { BsFillPersonFill, BsFillTelephoneFill } from "react-icons/bs";
import { MdAlternateEmail } from "react-icons/md";
import { RiUserLocationFill } from "react-icons/ri"

const CustomerInfo = ({ customerDetail }) => {
    const { address, customerId, name, phoneNumber } = customerDetail;
    return (
        <div className="max-w-md mx-auto bg-white rounded-xl shadow-sm overflow-hidden md:max-w-2xl">
            <div className='w-full grid md:grid-cols-2 grid-cols-1 gap-4'>
                <div className='flex flex-col md:items-center'>
                    <div className='flex md:flex-row flex-col md:gap-4 items-center'>
                        <div className="flex items-center gap-3 md:m-2  ">
                            <div className='text-themeBlue-1'><BsFillPersonFill /></div>
                            <div className='text-zinc-700 font-semibold'>Name</div>
                        </div>
                        <div className='flex items-center md:m-2 mb-2  '>
                            <div className=' text-gray-600'>
                                {name}
                            </div>
                        </div>
                    </div>
                    <div className='flex md:flex-row flex-col md:gap-5 md:pl-[-2] items-center'>
                        <div className="flex items-center gap-3 md:m-2 text-gray-600 ">
                            <div className='text-themeBlue-1'> <MdAlternateEmail /></div>
                            <div className='text-zinc-700 font-semibold'>Customer ID</div>
                        </div>
                        <div className='flex items-center md:m-2 mb-2'>
                            <div className='text-gray-600'>
                                {customerId}
                            </div>
                        </div>
                    </div>
                    <div className='flex md:flex-row flex-col md:gap-4 items-center'>
                        <div className="flex items-center gap-3 md:m-2 text-gray-600 ">
                            <div className='text-themeBlue-1'> <BsFillTelephoneFill /></div>
                            <div className='text-zinc-700 font-semibold'>Phone</div>
                        </div>
                        <div className='flex items-center md:m-2'>
                            <div className='text-gray-600'>
                                {phoneNumber}
                            </div>
                        </div>
                    </div>

                </div>
                <div className='flex md:flex-row flex-col md:gap-4 items-center'>
                    <div className="flex items-center gap-3  text-gray-600 ">
                        <div className='text-themeBlue-1'> <RiUserLocationFill /></div>
                        <div className='text-zinc-700 font-semibold'>Address</div>
                    </div>
                    <div className='flex items-center md:w-full w-40 mb-2'>
                        <div className='flex'>
                            <div className='flex flex-wrap text-gray-600 text-sm justify-center'>
                                <span>{address}</span>
                            </div>
                        </div>,
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerInfo