import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Headers/Header';
import Footer from '../Footer';



const Layout = () => {
    return (
        <div className=''>
            <div className='flex h-full w-full bg-pack-train bg-cover'>
                <Header />
                <div className='flex flex-col z-10 w-full min-h-[130vh]'>
                    <div className='mt-0 relative'>{<Outlet />}</div>
                </div>
            </div>
            <div>
                {/* <Footer /> */}
            </div>
        </div>

    )
}

export default Layout