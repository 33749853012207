import React from 'react';
import {
    HiArrowNarrowDown,
    HiArrowNarrowUp
} from 'react-icons/hi'
import { FaMinus } from 'react-icons/fa'

const StatCards = ({ complaints1 }) => {
    console.log(complaints1)
    return (
        <div className='flex flex-wrap justify-center w-full mt-4 py-4 gap-6 -z-10'>
            {complaints1.map((items, i) => (
                <div className='flex flex-col p-8 w-96 bg-white rounded-lg gap-y-3 border shadow-themeBlue-4 shadow-md hover:bg-themeBlue-3 hover:shadow-lg hover:shadow-themeBlue-5'>
                    <div className='flex items-center gap-5'>
                        <div className='p-2 bg-themeBlue-1 rounded-lg'>
                            <span className='text-3xl text-white'>{items.icon}</span>
                        </div>
                        <div className='text-lg tracking-wide text-gray-700'>{items.title}</div>
                    </div>
                    <div className='text-md tracking-wide text-zinc-500'>{items.subTitle}</div>
                    <div className='flex items-center gap-5'>
                        <div className='text-xl font-semibold'>{items.value}</div>
                        <span className={`pl-2 text-xs font-medium ${items.status === 'Down' ? 'text-themeRed' : 'text-themeGreen'}`}>{items.percentage}%</span>
                        <div className={`p-1 rounded-full ${items.status === 'Down' ? 'border border-themeRed' : 'border border-themeGreen'}`}>
                            {items.percentage < 0 && <HiArrowNarrowDown className='fill-current text-themeRed' />}
                            {items.percentage > 0 && <HiArrowNarrowUp className='fill-current text-themeGreen' />}
                            {items.percentage == 0 && <FaMinus className='fill-current text-[#ffa500]' />}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default StatCards