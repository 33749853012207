const message_data = [
    {
        msg_id: "t1_ID",
        msg_body: "`Hi ${AgentName}${callId} has been assigned to you. Customer name ${customerName} Customer number ${customerNumber}. Kindly check portal for more details.Team ${accountName}",
    },
    {
        msg_id: "t2_ID",
        msg_body: "Dear ${customerName}, Your complaint has been registed successfully with complain id ${callId}. Technician will be assigned soon.Team ${accountName}",
    },
    {
        msg_id: "t3_ID",
        msg_body: "Dear ${customerName}, ${AgentName} will attent to your complain id ${callId} soon .You can contact agent on ${agentNumber}.Team ${accountName}",
    },
    {
        msg_id: "t4_ID",
        msg_body: "Dear admin, You have a new customer request. From: ${name} email: ${email} number: ${phoneNumber}company: ${company}",
    },
    {
        msg_id: "t5_ID",
        msg_body: "Welcome to Sahayak! Your one time verificaiton code is : ${otp}",
    },
    {
        msg_id: "t6_ID",
        msg_body: "Dear ${name}, ${technicianName} has completed service request ${callId}. Kindly share your valuable feedback on ${config.feedbackUrl}/${feebackTrackId}.Team ${accountName}",
    }
];

export default message_data;



