import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import AppBarMobile from './AppBarMobile';
import AppBarDesktop from './AppBarDesktop';
import { getUserProfile } from '../../../../StateMgmt/Actions/userProfileActions';


const NavBar = () => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));   //below md (mobile, tablet)
    const [userImg, setUserImg]= useState('');
    useEffect(()=>{
        getUserProfile().then(({ payload: { userData } }) => {
            setUserImg(userData?.profilePic);
        });
    },[])
    return (
        <>
            {matches ? <AppBarMobile matches={matches} profilePic={userImg} /> : <AppBarDesktop matches={matches} profilePic={userImg} />}
        </>
    )
}

export default NavBar