import React, { useState, useEffect } from 'react'
import { Chart } from "react-google-charts";
import { getTechnicianFeeStackBar } from '../../StateMgmt/Actions/StatChartActions';


const FeeStackedBar = () => {
    const [feeData, setFeeData] = useState([])
    useEffect(() => {
        getTechnicianFeeStackBar().then(({ payload }) => {
            setFeeData(payload);
        });
    }, []);
    const options = {
        title: "Monthly Earning of Technicians",
        chartArea: { width: "50%" },
        isStacked: true,
        hAxis: {
            title: "Months",
            minValue: 0,
        },
        vAxis: {
            title: "Fees",
        },
    };
    return (
        <>
            <Chart
                chartType="ColumnChart"
                width="100%"
                height="400px"
                data={feeData}
                options={options}
            // spreadSheetUrl="https://docs.google.com/spreadsheets/d/1jN0iw0usssnsG1_oi-NXtuKfsUsGme09GsFidbqxFYA"
            // toolbarItems={[
            //     {
            //         type: "csv",
            //         datasource:
            //             "https://spreadsheets.google.com/tq?key=1jN0iw0usssnsG1_oi-NXtuKfsUsGme09GsFidbqxFYA",
            //     },
            // ]}
            />
            <div id="toolbar_div"></div>

        </>
    );
}

export default FeeStackedBar



// const data = [
//     ["Month", "TA Amount", "DA Amount", "Technician Fee"],
//     ["Jan", 817, 800, 400],
//     ["Feb", 379, 369, 600],
//     ["Mar", 269, 289, 450],
//     ["Apr", 209, 195, 350],
//     ["May", 152, 151, 280],
//     ["Jun", 560, 350, 300],
//     ["Jul", 750, 500, 650],
//     ["Aug", 590, 650, 450],
//     ["Sep", 800, 650, 300],
//     ["Oct", 750, 560, 400],
//     ["Nov", 830, 540, 450],
//     ["Dec", 770, 450, 540],
// ];