import React, { useEffect, useState } from 'react'
import StatCards from './StatCards'
import AvgRatingLine from './AvgRatingLine'
import AvgRatingTable from './AvgRatingTable'
import { getCustomerFeedback } from '../../CommonFunctions/getCallStats'

const CustomerStats = () => {
  const [statCards, setStatCards] = useState([]);

  useEffect(() => {
    getCustomerFeedback().then(response => {
      console.log(response)
      setStatCards(response)
    });
  }, [])
  return (
    <div className='h-[52rem]'>
      <StatCards complaints1={statCards} />
      <div className='flex flex-col'>
        <div className='m-10 p-10 border hover:shadow-md hover:shadow-themeBlue-2 border-themeBlue-1 '>
            <span className='flex justify-center text-2xl font-sans font-bold'>Technician Rating</span>
            <AvgRatingTable />
          </div>
        <div className='m-10 p-10 border hover:shadow-md hover:shadow-themeBlue-2 border-themeBlue-1 '>
          <span className='flex justify-center text-2xl font-sans font-bold'>Technician Monthly Average Rating</span>
          <AvgRatingLine />
        </div>
      </div>

    </div>
  )
}

export default CustomerStats

