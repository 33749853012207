import React from 'react'
import Logo from '../../Images/Sahayak Logo-1.png'


const Header = () => {
    return (
        <header className="fixed w-full">
            <div className="mx-auto w-full py-3 px-3 sm:px-6 lg:px-8">
                <div className="pr-16 pt-8 sm:px-16 sm:text-center">
                    <div className='flex pb-2 items-center justify-center '>
                        <img className='' src={Logo} alt="" />
                    </div>
                </div>
                {/* <div className='bg-themeBlue-4 dark:bg-gray-600 w-full h-0.5 mt-2'></div> */}
            </div>
        </header>
    )
}

export default Header