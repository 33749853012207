import React, { useState, useRef } from 'react';
import { TextField, Stack, Autocomplete } from "@mui/material";
import { Controller, useFormContext } from 'react-hook-form';
import api from '../../utils/axios';

const Personal_detail = () => {
    const [options, setOptions] = useState([]);
    const [stateValue, setStateValue] = useState('');
    const [districtValue, setDistrictValue] = useState('');

    const
        { control,
            formState: { errors }
        } = useFormContext();

    const getPin = (searchTerm) => {

        const filterValue = searchTerm ? { filterValue: searchTerm } : {};

        api.post('/api/v1/filter/pinCode/getList', filterValue)
            .then(response => {
                const updatedOptions = response.data.data.rows.map((p) => {
                    return {
                        label: p.pinCode + ', ' + p.postOffice,
                        value: p
                    };
                });
                setOptions(updatedOptions);
            });
    };
    const onInputChange = (event, value, reason) => {
        if (value) {
            getPin(value);
           
        } else {
            setOptions([]);
        }
    };

    const getValue = (value, field) => {
        setDistrictValue(value.value.district);
        setStateValue(value.value.state)

    }

    return (
        <div className=''>
            <div className='justify-center w-full'>
                <form className='mx-auto bg-gray-50 shadow-xl shadow-slate-400 p-8 px-8 rounded-lg'>
                    <h2 className='text-xl text-slate-800 font-bold text-center pb-5'>PERSONAL DETAILS</h2>
                    <div className='flex-col space-y-4 text-gray-600 px-16 py-2'>
                        <div className="flex flex-wrap gap-8">
                            <div className='flex flex-col gap-4'>
                                <Controller
                                    control={control}
                                    name='regName'
                                    rules={{ required: 'Registrant Name is required' }}
                                    render={({ field }) => (
                                        <TextField
                                            className='w-80'
                                            placeholder='Please enter your name'
                                            variant='outlined'
                                            label='Registrant Name'
                                            {...field}
                                            error={Boolean(errors.regName)}
                                            helperText={errors.regName?.message}
                                        />
                                    )} />
                                <Controller
                                    control={control}
                                    name='city'
                                    render={({ field: { ref, value,setValue, onChange, ...field } }) => (
                                        <TextField
                                            className='w-80'
                                            placeholder='Please enter your city/district'
                                            variant='outlined'
                                            label='City / District'
                                            value={districtValue}
                                            onChange={event => {
                                                onChange(event);
                                            }}
                                           
                                            {...field}

                                        />
                                    )} />
                            </div>
                            <div className='flex flex-col gap-4'>
                                <Stack spacing={2} sx={{ color: '#851851' }}>
                                    <Controller
                                        control={control}
                                        name='pinCode'
                                        rules={{ required: 'Pin Code is required' }}
                                        render={({ field: { ref,value, onChange, ...field } }) => (
                                            <Autocomplete
                                                freeSolo
                                                options={options}
                                                onInputChange={onInputChange}
                                                onChange={(event, value) => {
                                                    onChange(value);
                                                    getValue(value, field)
                                                }}
                                             
                                                getOptionLabel={(option) => option.label}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className='w-80'
                                                        placeholder='Please enter PIN Code'
                                                        variant='outlined'
                                                        label='PIN Code'
                                                        {...field}

                                                        error={Boolean(errors.pinCode)}
                                                        helperText={errors.pinCode?.message}

                                                    />
                                                )}

                                            />

                                        )}

                                    />
                                </Stack>
                                <Controller
                                    control={control}
                                    name='state'
                                    render={({ field: { value, ...field } }) => (
                                        <TextField
                                            className='w-80'
                                            placeholder='Please enter your state'
                                            variant='outlined'
                                            label='State'
                                            value={stateValue}
                                            {...field}
                                        />
                                    )} />
                            </div>

                        </div>
                        <Controller
                            control={control}
                            name='address'
                            rules={{ required: 'Address is required' }}
                            render={({ field }) => (
                                <TextField
                                    className='w-full'
                                    placeholder='Please enter your address'
                                    variant='outlined'
                                    multiline
                                    rows={2}
                                    label='Address'
                                    {...field}
                                    error={Boolean(errors.address)}
                                    helperText={errors.address?.message}
                                />
                            )} />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Personal_detail

