import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { HiOutlineUserGroup } from "react-icons/hi";
import { Avatar, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SectionHeader from '../Components/Headers/SectionHeader';
import ActionBtns from '../Components/ActionBtns';
import gridStyle from '../lib/Styles/grid_style';
import ModalOpener from '../Components/ModalOpener';
import { getTeamMembers } from '../StateMgmt/Actions/teamActions';


const Teams = () => {

    const dispatch = useDispatch()

    const [pageSize, setPageSize] = useState(10);
    const [rowId, setRowId] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [action, setAction] = useState('');
    const [rowData, setRowData] = useState({});
    const [filter, setFilter] = useState([]);
    const [members, setMembers] = useState([]);
    const [teamFilter, setTeamFilter] = useState('');


    useEffect(() => {
        getTeamMembers(teamFilter).then((data) => {
            setMembers(data.payload)
            dispatch(members)
        });
    }, [openPopup, teamFilter])
    // dispatch(members)
    const columns = useMemo(() => [
        {
            field: 'photoURL',
            headerName: '',
            width: 80,
            renderCell: params => <Avatar src={params.row.profilePic} />,
            sortable: false,
            filterable: false,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'id',
            headerName: 'User ID',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            hideable: true
        },
        {
            field: 'userId',
            headerName: 'User ID',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 250,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'phoneNumber',
            headerName: 'Phone',
            width: 300,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'roleName',
            headerName: 'Role',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'isActive',
            headerName: 'Status',
            width: 200,
            type: 'boolean',
            editable: true,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 400,
            renderCell: params => <ActionBtns {...{ params, setOpenPopup, setAction, flag: 'Member' }} />,
            headerAlign: 'center',
            headerClassName: 'table--header',
            align: 'center'
        },
    ], [rowId])
    // rowId is passed as a dependency in useMemo since we've to render the btn if there is a change in the row

    return (

        <>
            <SectionHeader
                icon={<HiOutlineUserGroup />}
                headingText='Team Members'
                btnText="Add Member"
                setFilter={setFilter}
                data={members}
                column='name'
                searchLabel='Search Member'
                searchFlag={true}
                addBtnFlag={true}
                setOpenPopup={setOpenPopup}
                setAction={setAction}
                action='AddTeamMember'
                filter={setTeamFilter}
            />

            <Box
                sx={gridStyle}>
                <DataGrid
                    columns={columns}
                    rows={members}
                    getRowId={(row) => row.id}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowSpacing={params => ({
                        top: params.isFirstVisible ? 0 : 5,
                        bottom: params.isLastVisible ? 0 : 5
                    })}
                    onCellEditCommit={params => setRowId(params.id)}
                    onRowClick={params => {
                        setRowId(params.id);
                        setRowData(params.row)
                    }}
                    disableColumnMenu={true}
                    filterModel={{
                        items: filter
                    }}
                    columnVisibilityModel={{
                        id: false,
                    }}
                />
            </Box>
            {action &&

                <ModalOpener
                    action={action}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    data={rowData}
                    id={rowId}
                />

            }

        </>
    )
}

export default Teams;

// const stringAvatar = (fname, lname) => {
//     return {
//       sx: {
//         bgcolor: '#851851',
//       },
//       children: `${fname.split(' ')[0][0]}${lname.split(' ')[0][0]}`,
//     };
//   }
