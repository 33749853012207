import {
    HiUsers,
    HiDuplicate,
    HiUser,
    HiBell,
    HiUserGroup,
    HiOutlineGift
} from 'react-icons/hi'
import { MdOutlineLocalOffer } from 'react-icons/md';
import { RiCouponLine } from 'react-icons/ri'


const stats = [
    // {
    //     title: "USERS",
    //     subTitle : "No. of Active Users",
    //     percentage: "+32.40%",
    //     value: "245",
    //     status: "up",
    //     icon: <HiUser />
    // },
    {
        title: "TEAMS",
        subTitle: "No. of Teams",
        // percentage: "+32.40%",
        value: "userCount",
        // status: "up",
        icon: <HiUsers />,
        path: '/teams'
    },
    {
        title: "PRODUCTS",
        subTitle: "No. of Products",
        // percentage: "+32.40%",
        value: "productCount",
        // status: "down" ,
        icon: <HiDuplicate />,
        path: '/products'
    },
    {
        title: "CUSTOMERS",
        subTitle: "No. of Active Customers",
        // percentage: "+32.40%",
        value: "customerCount",
        // status: "up", 
        icon: <HiUserGroup />,
        path: '/customers'
    },
    {
        title: "COMPLAINTS",
        subTitle: "No. of Registered Complaints",
        // percentage: "+32.40%",
        value: "callCount",
        // status: "down",
        icon: <HiBell />,
        path: '/complaints'
    },
];

export const grafdoer_stats = [
    {
        title: "OFFERS",
        subTitle: "Existing offers",
        // percentage: "+32.40%",
        value: "userCount",
        // status: "up",
        icon: <MdOutlineLocalOffer />,
        path: '/loyality/offers'
    },
    {
        title: "GIFT GALLARY",
        subTitle: "No. of Gifts",
        // percentage: "+32.40%",
        value: "productCount",
        // status: "down" ,
        icon: <HiOutlineGift />,
        path: '/loyality/gifts'
    },
    {
        title: "BALANCE COUPON VALUES",
        subTitle: "No. of Balance Coupons",
        // percentage: "+32.40%",
        value: "customerCount",
        // status: "up", 
        icon: <RiCouponLine />,
        path: '/loyality/coupon_code'
    }
];

export default stats;