import { IconButton } from '@mui/material'
import React from 'react'
import { AppBarContainer, AppBarHeader } from '../../Styles/AppBar'
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ProfileAction from './ProfileAction';
import LogoutIcon from '@mui/icons-material/Logout';
import { Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import brandLogo from '../../../../Images/logo.png'
import brandLogo from '../../../../Images/grafdoer.jpg';
import Logo from '../../../../Images/Sahayak Logo-1.png'
import { useUIContext } from '../../NavContext/Context';
import { successResponse } from '../../../../utils/utils';


const AppBarMobile = ({ matches, profilePic }) => {
    const { setDrawerOpen, setShowProfile } = useUIContext()
    const navigate = useNavigate();

    return (
        <AppBarContainer>
            {/* <IconButton onClick={() => setDrawerOpen(true)}>
                <MenuIcon />
            </IconButton> */}
            {/* <AppBarHeader textAlign={'center'} variant='h4'>
                Technician Portal
            </AppBarHeader> */}

            <div className='lg:flex'>
                <img className="" src={brandLogo} alt='' />
                <div className='flex items-end pb-2 gap-1 font-extralight text-xs pl-6'>
                    <span className='pb-1'>Powered by </span>
                    <img className='h-5' src={Logo} alt="" />
                </div>
            </div>

            <IconButton>
                <div  onClick={() => setShowProfile(true)} >
                    <Avatar src={profilePic} />  
                </div>
            </IconButton>
            <IconButton>
                <LogoutIcon onClick={() => {
                    localStorage.clear(); 
                    successResponse('Successfully Logout!')
                    navigate('/login');
                    }} />
            </IconButton>
            {/* <ProfileAction matches={matches} /> */}
        </AppBarContainer>
    )
}

export default AppBarMobile