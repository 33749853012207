import api from "../utils/axios";


const downloadGeneratedQR = async (reqBody) => {
    const res = await api.post(`/api/v1/product/generatQRList`, reqBody);
    const QRDataList = res.data.data.fileUrl;
    const filename = QRDataList.split('/').pop();
    const link = document.createElement('a');
    link.href = QRDataList;
    link.setAttribute('download', filename); //or any other extension
    link.click();
    return QRDataList
}

export default downloadGeneratedQR;