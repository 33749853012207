import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { Box, Stepper, Step, StepLabel, Button, Typography, TextField } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import { CustomizeTextField } from '../lib/Styles/textField_style';
import BtnOutlined, { BtnDefault } from '../Components/BtnComponent';
import ModalOpener from '../Components/ModalOpener';
import { searchCustomer } from '../StateMgmt/Actions/customerActions';
import { useNavigate } from 'react-router-dom';



const RegisterComplaint = () => {

  const [openPopup, setOpenPopup] = useState(false);
  const [action, setAction] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [searchFlag, setSearchFlag] = useState('');
  const [inputNumber, setInputNumber] = useState('');
  const navigate = useNavigate();


  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm();

  const search = (event, flag) => {
    if (flag === 'PHONE_NUMBER') {
      setInputNumber(event.target.value)
    }
    setSearchInput(event.target.value);
    setSearchFlag(flag);
  }

  const handleClick = async (data) => {
    const res = await searchCustomer(searchInput, searchFlag, navigate)
    if (res.payload.message === "Customer not found") {
      setOpenPopup(true)
      setAction('GetConfirmation')
    }


  }

  return (
    <div className='md:container md:mx-auto h-screen pt-32'>
      <div className='content-center justify-center w-auto px-60 '>
        <form  className='mx-auto bg-gray-50 shadow-xl shadow-slate-400 p-8 px-8 rounded-lg'>
          <h2 className='text-xl text-slate-800 font-bold text-center pb-5'>SEARCH</h2>
          <div className='flex-col space-y-4 text-gray-600 px-16 py-2'>
            <div className='flex flex-col gap-4'>
              <CustomizeTextField
                placeholder='Enter Phone'
                variant='outlined'
                label='Search By Phone'
                name='cust_number'
                onChange={(e) => { search(e, 'PHONE_NUMBER') }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                }}
              />
              <div className='flex gap-2'>
                <div className='bg-themeBlue-1 dark:bg-gray-600 w-full h-1 mt-2'></div>
                <span>OR</span>
                <div className='bg-themeBlue-1 dark:bg-gray-600 w-full h-1 mt-2'></div>
              </div>
              <CustomizeTextField
                placeholder='Please enter Call ID'
                variant='outlined'
                label='Search By Call ID'
                name='cust_id'
                onChange={(e) => { search(e, 'CALL_ID') }}
              />

            </div>
            <div className='flex justify-end'>
              {/* <BtnOutlined
                variant="outlined"
                size="medium"
                text="Add New Customer"
                onClick={() => {
                  setOpenPopup(true)
                  setAction('AddCustomer')
                }}
              /> */}
              <BtnDefault
                variant="contained"
                color="primary"
                size="medium"
                text='Submit'
                onClick={handleClick}
                // type="submit"
              />

            </div>
            {action &&
              <ModalOpener
                action={action}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                setAction={setAction}
                customerNumber={inputNumber}
                searchFlag={searchFlag}
              />

            }
          </div>


        </form>
      </div>
    </div>
  )

}

export default RegisterComplaint