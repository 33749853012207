import api from "../utils/axios";
import { successResponse } from "../utils/utils";

const postCustomerFeedback = async (feedbackDetail) => {

    const response = await api.post(`/api/v1/auth/feedback/submit`, { ...feedbackDetail });
    successResponse('Feedback submited successfully');
    return response;

};

export default postCustomerFeedback

