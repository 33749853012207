import React, { useMemo, useState, useEffect } from 'react';
import SectionHeader from '../../Components/Headers/SectionHeader';
import { HiOutlineUserGroup } from "react-icons/hi";
import { Avatar, Box } from '@mui/material';
import ActionBtns from '../../Components/ActionBtns';
import { DataGrid } from '@mui/x-data-grid';
import QRData from '../../lib/QR_data';
import ModalOpener from '../../Components/ModalOpener';
import getGeneratedQRList from '../../CommonFunctions/getGeneratedQRList';


const ProductQRData = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const productId = urlParams.get('productId');
    const productName = urlParams.get('productName');

    const [pageSize, setPageSize] = useState(10);
    const [rowId, setRowId] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [action, setAction] = useState('');
    const [tableData, setTableData] = useState([]);
    const [rowData, setRowData] = useState({});

    useEffect(() => {
        getGeneratedQRList(productId).then((data) => {
            setTableData(data)
        });
    }, [openPopup])


    const columns = useMemo(() => [
        {
            field: 'photoURL',
            headerName: '',
            width: 0,
            // renderCell: params => <Avatar {...stringAvatar(params.row.name)} />,
            sortable: false,
            filterable: false,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'count',
            headerName: 'No.of QR generated',
            width: 300,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'createdAt',
            headerName: 'Date of generation',
            width: 400,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 400,
            renderCell: params => <ActionBtns {...{ params, setOpenPopup, setAction, flag: 'QR' }} />,
            headerAlign: 'center',
            headerClassName: 'table--header',
            align: 'center'
        },
    ], [rowId])
    return (
        <div className='mt-36'>
            <SectionHeader
                icon={<HiOutlineUserGroup />}
                headingText={productName}
                btnText="Generate QR"
                column='name'
                searchFlag={false}
                addBtnFlag={true}
                setOpenPopup={setOpenPopup}
                setAction={setAction}
                action='GenerateQR'
            />
            <div className='flex justify-center' >
                <Box
                    sx={gridStyle}>
                    <DataGrid
                        columns={columns}
                        rows={tableData}
                        getRowId={(row) => row.id}
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        getRowSpacing={params => ({
                            top: params.isFirstVisible ? 0 : 5,
                            bottom: params.isLastVisible ? 0 : 5
                        })}
                        onCellEditCommit={params => setRowId(params.id)}
                        onRowClick={params => { setRowId(params.id); setRowData(params.row) }}
                    // filterModel={{
                    //     items: filter
                    // }}
                    />
                </Box>
                {action &&
                    <ModalOpener
                        action={action}
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                        data={rowData}
                        id={rowId}
                        productId={productId}
                    />

                }
            </div>

        </div>
    )
}

export default ProductQRData

const stringAvatar = (name) => {
    if(!name) return;
    const regex = /\s+/gi;
    const nameLength = name.trim().replace(regex, ' ').split(' ').length;

    return {
        sx: {
            bgcolor: '#153b8c',
        },
        children: nameLength > 1 ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : `${name[0]}${name[1]}`
    };
}

const gridStyle = {

    backgroundColor: '#fff',
    height: 700,
    padding: 2,
    width: '70%',
    '& .MuiDataGrid-root': {
        borderColor: '#153b8c',
        '& .MuiDataGrid-row': {
            borderColor: '#aed1f6',
            '&:hover': {
                bgcolor: '#ebf4fd',
            },
            '&.Mui-selected': {
                bgcolor: '#c2ddf8',
                '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: '#aed1f6',
                    borderColor: '#aed1f6',
                },
            }

        }
    },
    '& .MuiDataGrid-columnHeaders': {
        borderColor: '#153b8c',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        color: '#153b8c',
        fontSize: 16,
        fontWeight: 500,
        fontFamily: "sans-serif",
    },
}