import React, { useEffect, useState } from 'react';
import { HiBell } from 'react-icons/hi'
import StatCards from './StatCards';
import MonthlyTrendBar from './MonthlyTrendBar';
import CallStatusPie from './CallStatusPie';
import ComplaintCountDonut from './ComplaintCountDonut';
import { getCallStats } from '../../CommonFunctions/getCallStats';
import PendingCallBar from './PendingCallBar';
import CallCompletionLine from './CallCompletionLine';
import CallPendingTable from './CallPendingTable';
import CallPendingStatsTable from './CallPendingStatsTable';

const CallStats = () => {
  const [statCards, setStatCards] = useState([]);
  useEffect(() => {
    getCallStats().then(response => {
      setStatCards(response)
    });
  }, [])

  return (
    <div className='h-[54rem]'>
      <StatCards complaints1={statCards} />
      <div className='grid grid-cols-2'>
        <div className='m-10 p-10 border hover:shadow-md hover:shadow-themeBlue-2 border-themeBlue-1 '>
          <span className='flex justify-center text-2xl font-sans font-bold'>Monthly Trend Reports</span>
          <MonthlyTrendBar />
        </div>
        <div className='m-10 p-10 border hover:shadow-md hover:shadow-themeBlue-2 border-themeBlue-1 '>
          <span className='flex justify-center text-2xl font-sans font-bold'>Monthly Call Status Reports</span>
          <CallStatusPie />
        </div>
        <div className='m-10 p-10 border hover:shadow-md hover:shadow-themeBlue-2 border-themeBlue-1 '>
          <span className='flex justify-center text-2xl font-sans font-bold'>Monthly Statewise Complaint Data</span>
          <ComplaintCountDonut />
        </div>
        <div className='m-10 p-10 border hover:shadow-md hover:shadow-themeBlue-2 border-themeBlue-1 '>
          <span className='flex justify-center text-2xl font-sans font-bold'>Calls Pending Weekly</span>
          <PendingCallBar />
        </div>
      </div>
      <div className='m-10 p-10 border hover:shadow-md hover:shadow-themeBlue-2 border-themeBlue-1 '>
        {/* <CallCompletionLine /> */}
        <div className="grid grid-cols-2 pt-3">
        <CallPendingTable />
        <CallPendingStatsTable/>
        </div>
      </div>
    </div>

  )
}

export default CallStats

const complaints1 = [
  {
    title: "COMPLAINTS TODAY",
    subTitle: "No. of Registered Complaints Today",
    percentage: "+32.40%",
    value: "5",
    status: "down",
    icon: <HiBell />,
    path: '/complaints1'
  },
  {
    title: "COMPLAINTS THIS MONTH",
    subTitle: "No. of Registered Complaints This Month",
    percentage: "+38.40%",
    value: "45",
    status: "up",
    icon: <HiBell />,
    path: '/complaints1'
  }
]