import React, { useState } from 'react'
import { Stepper, Step, StepLabel, Typography } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form"
import Ent_detail from './EnterpriseForms/Ent_detail';
import Personal_detail from './EnterpriseForms/Personal_detail';
import Payment_details from './EnterpriseForms/Payment_details';
import { useNavigate } from 'react-router-dom';
import { BtnDefault } from './BtnComponent';
import GetStepperData from '../CommonFunctions/GetStepperData';
import api from '../utils/axios';
import { successResponse } from '../utils/utils';


const getSteps = () => {
    return [
        "Enterprise Details",
        "Personal Details",
        "Payment Details",
        // "Add Product",
        // "Add Team Member"
    ]
}

const getStepComponents = (step) => {
    switch (step) {
        case 0: return <Ent_detail />
        case 1: return <Personal_detail />
        case 2: return <Payment_details />
        // case 3: return <Add_product />
        // case 4: return <Add_team />
        default: return (<div>Default Case</div>)
    }
}

const FormStepper = () => {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [skippedSteps, setSkippedSteps] = useState([]);
    const steps = getSteps();
    const methods = useForm({
        defaultValues: {
            entName: "",
            description: "",
            regName: "",
            pinCode: "",
            city: "",
            state: "",
            address: "",
            upi: "",
            logo: ""
        }
    });
    const isOptional = step => step === 3 || step === 4;                            //will return boolean true/false
    const isSkipped = step => skippedSteps.includes(step);                         //will return boolean true/false

    const handleNext = async (data) => {

        setActiveStep(activeStep + 1)
        setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep))
        if (activeStep === steps.length - 1) {
           
            const formData = await GetStepperData(data);
            const token = localStorage.getItem('token');
            const { accountId } = JSON.parse(localStorage.getItem('userData'));
            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + token
                }
            }
            const payload = { accountId, accountDetail: JSON.stringify(formData) }
            const res = await api.post(`/api/v1/account/updateAccount`, payload, config);
            successResponse('Account detail updated successfully!')
            navigate('/home');
        }
    }

    const handlePrev = () => {
        setActiveStep(activeStep - 1)
    }

    const handleSkip = () => {
        if (!isSkipped(activeStep))
            setSkippedSteps([...skippedSteps, activeStep])
        setActiveStep(activeStep + 1)
    }

    return (
        <div className="md:container md:mx-auto h-screen pt-48">
            <div className='flex-col'>
                <h2 className='text-3xl text-slate-800 font-bold text-center pb-5'>Enterprise Profile</h2>
                <Stepper alternativeLabel activeStep={activeStep}>
                    {steps.map((step, index) => {
                        const labelProps = {};
                        const stepProps = {};
                        if (isOptional(index)) {
                            labelProps.optional = (<Typography variant='caption'>Optional</Typography>)
                        }
                        if (isSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={index} {...stepProps}>
                                <StepLabel {...labelProps}>{step}</StepLabel>
                            </Step>
                        )

                    })}
                </Stepper>

                {
                activeStep === steps.length ? (
                    // <Typography variant='h3'>Thank You</Typography>
                    navigate('/home')
                ) : (
                    <div className=''>
                        <FormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(handleNext)} className='flex justify-center mt-10'>
                                <div className='flex flex-col'>
                                    {getStepComponents(activeStep)}

                                    <div className='flex gap-5 justify-center mt-10'>
                                        <BtnDefault
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            text="Prev"
                                            onClick={handlePrev}
                                            disabled={activeStep === 0}
                                        />
                                        <BtnDefault
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            text={activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                            type="submit"
                                        />
                                    </div>
                                </div>
                                {isOptional(activeStep) &&
                                    <BtnDefault variant='contained'
                                        color="error"
                                        size="large"
                                        text="Skip"
                                        onClick={handleSkip}
                                    />}
                            </form>
                        </FormProvider>
                    </div>
                )
                }
            </div>
        </div>
    )
}

export default FormStepper