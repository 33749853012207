import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VerticalStepper from './ReportComponents/VerticalStepper';
import CustomerInfo from './ReportComponents/CustomerInfo';
import ProductInfo from './ReportComponents/ProductInfo';
import ComplaintInfo from './ReportComponents/ComplaintInfo';
import AssignToInputs from './ReportComponents/AssignToInputs';
import PartPendingForm from './ReportComponents/PartPendingForm';
import ReplacementForm from './ReportComponents/ReplacementForm';
import ClosingInputs from './ReportComponents/ClosingInputs';
import { getReportData, getRequestDescription } from '../StateMgmt/Actions/reportActions';
import { getUser } from '../utils/utils'
import CustomerCareInputs from './ReportComponents/CustomerCareInputs';
import Feedback from './ReportComponents/Feedback';
import PartPendingInputs from './ReportComponents/PartPendingInputs';
import ReplacementInputs from './ReportComponents/ReplacementInputs';
import TechnicianDetails from './ReportComponents/TechnicianDetails';
import TechnicianInputs from './ReportComponents/TechnicianInputs';
import Charges from './ReportComponents/Charges';
import Documents from './ReportComponents/Documents';


const Report = ({ status, complaintData, setOpenPopup, openPopup }) => {

    const user = getUser();

    const [expanded, setExpanded] = useState('panel1');
    const [customerDetail, setCustomerDetail] = useState({});
    const [complainAndStatus, setComplainAndStatus] = useState({});
    const [productAndBilling, setProductAndBilling] = useState({});
    const [partPending, setPartPending] = useState({});
    const [replacment, setReplacment] = useState({});
    const [technician, setTechnician] = useState({});
    const [feedback, setFeedback] = useState({});
    const [financial, setFinancial] = useState({});
    const [docs, setDocs] = useState({});
    const [requestDescription, setRequestDescription] = useState([]);
    const [callId, setCallId] = useState('');



    useEffect(() => {
        const { id } = complaintData;
        const user = getUser();

        getReportData(id).then(({ payload }) => {
            const { customerDetail, complainAndStatus, productAndBilling, partPending, replacment, technician, docs, feedback, financial } = payload;
            setCustomerDetail(customerDetail);
            setComplainAndStatus(complainAndStatus);
            setProductAndBilling(productAndBilling);
            setPartPending(partPending);
            setReplacment(replacment);
            setTechnician(technician);
            setFeedback(feedback);
            setFinancial(financial);
            setDocs(docs);
            const { callId } = complainAndStatus;
            setCallId(callId);
        });


        getRequestDescription(id).then(({ payload }) => {
            setRequestDescription(payload);
        });
    }, []);

    const { name } = customerDetail;
    const { product } = productAndBilling;
    const { id } = complaintData;
    const { partName } = partPending;
    const { replacementProductName } = replacment;
    const { technicianName } = technician;



    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
            {!_.isEmpty(customerDetail) &&
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <span className='text-sm font-semibold md:w-1/3'>Customer Details</span>
                        <span className='text-sm md:block hidden'>{name}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CustomerInfo customerDetail={customerDetail} />
                    </AccordionDetails>
                </Accordion>
            }
            {!_.isEmpty(productAndBilling) &&
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <span className='text-sm font-semibold md:w-1/3'>Product and Billings</span>
                        <span className='text-sm md:block hidden'>{product}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ProductInfo productAndBilling={productAndBilling} />
                    </AccordionDetails>
                </Accordion>
            }
            {!_.isEmpty(complainAndStatus) &&
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        <span className='text-sm font-semibold md:w-1/3'>Complaint Info and Status</span>
                        <span className='text-sm md:block hidden'>{status} - {callId}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ComplaintInfo complainAndStatus={complainAndStatus} />
                    </AccordionDetails>
                </Accordion>
            }

            {!_.isEmpty(feedback) &&
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5bh-content"
                        id="panel3bh-header"
                    >
                        <span className='text-sm font-semibold md:w-1/3'>Customer Inputs</span>
                        <span className='text-sm md:block hidden'>Rating and Feedbacks</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Feedback feedback={feedback} />
                    </AccordionDetails>
                </Accordion>
            }
            {!_.isEmpty(partPending) &&
                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5bh-content"
                        id="panel3bh-header"
                    >
                        <span className='text-sm font-semibold md:w-1/3'>Part Pending Inputs</span>
                        <span className='text-sm md:block hidden'>{partName}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PartPendingInputs partPending={partPending} />
                    </AccordionDetails>
                </Accordion>
            }
            {!_.isEmpty(replacment) &&
                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5bh-content"
                        id="panel3bh-header"
                    >
                        <span className='text-sm font-semibold md:w-1/3'>Replacement Inputs</span>
                        {/* <span className='text-sm md:block hidden'>{replacementProductName}</span> */}
                    </AccordionSummary>
                    <AccordionDetails>
                        <ReplacementInputs replacment={replacment} />
                    </AccordionDetails>
                </Accordion>
            }
            {!_.isEmpty(technician) &&
                <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5bh-content"
                        id="panel3bh-header"
                    >
                        <span className='text-sm font-semibold md:w-1/3'>Technician Details</span>
                        <span className='text-sm md:block hidden'>{technicianName}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TechnicianDetails technician={technician} />
                    </AccordionDetails>
                </Accordion>
            }
            {!_.isEmpty(financial) &&
                <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5bh-content"
                        id="panel3bh-header"
                    >
                        <span className='text-sm font-semibold md:w-1/3'>Charges</span>
                        {/* <span className='text-sm md:block hidden'>{technicianName}</span> */}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Charges financial={financial} />
                    </AccordionDetails>
                </Accordion>
            }
            {!_.isEmpty(docs) &&
                <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5bh-content"
                        id="panel3bh-header"
                    >
                        <span className='text-sm font-semibold md:w-1/3'>Documents</span>
                        {/* <span className='text-sm md:block hidden'>{technicianName}</span> */}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Documents docs={docs} />
                    </AccordionDetails>
                </Accordion>
            }
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <span className='text-sm font-semibold md:w-1/3'>Request Description</span>
                </AccordionSummary>
                <AccordionDetails className='overflow-y-scroll h-80'>
                    <VerticalStepper requestDescription={requestDescription} id={id} />
                </AccordionDetails>
            </Accordion>

            <div>
                {user.role === 'Customer Executive' &&
                    <div>
                        {status === 'COMPLETED' && <CustomerCareInputs callId={id} setOpenPopup={setOpenPopup} />}
                        {status === 'CLOSED' && <CustomerCareInputs callId={id} setOpenPopup={setOpenPopup} />}
                        {status === 'REJECTED' && <CustomerCareInputs callId={id} setOpenPopup={setOpenPopup} />}
                    </div>
                }
                {user.role === 'Technician' &&
                    <div>
                        {/* {status === 'UNASSIGNED' && <TechnicianInputs status={status} callId={id} setOpenPopup={setOpenPopup}/>} */}
                        {status === 'ASSIGNED' && <TechnicianInputs status={status} callId={id} setOpenPopup={setOpenPopup} />}
                        {/* {status === 'PART PENDING' && <TechnicianInputs status={status} callId={id} setOpenPopup={setOpenPopup}/>} */}
                        {/* {status === 'REPLACEMENT' && <TechnicianInputs status={status} callId={id} setOpenPopup={setOpenPopup}/>} */}
                        {/* {status === 'COMPLETED' && <TechnicianInputs status={status} callId={id} setOpenPopup={setOpenPopup}/>} */}
                    </div>
                }
                {user.role === 'Admin' &&
                    <div>
                        {status === 'UNASSIGNED' && <AssignToInputs id={id} setOpenPopup={setOpenPopup} status={status} />}
                        {status === 'ASSIGNED' && <AssignToInputs id={id} status={status} setOpenPopup={setOpenPopup} />}
                        {status === 'PART PENDING' && <PartPendingForm id={id} status={status} setOpenPopup={setOpenPopup} />}
                        {status === 'REPLACEMENT' && <ReplacementForm id={id} status={status} setOpenPopup={setOpenPopup} />}
                        {status === 'COMPLETED'
                            &&
                            !_.isEmpty(productAndBilling) &&
                            <ClosingInputs
                                id={id}
                                status={status}
                                setOpenPopup={setOpenPopup}
                                productAndBilling={productAndBilling}
                                complainAndStatus={complainAndStatus}
                                technician={technician}
                            />

                        }
                    </div>
                }
            </div>


        </div>
    );
}

export default Report;