import React from 'react';
import { BsBox, BsCalendarDateFill, BsFillPatchCheckFill, BsFillPersonFill } from "react-icons/bs";
import { RiBillFill, RiNumbersFill } from "react-icons/ri"

const ProductInfo = ({ productAndBilling }) => {
    const { product, brandName, noOfProduct, billStatus, dateOfPurchase, dealerName } = productAndBilling;
    return (
        <div className="max-w-md mx-auto bg-white rounded-xl shadow-sm overflow-hidden md:max-w-2xl">
            <div className='w-full grid md:grid-cols-2 grid-cols-1 gap-4'>
                <div className='flex flex-col'>
                    <div className='flex md:flex-row flex-col md:gap-4'>
                        <div className="flex items-center gap-3 m-2">
                            <div className='text-themeBlue-1'><BsBox /></div>
                            <div className='text-zinc-700 font-semibold'>Product</div>
                        </div>
                        <div className='flex items-center m-2 col-span-2'>
                            <div className='text-gray-600'>
                                {product}
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row md:gap-4'>
                        <div className="flex col-span-2 items-center gap-3 m-2 text-gray-600 ">
                            <div className='text-themeBlue-1'><BsFillPatchCheckFill /></div>
                            <div className='text-zinc-700 font-semibold'>Product Brand</div>
                        </div>
                        <div className='flex items-center m-2 '>
                            <div className='text-gray-600'>
                                {brandName}
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row md:gap-4'>
                        <div className="flex col-span-2 items-center gap-3 m-2 text-gray-600 ">
                            <div className='text-themeBlue-1'> <RiNumbersFill /></div>
                            <div className='text-zinc-700 font-semibold'>No. of Products</div>
                        </div>
                        <div className='flex items-center m-2'>
                            <div className='text-gray-600'>
                                {noOfProduct}
                            </div>
                        </div>
                    </div>

                </div>
                <div className=''>
                    <div className='flex flex-row  md:gap-4'>
                        <div className="flex items-center gap-3 m-2 text-gray-600 ">
                            <div className='text-themeBlue-1'><RiBillFill /></div>
                            <div className='text-zinc-700 font-semibold'>Bill Status</div>
                        </div>
                        <div className='flex items-center m-2 col-span-2'>
                            <div className='text-gray-600'>
                                {billStatus}
                            </div>
                        </div>
                    </div>
                    <div className='flex md:flex-row flex-col md:gap-4'>
                        <div className="flex col-span-2 items-center gap-3 m-2 text-gray-600 ">
                            <div className='text-themeBlue-1'><BsCalendarDateFill /></div>
                            <div className='text-zinc-700 font-semibold'>Date of Purchase</div>
                        </div>
                        <div className='flex col-span-2 items-center md:m-2 '>
                            <div className='text-gray-600'>
                                {dateOfPurchase}
                            </div>
                        </div>
                    </div>
                    <div className='flex md:flex-row flex-col md:gap-4'>
                        <div className="flex col-span-2 items-center gap-3 m-2 text-gray-600 ">
                            <div className='text-themeBlue-1'> <BsFillPersonFill /></div>
                            <div className='text-zinc-700 font-semibold'>Dealer Name</div>
                        </div>
                        <div className='flex col-span-2 items-center md:m-2'>
                            <div className='text-gray-600'>
                                {dealerName}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ProductInfo