import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { useForm } from "react-hook-form";
import Autocomplete from '@mui/material/Autocomplete';
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import BtnOutlined, { BtnDefault } from '../BtnComponent';
import getPinValue from '../../CommonFunctions/getPinValue';
import getRoles from '../../CommonFunctions/getRoles';
import { addTeamMember } from '../../StateMgmt/Actions/teamActions';
import { errorMessage } from '../../utils/utils';


const idTypes = [
  { label: 'Aadhar Card', value: "ADHAR", regex: new RegExp(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/)},
  { label: 'PAN Card', value: "PAN",  regex: new RegExp(/([A-Z]){5}([0-9]){4}([A-Z]){1}$/) },
  { label: 'Voter ID Card', value: "VOTER",  regex: new RegExp(/^[A-Z]{3}[0-9]{7}$/)},
]

const AddMember = ({ setOpenPopup }) => {

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm();

  const [role, setRole] = useState('');
  const [roles, setRoles] = useState([]);
  const [id_type, setId_type] = useState('ADHAR');
  const [options, setOptions] = useState([]);
  const [stateValue, setStateValue] = useState('');
  const [districtValue, setDistrictValue] = useState('');

  useEffect(() => {
    getRoles().then((data) => {
      setRoles(data)
    });
  }, [])

  const handleSelectRole = (_, value, reason) => {
    setRole(value.id);
  }
  const handleSelectIDType = (_, value, reason) => {
    setId_type(value.value);
  }

  const onInputChange = (event, value, reason) => {
    if (value) {
      getPinValue(value, setOptions);
    } else {
      setOptions([]);
    }
  };

  const getValue = (value, field) => {
    setDistrictValue(value.value.district);
    setStateValue(value.value.state)
  }
  const validateIDCard = ({id_type,id_number}) => {
    const idCard =  idTypes.find((type)=> type.value === id_type);
    if (!id_number) return;
    if (!idCard.regex.test(id_number)) {
      const err_message = `Please enter a valid ${idCard.label}  number`;
      errorMessage(err_message);
      throw new Error(err_message);
    }
  }
  const validateIFSC = ({ifsc})=>{
    if(!ifsc) return;
    const regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
    if(!regex.test(ifsc)){
      const err_message = `Please enter a valid IFSC Code `;
      errorMessage(err_message);
      throw new Error(err_message);
    }
  }
  const onSubmit = async (data) => {
    const reqBody = data;
    reqBody.city = districtValue;
    reqBody.state = stateValue;
    reqBody.id_type = id_type;
    reqBody.role = role;
    validateIFSC(data);
    validateIDCard(data);
    await addTeamMember(reqBody);
    setOpenPopup(false);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
      <div className="grid gap-4 grid-cols-3 gap-x-8 ">
        <CustomizeTextField
          className='w-full'
          placeholder='Please enter member name'
          variant='outlined'
          label='Member Name'
          name='name'
          {...register("name", { required: 'Member name is required.' })}
          error={Boolean(errors.name)}
          helperText={errors.name?.message}
        />
        <Stack spacing={2} sx={{ color: '#851851' }}>
          <Autocomplete
            error={Boolean(errors.role)}
            disableClearable
            onChange={handleSelectRole}
            options={roles}
            renderInput={(params) => (
              <CustomizeTextField
                {...params}
                label='Member Role'
                name='role'
                {...register("role", { required: 'Please select a role' })}
                error={Boolean(errors.role)}
                helperText={errors.role?.message}
              />
            )}

          />
        </Stack>
        <CustomizeTextField
          className='w-full'
          placeholder='Please enter member phone'
          variant='outlined'
          label='Member Phone'
          name='phone'
          {...register("phone", { required: 'Phone is required.' })}
          error={Boolean(errors.phone)}
          helperText={errors.phone?.message}
        />

      </div>
      <div className="grid gap-4 grid-cols-2 gap-x-8 ">
        <div className='flex flex-col gap-3'>
          <Stack spacing={2} sx={{ color: '#851851' }}>
            <Autocomplete
              error={Boolean(errors.role)}
              freeSolo
              options={options}
              onInputChange={onInputChange}
              onChange={(event, value) => {
                getValue(value)
              }}

              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <CustomizeTextField
                  {...params}
                  label='PIN Code'
                  name='pin'
                  {...register("pin", { required: 'PIN is required.' })}
                  error={Boolean(errors.pin)}
                  helperText={errors.pin?.message}
                />
              )}

            />
          </Stack>
          <CustomizeTextField
            className='w-full'
            variant='outlined'
            value={districtValue}
            label='City / District'
            name='city'
          />
          <CustomizeTextField
            className='w-full'
            variant='outlined'
            value={stateValue}
            label='State'
            name='state'
          />
        </div>

        <CustomizeTextField
          placeholder='Please enter member address'
          variant='outlined'
          multiline
          rows={7}
          label='Member Address'
          name='address'
          {...register("address", { required: 'Address is required.' })}
          error={Boolean(errors.address)}
          helperText={errors.address?.message}
        />

      </div>
      <div className="grid gap-4 grid-cols-3 gap-x-8 ">
        <CustomizeTextField
          className='w-full'
          placeholder='Please enter member bank account name'
          variant='outlined'
          label='Bank Account Name'
          name='bank'
          {...register("bank", { required: false })}
          error={Boolean(errors.bank)}
          helperText={errors.bank?.message}
        />

        <CustomizeTextField
          className='w-full'
          placeholder='Please enter member bank account number'
          variant='outlined'
          label='Bank A/C Number'
          name='ac_number'
          {...register("ac_number", { required: false })}
          error={Boolean(errors.ac_number)}
          helperText={errors.ac_number?.message}
        />
        <CustomizeTextField
          className='w-full'
          placeholder='Please enter member bank IFSC Code'
          variant='outlined'
          label='IFSC Code'
          name='ifsc'
          {...register("ifsc", { required: false })}
          error={Boolean(errors.ifsc)}
          helperText={errors.ifsc?.message}
        />

      </div>
      <div className="grid gap-4 grid-cols-3 gap-x-8 ">
        <Stack spacing={2} sx={{ color: '#851851' }}>
          <Autocomplete
            error={Boolean(errors.id_type)}
            disableClearable
            onChange={handleSelectIDType}
            options={idTypes}
            defaultValue={{label: 'Aadhar Card'}}
            renderInput={(params) => (
              <CustomizeTextField
                {...params}
                label='Member ID Type'
                name='id_type'
                {...register("id_type", { required: false })}
                error={Boolean(errors.id_type)}
                helperText={errors.id_type?.message}
              />
            )}

          />
        </Stack>
        <CustomizeTextField
          className='w-full'
          placeholder='Please enter selected ID number'
          variant='outlined'
          label='ID Number'
          name='id_number'
          {...register("id_number", { required: false })}
          error={Boolean(errors.id_number)}
          helperText={errors.id_number?.message}
        />
        <CustomizeTextField
          className='w-full'
          placeholder='Please enter member rate/visit'
          variant='outlined'
          label='Rate Per Visit'
          name='rate'
          {...register("rate", { required: false })}
          error={Boolean(errors.rate)}
          helperText={errors.rate?.message}
        />

      </div>

      <div className="grid gap-4 grid-cols-2 gap-x-8 ">
        <BtnDefault
          variant="contained"
          color="primary"
          size="medium"
          text="Submit"
          type="submit"
        />
        <BtnOutlined
          variant="outlined"
          color="error"
          size="medium"
          text="Cancel"
          onClick={() => setOpenPopup(false)}
        />
      </div>

    </form>
  )
}

export default AddMember