    import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { TextField } from '@mui/material';
import api from '../../utils/axios';


const MobileLogin = () => {

    const [code, setCode] = useState('')
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const Code = urlParams.get('Code');
        setCode(Code);
    }, []);

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();
    const navigate = useNavigate();
    const [phone, setPhone] = useState('');

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            onSubmit();
        }
    }
    const onSubmit = (data) => {
        setPhone(data.mobile)
        api.post('/api/v1/auth/customer/login', { phoneNo: data.mobile, productUniqueCode: code })
            .then(response => {
                const customerId = response.data.data.customerId;
                navigate('/enterOtp', { state: { phone: data.mobile, code: code, customerId: customerId } });
            });

    }

    return (
        <div className='grid h-screen w-full'>
            <div className='flex flex-col justify-center'>
                <form onSubmit={handleSubmit(onSubmit)} className='max-w-[400px] w-full mx-auto bg-gray-50 shadow-xl shadow-slate-400 p-8 px-8 rounded-lg'>
                    <h2 className='text-3xl text-slate-800 font-bold text-center pb-5'>Verify Your Number</h2>
                    <div className='flex flex-col text-gray-600 py-2'>
                        <label>Phone Number</label>
                        <TextField
                            className='rounded-lg bg-gray-200 border mt-2 p-2 focus:border-blue-300 focus:bg-blue-50 focus:outline-none'
                            type='text'
                            placeholder='Enter Your Mobile Number'
                            // value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            onKeyDown={handleKeyDown}
                            name='mobile'
                            {...register("mobile", { required: 'Mobile Number is required.' })}
                            error={Boolean(errors.mobile)}
                            helperText={errors.mobile?.message}
                        />
                    </div>
                    <button
                        className='w-full my-5 py-2 bg-black  hover:shadow-blue-500/30 text-white font-semibold rounded-lg'
                        type="submit"
                    >
                        Next
                    </button>
                </form>
            </div>
        </div>
    )
}


export default MobileLogin;
