import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import BtnOutlined, { BtnDefault, BtnText } from '../BtnComponent';
import { IconButton, Tooltip, Button } from '@mui/material';
import { Delete, Add } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

const Add_other_product = ({ setOpenPopup, productList, setProductList, onSubmit }) => {

    const [product, setProduct] = useState();
    const [quantity, setQuantity] = useState();
    const [brand, setBrand] = useState();
    const [price, setPrice] = useState();

    const addProduct = () => {
        setProductList([...productList, { pName: product, quantity, brand, type: 'Other', id: productList.length, price }]);
        setPrice();
        setBrand();
        setProduct();
        setQuantity();
    }

    const removeProduct = (id) => {
        const index = productList.map(e => e.id).indexOf(id);
        console.log(index)
        const filteredProducts = [...productList];
        filteredProducts.splice(index, 1);
        console.log(filteredProducts)
        setProductList(filteredProducts);

    }

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
            {
                productList.filter(x => x.type === 'Other').map((product, index) => (
                    <div className="grid gap-4 grid-cols-7 gap-x-3" key={index}>
                        <div className='col-span-3'>
                            <CustomizeTextField
                                disabled
                                variant='outlined'
                                value={product?.pName}
                                className='w-full'
                                label='Product Name'

                            />
                        </div>
                        <div className='col-span-3'>
                            <CustomizeTextField
                                disabled
                                variant='outlined'
                                value={product?.brand}
                                className='w-full'
                                label='Product Brand'

                            />
                        </div>


                        <div className='col-span-3'>
                            <CustomizeTextField
                                disabled
                                value={product?.quantity}
                                className='w-full'
                                variant='outlined'
                                label='Quantity'
                            />
                        </div>
                        <div className='col-span-3'>
                            <CustomizeTextField
                                disabled
                                variant='outlined'
                                value={product?.price}
                                className='w-full'
                                label='Product Price'

                            />
                        </div>
                        <div className='ml-2'>
                            <Tooltip title='Remove Inputs' placement="right">
                                <IconButton
                                    sx={{
                                        width: 55,
                                        height: 55,
                                        color: '#153b8c',
                                        '&:hover': { bgcolor: '#153b8c', color: grey[50] }
                                    }}
                                    onClick={() => removeProduct(product?.id)}
                                >
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                ))
            }



            <div className='flex flex-col space-y-3'>
                <div className="grid gap-4 grid-cols-4 gap-x-3">
                    <div className='col-span-2'>
                        <CustomizeTextField
                            value={brand || ''}
                            className='w-full'
                            placeholder='Please enter product brand'
                            variant='outlined'
                            label='Product Brand'
                            name='brand'
                            onChange={e => setBrand(e.target.value)}
                        />
                    </div>
                    <div className='col-span-2'>
                        <CustomizeTextField
                            value={product || ''}
                            className='w-full'
                            placeholder='Please enter product name'
                            variant='outlined'
                            label='Product Name'
                            name='p_name'
                            onChange={e => setProduct(e.target.value)}
                        />
                    </div>
                </div>
                <div className="grid gap-4 grid-cols-5 gap-x-3">
                    <div className='col-span-2'>
                        <CustomizeTextField
                            value={quantity || ''}
                            type="number"
                            className='w-full'
                            placeholder='Please enter product quantity'
                            variant='outlined'
                            label='Product Quantity'
                            name='brand'
                            onChange={e => setQuantity(e.target.value)}
                        />
                    </div>
                    <div className='col-span-2'>
                        <CustomizeTextField
                            value={price || ''}
                            type="number"
                            className='w-full'
                            placeholder='Please enter product price'
                            variant='outlined'
                            label='Product Price'
                            name='price'
                            onChange={e => setPrice(e.target.value)}
                        />
                    </div>
                    <div className='ml-2' >
                        <BtnText
                            disabled={quantity && product && brand ? false : true}
                            variant="text"
                            size="medium"
                            text="Add"
                            type="submit"
                            startIcon={<Add />}
                            onClick={addProduct}
                            color='#153b8c'
                        />
                    </div>
                </div>

            </div>

            {/* <BtnText
                variant="text"
                size="medium"
                text="Add More Products"
                type="submit"
                startIcon={<Add />}
                onClick={addProduct}
                color='#153b8c'
            /> */}
            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <BtnDefault
                    variant="contained"
                    color="primary"
                    size="medium"
                    text="Save"
                    type="submit"
                />
                <BtnOutlined
                    variant="outlined"
                    color="error"
                    size="medium"
                    text="Cancel"
                    onClick={() => setOpenPopup(false)}
                />
            </div>

        </form>
    )
}

export default Add_other_product