import React, { useEffect, useState } from 'react'
import StatCards from './StatCards'
import { MdAttachMoney } from 'react-icons/md';
import FeeStackedBar from './FeeStackedBar';
import RevenueTable from './RevenueTable';
import { getTechnicianRevenueCards } from '../../CommonFunctions/getCallStats'
// import { Button } from '@mui/material';
import { IconButton, Tooltip } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';


import { exportAvgExpenseReport } from '../../StateMgmt/Actions/complaintActions';

const RevenueStats = () => {
  const [statCards, setStatCards] = useState([]);

  useEffect(() => {
    getTechnicianRevenueCards().then(response => {
      console.log(response)
      setStatCards(response)
    });
  }, [])
  return (
    <div className='h-[52rem]'>
      <StatCards complaints1={statCards} />
      <div className='flex flex-col'>
        <div className='m-10 p-10 border hover:shadow-md hover:shadow-themeBlue-2 border-themeBlue-1 '>
          <span className='flex justify-center text-2xl font-sans font-bold'>Technician Fee</span>
          <FeeStackedBar />
        </div>
        <div className='m-10 p-1 border hover:shadow-md hover:shadow-themeBlue-2 border-themeBlue-1 '>
          <span className='flex justify-center text-2xl font-sans font-bold'>Average Expense</span>
          <span className='flex justify-end text-2xl font-sans font-bold'>
          <Tooltip title={`Export Technicaina Expense Data`}>
              <IconButton
                sx={{
                  width: 50,
                  height: 50,
                  bgcolor: '#fff', color: '#153B8C'
                }}
                onClick={() => {
                  exportAvgExpenseReport();
                }}>
                <GetAppIcon fontSize='large' />
              </IconButton>
            </Tooltip>
            {/* <Button onClick={() => {
              exportAvgExpenseReport();
            }}>
              Export Data
            </Button> */}
          </span>
          <RevenueTable />
        </div>

      </div>

    </div>
  )
}

export default RevenueStats

const complaints1 = [
  {
    title: "COMPLAINTS TODAY",
    subTitle: "No. of Registered Complaints Today",
    percentage: "+32.40%",
    value: "5",
    status: "down",
    icon: <MdAttachMoney />,
    path: '/complaints1'
  },
  {
    title: "COMPLAINTS THIS MONTH",
    subTitle: "No. of Registered Complaints This Month",
    percentage: "+38.40%",
    value: "45",
    status: "up",
    icon: <MdAttachMoney />,
    path: '/complaints1'
  }
]