import React from 'react';
import { MdAssignmentLate, MdOutlineNature, MdReportProblem, MdPieChart } from "react-icons/md"


const ReplacementInputs = ({ replacment }) => {
    const { replacementProductName, replacemntApprovedBy, replacementProductQty, replacementProductId } = replacment
    return (
        <div className="max-w-md mx-auto bg-white rounded-xl shadow-sm overflow-hidden md:max-w-2xl">
            <div className='w-full grid md:grid-cols-1 grid-cols-1 gap-4'>
                <div className='flex flex-col'>
                    <div className='flex md:flex-row flex-col md:gap-4'>
                        <div className="flex items-center gap-3 m-2">
                            <div className='text-themeBlue-1'><MdAssignmentLate /></div>
                            <div className='text-zinc-700 font-semibold'>Replacement Product</div>
                        </div>
                        <div className='flex items-center m-2 col-span-2'>
                            <div className='text-gray-600'>
                                {replacementProductName}
                            </div>
                        </div>
                    </div>

                </div>
                <div className='flex md:flex-row  flex-col md:gap-20'>
                    <div className='flex flex-row md:gap-4'>
                        <div className="flex col-span-2 items-center gap-3 m-2 text-gray-600 ">
                            <div className='text-themeBlue-1'><MdOutlineNature /></div>
                            <div className='text-zinc-700 font-semibold'>Product Quantity</div>
                        </div>
                        <div className='flex items-center m-2 '>
                            <div className='text-gray-600'>
                                {replacementProductQty}
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row md:gap-4'>
                        <div className="flex col-span-2 items-center gap-3 m-2 text-gray-600 ">
                            <div className='text-themeBlue-1'> <MdPieChart /></div>
                            <div className='text-zinc-700 font-semibold'>Product ID</div>
                        </div>
                        <div className='flex items-center m-2'>
                            <div className='text-gray-600'>
                                {replacementProductId}
                            </div>
                        </div>
                    </div>

                </div>
                <div className='flex flex-row  md:gap-4'>
                    <div className="flex col-span-2 items-center gap-3 m-2 text-gray-600 ">
                        <div className='text-themeBlue-1'> <MdReportProblem /></div>
                        <div className='text-zinc-700 font-semibold'>Approved By</div>
                    </div>
                    <div className='flex col-span-2 items-center md:m-2'>
                        <div className='text-gray-600'>
                            {replacemntApprovedBy}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReplacementInputs