import React from 'react';
import ProfileCard from '../Components/ProfileCard';


const Profile = () => {
  return (
    <div className='mt-36 flex justify-center items-center'>
      <ProfileCard />
    </div>
  )
}

export default Profile