import api from "../../utils/axios";
import { successResponse } from '../../utils/utils';

export const getReportData = async (id) => {
    const query = `callId=${id}`
    const res = await api.get(`/api/v1/call/getDetail?${query}`);

    return {
        type: "GET_REPORT_DATA",
        payload: res.data.data,
    }
};

export const getRequestDescription = async (id) => {
    const query = `callId=${id}`
    const res = await api.get(`/api/v1/call/getRequestDescription?${query}`);

    return {
        type: "GET_REQUEST_DESCRIPTION",
        payload: res.data.data,
    }
};

export const addRequestDescription = async (req_desc) => {
    const res = await api.post('/api/v1/call/requestDescription', { ...req_desc });

    successResponse("Call comment added successfully!");
    // return {
    //     type: "ADD_REQUEST_DESCRIPTION",
    //     payload: res.data.data,
    // }
};

export const getAgentList = async (id) => {
    const query = `callId=${id}`
    const res = await api.get(`/api/v1/filter/call/agentList?${query}`);

    return {
        type: "GET_AGENT_LIST",
        payload: res.data.data,
    }
};

export const updateCallDetails = async (details) => {
    const res = await api.post('/api/v1/call/handleCalls', { ...details });
    // return {
    //     type: "ADD_REQUEST_DESCRIPTION",
    //     payload: res.data.data,
    // }
};

export const getCustomerCalls = async () => {
    const res = await api.get(`/api/v1/customerCare/getCalls`);

    return {
        type: "GET_CUSTOMER_CALLS",
        payload: res.data.data,
    }
};

export const getCustomerReportData = async (id) => {
    const query = `callId=${id}`
    const res = await api.get(`/api/v1/customerCare/getCallDetail?${query}`);
    return {
        type: "GET_CUSTOMER_REPORT_DATA",
        payload: res.data.data,
    }
};

export const addCustomerCareFeedback = async (feedback) => {
    const res = await api.post('/api/v1/customerCare/feedback', { ...feedback });
    successResponse('Feedback updated successfully!');
    // return {
    //     type: "ADD_REQUEST_DESCRIPTION",
    //     payload: res.data.data,
    // }
};



