import React, { useMemo, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getCallPendingTable } from '../../StateMgmt/Actions/StatChartActions';



const CallPendingStatsTable = () => {
    const [pageSize, setPageSize] = useState(10);
    const [rowId, setRowId] = useState(null);
    const [tableData, setTableData] = useState([])
    useEffect(() => {
        getCallPendingTable().then(({ payload }) => {
            setTableData(payload);
        });
    }, []);
    const columns = useMemo(() => [
        {
            field: 'callId',
            headerName: 'CallId',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'technicianName',
            headerName: 'Technician Name',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'status',
            headerName: 'Call Status',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'pendingDays',
            headerName: 'Pending Days',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'updatedAt',
            headerName: 'Last Updated On',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        
    ], [rowId])

    return (
        <div className='col-span-1'>
            <span className='flex justify-center text-2xl font-sans font-bold'>Calls Pending Time</span>
            <Box
                sx={gridStyle}>

                <DataGrid
                    columns={columns}
                    rows={tableData}
                    getRowId={(row) => row.callId}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowSpacing={params => ({
                        top: params.isFirstVisible ? 0 : 5,
                        bottom: params.isLastVisible ? 0 : 5
                    })}
                />
            </Box>
        </div>
    )
}

export default CallPendingStatsTable

const gridStyle = {

    backgroundColor: '#fff',
    height: 600,
    padding: 2,
    width: '100%',
    '& .MuiDataGrid-root': {
        borderColor: '#153b8c',
        '& .MuiDataGrid-row': {
            borderColor: '#aed1f6',
            '&:hover': {
                bgcolor: '#ebf4fd',
            },
            '&.Mui-selected': {
                bgcolor: '#c2ddf8',
                '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: '#aed1f6',
                    borderColor: '#aed1f6',
                },
            }

        }
    },
    '& .MuiDataGrid-columnHeaders': {
        borderColor: '#153b8c',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        color: '#153b8c',
        fontSize: 16,
        fontWeight: 500,
        fontFamily: "sans-serif",
    },
}