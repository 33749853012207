import productReducers from "./Reducers/productReducers";
import teamReducers from "./Reducers/teamReducers";
import customerReducers from "./Reducers/customerReducers";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
    productReducers,
    teamReducers,
    customerReducers
})

export default rootReducer;