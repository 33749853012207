import React, { useEffect, useState } from 'react';
import { Chart } from "react-google-charts";
import { getStatewiseComplaintData } from '../../StateMgmt/Actions/StatChartActions';


const ComplaintCountDonut = () => {

    const [complaintCountData, setComplaintCountData] = useState([]);

    useEffect(() => {
        getStatewiseComplaintData().then(({ payload }) => {
            setComplaintCountData(payload);
        });
    }, []);

    const options = {
        title: "My Daily Activities",
        pieHole: 0.4,
        is3D: false,
    };
    return (
        <Chart
            chartType="PieChart"
            width="100%"
            height="400px"
            data={complaintCountData}
            options={options}
        />
    );
}

export default ComplaintCountDonut

// const data = [
//     ["Task", "Hours per Day"],
//     ["Work", 11],
//     ["Eat", 2],
//     ["Commute", 2],
//     ["Watch TV", 2],
//     ["Sleep", 7], // CSS-style declaration
// ];
