import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BiCategoryAlt } from "react-icons/bi";
import { Chip, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SectionHeader from '../Components/Headers/SectionHeader';
import ActionBtns from '../Components/ActionBtns';
import gridStyle from '../lib/Styles/grid_style';
import ModalOpener from '../Components/ModalOpener';
import { GetProductCategories } from '../StateMgmt/Actions/productActions';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles({
    topAligned: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        height: '100%',
        paddingTop: '8px', // Adjust padding as needed
        '& .MuiDataGrid-cellContent': {
            textDecoration: 'none', // Remove underline
          },
    },
    root: {

        '& .MuiDataGrid-cell': {
          borderBottom: 'none',
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-cell:focus-within': {
          outline: 'none',
        },
        '& .MuiDataGrid-row': {
          borderBottom: 'none',
        },
      },
  });


const ProductCategories = () => {
    const dispatch = useDispatch()
    const [productCategories, setProductCategories] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [rowId, setRowId] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [action, setAction] = useState('');
    const [rowData, setRowData] = useState({});
    const [filter, setFilter] = useState([]);


    useEffect(() => {
        GetProductCategories().then((data) => {
            setProductCategories(data.payload.data)
            dispatch(productCategories)
        });
    }, [openPopup])

      const classes = useStyles();
    const columns = useMemo(() => [
        {
            field: 'id',
            headerName: 'Category ID',
            width: 130,
            headerAlign: 'center',
            cellClassName: 'topAligned',
        },

        {
            field: 'categoryName',
            headerName: 'Category',
            width: 400,
            headerAlign: 'center',
            cellClassName: 'topAligned',
        },
        {
            field: 'SubcategoryName',
            headerName: 'Subcategory',
            width: 900,
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.ProductSubCategories.map((sub_c, index) => (
                            <Chip label={sub_c.subCategoryName.substr(0,100)} style={{ margin: 5 }} />
                        ))}
                    </div>


                );
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            renderCell: params => <ActionBtns {...{ params, setOpenPopup, setAction, flag: 'Categories' }} />,
            headerAlign: 'center',
            headerClassName: 'table--header',
            cellClassName: 'topAligned',
        },
    ], [rowId])
    // rowId is passed as a dependency in useMemo since we've to render the btn if there is a change in the row
    {/* <span key={index}>{sub_c.subCategoryName}</span> */ }

    return (

        <>
            <SectionHeader
                icon={<BiCategoryAlt />}
                headingText='Product Categories' btnText="Create Category"
                setFilter={setFilter}
                // data={customers}
                column='name'
                searchLabel='Search Category'
                searchFlag={true}
                addBtnFlag={true}
                setOpenPopup={setOpenPopup}
                setAction={setAction}
                action='CreateCategory'
            // filter={setCustomerFilter}
            />
            {/* <SearchDropdown options={columns} setFilterElement={setFilterElement} /> */}
            <Box
                sx={gridStyle}>
                <DataGrid
                    getRowHeight={() => 'auto'}
                    columns={columns}
                    rows={productCategories}
                    getRowId={(row) => row.id}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowSpacing={params => ({
                        top: params.isFirstVisible ? 0 : 5,
                        bottom: params.isLastVisible ? 0 : 5
                    })}
                    onCellEditCommit={params => setRowId(params.id)}
                    onRowClick={params => { setRowId(params.id); setRowData(params.row) }}
                    disableColumnMenu={true}
                    filterModel={{
                        items: filter
                    }}
                    // className={classes.root}
                    // getCellClassName={(params) => {
                    //     if (['id', 'categoryName', 'actions'].includes(params.field) ) {
                    //       return classes.topAligned; // Apply custom class for the 'name' column
                    //     }
                    //     return ''
                    //   }}
                />
            </Box>
            {action &&
                <ModalOpener
                    action={action}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    data={rowData}
                    id={rowId}
                />

            }

        </>
    )
}

export default ProductCategories;

const CustomCellRenderer = ({ value }) => (
    <div className='flex justify-start'>
        {value}
    </div>
);
