import { Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import React from 'react';
import BtnOutlined, { BtnDefault } from '../BtnComponent';
import { useNavigate } from 'react-router-dom';


const ConfirmationModal = (props) => {

    const navigate = useNavigate();
    const { openPopup, setOpenPopup, data, icon, header, details, id, avatar, setAction, formLayout } = props;

    const handleClick = () => {
        setOpenPopup(false)
        setOpenPopup(true)
        setAction('AddCustomer')

    }

    const closeDialog = () => setOpenPopup(false);

    return (
        <Dialog open={openPopup} onClose={closeDialog} width='800px' sx={design}>
            <Box width='600px' p={1} textAlign='center'>
                <DialogTitle className='flex justify-center space-x-2 text-themeBlue-2 bg-themeBlue-4'>

                    {icon}
                    <Typography className='text-sm font-extrabold tracking-wide'>
                        {header}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers className='flex flex-col justify-center space-y-3'>
                    {data && <Avatar {...stringAvatar(data.name)} sx={{ width: 90, height: 90, bgcolor: '#153B8C' }} />}
                    {avatar && <Avatar sx={{ width: 90, height: 90, bgcolor: '#153B8C' }}>{avatar}</Avatar>}
                    <Typography variant="overline" style={{ fontWeight: 700 }}>{id}</Typography>
                </DialogContent>
                <DialogContent dividers className='flex justify-center space-y-3'>
                    <DialogContentText className='flex flex-col my-5 space-y-2'>
                        {details}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='flex space-x-3'>
                    {!avatar && <BtnDefault
                        variant="contained"
                        color="primary"
                        size="medium"
                        text="Add New Customer"
                        onClick={handleClick}

                    />}
                    <BtnOutlined
                        variant="outlined"
                        color="error"
                        size="medium"
                        text="Cancel"
                        onClick={() => setOpenPopup(false)}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ConfirmationModal

const stringAvatar = (name) => {
    if(!name) return;
    return {
        sx: {
            bgcolor: '#153B8C',
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    };
}



const design = {
    '& .MuiDialogContent-root': {
        justifyContent: 'center',
        alignItems: 'center'
    },
    '& .MuiDialogActions-root': {
        justifyContent: 'center',
        alignItems: 'center'
    }
}
