import api from "../../utils/axios";

export const getComplaintList = async (filter, status_filter) => {
    const res = await api.post(`/api/v1/call/getAll?filter=${filter}`, { filterOptions: status_filter });
    // alert("Complain created successfully!");
    return {
        type: "GET_COMPLAINT_LIST",
        payload: res.data.data,
    }
};

export const exportComplaintData = async (filter, status_filter) => {
    const res = await api.post(`/api/v1/call/export?filter=${filter}`, { filterOptions: status_filter });
    // alert("Complain created successfully!");
    const QRDataList = res.data.data;
    const filename = QRDataList.split('/').pop();
    const link = document.createElement('a');
    link.href = QRDataList;
    link.setAttribute('download', filename); //or any other extension
    link.click();
    return {
        type: "GET_COMPLAINT_LIST",
        payload: res.data.data,
    }
};

export const exportAvgExpenseReport = async () => {
    const res = await api.post(`/api/v1/reports/technicianFinancial?exportResult=true`);
    // alert("Complain created successfully!");
    const QRDataList = res.data.data;
    const filename = QRDataList.split('/').pop();
    const link = document.createElement('a');
    link.href = QRDataList;
    link.setAttribute('download', filename); //or any other extension
    link.click();
    return {
        type: "GET_AVERAGE_EXPENSE",
        payload: res.data.data,
    }
};

