import api from "../../utils/axios";

export const getMonthlyTrendBar = async () => {
    const res = await api.get(`/api/v1/reports/complaintTrend`);

    return {
        type: "GET_MONTHLY_TREND",
        payload: res.data.data,
    }
};

export const getMonthlyCallStatus = async () => {
    const res = await api.get(`/api/v1/reports/callStatus`);

    return {
        type: "GET_MONTHLY_CALLSTATUS",
        payload: res.data.data,
    }
};

export const getStatewiseComplaintData = async () => {
    const res = await api.get(`/api/v1/reports/callCity`);

    return {
        type: "GET_STATEWISE_COMPLAINTDATA",
        payload: res.data.data,
    }
};

export const getTechnicianAvgRating = async () => {
    const res = await api.get(`/api/v1/reports/technicianAvgRating`);

    return {
        type: "GET_TECHNICIAN_AVG_RATING",
        payload: res.data.data,
    }
};

export const getTechnicianTableRating = async () => {
    const res = await api.get(`/api/v1/reports/technicianRating`);

    return {
        type: "GET_TECHNICIAN_TABLE_RATING",
        payload: res.data.data,
    }
};

export const getTechnicianPendingCallsBar = async () => {
    const res = await api.get(`/api/v1/reports/technicianPendingCalls`);

    return {
        type: "GET_TECHNICIAN_PENDING_CALLS",
        payload: res.data.data,
    }
};

export const getTechnicianFeeStackBar = async () => {
    const res = await api.get(`/api/v1/reports/earningMonthly`);

    return {
        type: "GET_TECHNICIAN_MONTHLY_FEE_STACK",
        payload: res.data.data,
    }
};

export const getTechnicianRevenueTable = async () => {
    const res = await api.get(`/api/v1/reports/technicianFinancial`);

    return {
        type: "GET_TECHNICIAN_TABLE_REVENUE",
        payload: res.data.data,
    }
};

export const getTechnicianCallPendingTable = async () => {
    const res = await api.get(`/api/v1/reports/completedCallTimeLine`);

    return {
        type: "GET_TECHNICIAN_CALL_PENDING_TABLE",
        payload: res.data.data,
    }
};

export const getCallPendingTable = async () => {
    const res = await api.get(`/api/v1/reports/pendingCallTimeStats`);

    return {
        type: "GET_TECHNICIAN_CALL_PENDING_TABLE",
        payload: res.data.data,
    }
};





