import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Stack from '@mui/material/Stack';
import BtnOutlined, { BtnDefault } from '../BtnComponent';
import Autocomplete from '@mui/material/Autocomplete';
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import { updateCallDetails } from '../../StateMgmt/Actions/reportActions';
import { successResponse } from '../../utils/utils';


const parts = [
    {
        id: 'P01',
        label: "Battery"
    },
    {
        id: 'P02',
        label: "Screw"
    },
    {
        id: 'P03',
        label: "Rotator"
    }
]

const PartPendingForm = (props) => {

    const { setOpenPopup, status, id } = props;
    // const [role, setRole] = useState('');
    // const [team, setTeam] = useState('');
    const [value, setValue] = useState(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();



    const onSubmit = async(data) => {
        data.callId = id;
        data.status = status;
        const resData = await updateCallDetails(data);
        successResponse('Part pending details added successfully!')
        setOpenPopup(false)
    }


    return (
        <div className='mt-5'>
            <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
                <div className="grid gap-4 md:grid-cols-2 grid-cols-1 gap-x-8 ">
                    <Stack spacing={2} sx={{ color: '#851851' }}>
                        <Autocomplete
                            error={Boolean(errors.role)}
                            disableClearable
                            // onChange={handleSelectRole}
                            options={parts}
                            renderInput={(params) => (
                                <CustomizeTextField
                                    {...params}
                                    label='Part ID'
                                    // value={assignedTo}
                                    name='partId'
                                    {...register("partId", { required: 'Please select part id' })}
                                    error={Boolean(errors.partId)}
                                    helperText={errors.partId?.message}
                                />
                            )}
                        />
                    </Stack>
                    <CustomizeTextField
                        className='w-full'
                        placeholder='Enter Part Name'
                        variant='outlined'
                        label='Part Name'
                        name='partName'
                        {...register("partName", { required: 'Please enter part name' })}
                        error={Boolean(errors.partName)}
                        helperText={errors.partName?.message}
                    />
                </div>
                <div className="grid gap-4 md:grid-cols-2 grid-cols-1 gap-x-8 ">
                    <CustomizeTextField
                        className='w-full'
                        placeholder='Enter Part Quantity'
                        variant='outlined'
                        label='Part Quantity'
                        name='partQty'
                        {...register("partQty", { required: 'Please enter part quantity' })}
                        error={Boolean(errors.partQty)}
                        helperText={errors.partQty?.message}
                    />
                    <CustomizeTextField
                        className='w-full'
                        placeholder='Enter Part Warranty in Months'
                        variant='outlined'
                        label='Part Warranty (in Months)'
                        name='partWarranty'
                        {...register("partWarranty", { required: 'Please enter part warranty' })}
                        error={Boolean(errors.partWarranty)}
                        helperText={errors.partWarranty?.message}                    />
                </div>
                <div className="grid gap-4 md:grid-cols-2 grid-cols-1 gap-x-8 ">
                    <BtnDefault
                        variant="contained"
                        color="primary"
                        size="medium"
                        text="Save Changes"
                        type="submit"
                    />
                    <BtnOutlined
                        variant="outlined"
                        color="error"
                        size="medium"
                        text="Cancel"
                        border='#016fe2'
                        onClick={() => setOpenPopup(false)}
                    />
                </div>

            </form>
        </div>

    )
}

export default PartPendingForm


