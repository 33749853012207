import React, { useState } from 'react';
import { HiOutlineBell } from 'react-icons/hi';
import getActionBtn from '../../../../CommonFunctions/getActionBtn';
import ModalOpener from '../../../../Components/ModalOpener';

const ComplaintCards = ({ openPopup, setOpenPopup, complaintsData }) => {
    const [action, setAction] = useState('');
    const [status, setStatus] = useState('');
    const [callId, setCallId] = useState({});
    return (
        <div className='flex items-center justify-center min-h-screen container mx-auto mt-2'>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 gap-y-10'>
                {complaintsData.map((item, i) => (
                    <div key={i} className={`card ${item.isDue ? 'bg-red-50' : 'bg-white'} shadow-themeBlue-4 shadow-md ${item.isDue ? 'hover:bg-red-100' : 'hover:bg-themeBlue-3'} hover:shadow-lg hover:shadow-themeBlue-5`}>
                        <div className='flex flex-col'>
                            <div className={` ${item.isDue ? 'bg-red-500' : 'bg-themeBlue-1'} rounded-md h-17 flex items-center justify-between p-2 lg:px-4 `}>
                                <div className='flex gap-4'>
                                    <span className='text-xl md:text-2xl text-gray-100'>{<HiOutlineBell />}</span>
                                    <h4 className='text-white text-sm md:text-md font-semibold'>{item.status}</h4>
                                </div>
                            </div>
                        </div>
                        <div className='p-5 flex flex-col gap-3'>
                            <div className='flex flex-col space-y-1 '>
                                <div className='grid grid-cols-8 place-items-center'>
                                    <span className='col-span-3 text-sm md:text-md font-bold mt-3'>Call ID</span>
                                    <span className='text-md md:text-md font-medium mt-3'>:</span>
                                    <span className='col-span-4 text-sm md:text-md font-medium mt-3'>{item.callId}</span>
                                </div>
                                <div className='grid grid-cols-8 place-items-center'>
                                    <span className='col-span-3 text-sm md:text-md font-bold'>Customer Name</span>
                                    <span className='text-md md:text-md font-medium'>:</span>
                                    <span className='col-span-4 text-sm md:text-md font-medium'>{item.customerName}</span>
                                </div>
                                <div className='grid grid-cols-8 place-items-center'>
                                    <span className='col-span-3 text-sm md:text-md font-bold'>Created On</span>
                                    <span className='text-md md:text-md font-medium'>:</span>
                                    <span className='col-span-4 text-sm md:text-md font-medium'>{item.createdDate}</span>
                                </div>
                                <div className='grid grid-cols-8 place-items-center'>
                                    <span className='col-span-3 text-sm md:text-md font-bold'>Product</span>
                                    <span className='text-md md:text-md font-medium'>:</span>
                                    <span className='col-span-4 text-sm md:text-md font-light text-center'>
                                        {/* <a className="read-more-link" onClick={() => { setReadMore(!readMore) }}>
                                                <h2 className='text-xs text-themeBlue-1 cursor-pointer'>{linkName}</h2>
                                            </a> 
                                            {readMore && item.product}*/}
                                        {item.product}

                                    </span>
                                </div>
                                <div className='grid grid-cols-8 place-items-center'>
                                    <span className='col-span-3 text-sm md:text-md font-bold'>Type of Service</span>
                                    <span className='text-md md:text-md font-medium'>:</span>
                                    <span className='col-span-4 text-sm md:text-md font-medium'>{item.serviceType}</span>
                                </div>
                                {/* <div className='grid grid-cols-8 place-items-center'>
                                        <span className='col-span-3 text-md md:text-md font-bold'>Status</span>
                                        <span className='text-md md:text-md font-medium'>:</span>
                                        <span className='col-span-4 text-md md:text-md font-medium'>{item.status}</span>
                                    </div> */}
                                <div className='grid grid-cols-8 place-items-center'>
                                    <span className='col-span-3 text-sm md:text-md font-bold'>Created By</span>
                                    <span className='text-md md:text-md font-medium'>:</span>
                                    <span className='col-span-4 text-sm md:text-md font-medium'>{item.createdBy}</span>
                                </div>
                            </div>

                            <div className='flex gap-4 justify-center' onClick={() => setCallId({ id: item.id })}>
                                {getActionBtn(item.status, setOpenPopup, setAction, setStatus)}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {action &&
                <ModalOpener
                    action={action}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    status={status}
                    data={callId}
                />

            }
        </div>
    )
}

export default ComplaintCards