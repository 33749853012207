import React from 'react';
import { TextField } from "@mui/material";
import ImageHandler from '../ImageHandler';
import { Controller, useFormContext } from 'react-hook-form';

const Ent_detail = () => {
    const
        { control,
            formState: { errors }
        } = useFormContext();

    return (
        <div className=''>
            <div className='justify-center w-full'>
                <form className='mx-auto bg-gray-50 shadow-xl shadow-slate-400 p-8 px-8 rounded-lg'>
                    <h2 className='text-xl text-slate-800 font-bold text-center pb-5'>ENTERPRISE DETAILS</h2>
                    <div className='flex-col space-y-4 text-gray-600 px-16 py-2'>
                        <div className="flex flex-wrap gap-8">
                            <div className='flex flex-col gap-4'>
                                <Controller
                                    control={control}
                                    name='entName'
                                    rules={{ required: 'Enterprise Name is required' }}
                                    render={({ field }) => (
                                        <TextField
                                            className='w-80'
                                            placeholder='Please enter enterprise name'
                                            variant='outlined'
                                            label='Enterprise Name'
                                            {...field}
                                            error={Boolean(errors.entName)}
                                            helperText={errors.entName?.message}
                                        />
                                    )} />
                                <Controller
                                    control={control}
                                    name='description'
                                    rules={{ required: 'Please enter a short description' }}
                                    render={({ field }) => (
                                        <TextField
                                            placeholder='Please enter description'
                                            variant='outlined'
                                            multiline
                                            rows={4}
                                            label='Short Description'
                                            {...field}
                                            error={Boolean(errors.description)}
                                            helperText={errors.description?.message}
                                        />
                                    )} />
                            </div>
                            <ImageHandler />
                            {/* <div class="flex items-center justify-center w-80">
                                <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 hover:border-[#016FE2] border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                    <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Enterprise Logo</span></p>
                                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-[#016FE2]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                        </svg>
                                        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                                        <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                    </div>
                                    <input id="dropzone-file" type="file" class="hidden" />
                                </label>
                            </div> */}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Ent_detail