import React,  { useState, useEffect }  from 'react';
import stats from '../lib/stats';
import { Link } from "react-router-dom";
import getDashboardData from '../CommonFunctions/getDashboardData'


const Dashboard = () => {
    const [statsData, setStatsData] = useState({});
    useEffect(() => {
        getDashboardData().then((data) => {
            setStatsData(data);
        });
    }, [])
    return (
        <div className='flex flex-wrap justify-center w-full mt-4 py-4 gap-6 -z-10'>
            {stats.map((items, i) => (
                <Link to={items.path} >
                    <div className='flex flex-col p-8 w-96 bg-white rounded-lg gap-y-3 border shadow-themeBlue-4 shadow-md hover:bg-themeBlue-3 hover:shadow-lg hover:shadow-themeBlue-5'>
                        <div className='flex items-center gap-5'>
                            <div className='p-2 bg-themeBlue-1 rounded-lg'>
                                <span className='text-3xl text-white'>{items.icon}</span>
                            </div>
                            <div className='text-lg tracking-wide text-gray-700'>{items.title}</div>
                        </div>
                        <div className='text-md tracking-wide text-zinc-500'>{items.subTitle}</div>
                        <div className='flex items-center gap-5'>
                            <div className='text-xl font-semibold'>{statsData[items.value]}</div>
                            {/* <span className={`pl-2 text-xs font-medium ${items.status === 'up' ? 'text-themeRed' : 'text-themeGreen'}`}>{items.percentage}</span> */}
                            {/* <div className={`p-1 rounded-full ${items.status === 'up' ? 'border border-themeRed' : 'border border-themeGreen'}`}> */}
                                {/* {items.status === 'up' && <HiArrowNarrowDown className='fill-current text-themeRed' />}
                                {items.status === 'down' && <HiArrowNarrowUp className='fill-current text-themeGreen' />}
                            </div> */}
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    )
}

export default Dashboard

