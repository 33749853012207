import api from "../utils/axios";

const UploadFile = async (file, category) => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + token
        }
    }
    const data = {
        fileObj: file,
        category
    }
    const res = await api.post(`/api/v1/file/upload`, data, config);
        const fileUrl = res.data.data;
        return fileUrl;
}
export default UploadFile;