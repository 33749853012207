import React from 'react';
import { BiRupee } from "react-icons/bi";

const Charges = ({ financial }) => {
    const { tAamt, dAamt, technicalFee } = financial;
    return (
        <div className="max-w-md mx-auto bg-white rounded-xl shadow-sm overflow-hidden md:max-w-2xl">
            <div className='w-full grid md:grid-cols-1 grid-cols-1 gap-4'>
                <div className='flex md:flex-row md:gap-10 flex-col justify-center'>
                    <div className='flex flex-row md:gap-4 items-center'>
                        <div className="flex col-span-2 items-center gap-3 m-2 text-gray-600 ">
                            <div className='text-themeBlue-1'><BiRupee /></div>
                            <div className='text-zinc-700 font-semibold'>TA Amount</div>
                        </div>
                        <div className='flex items-center m-2 '>
                            <div className='text-gray-600'>
                                {tAamt}
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row md:gap-4 items-center'>
                        <div className="flex col-span-2 items-center gap-3 m-2 text-gray-600 ">
                            <div className='text-themeBlue-1'> <BiRupee /></div>
                            <div className='text-zinc-700 font-semibold'>DA Amount</div>
                        </div>
                        <div className='flex items-center m-2'>
                            <div className='text-gray-600'>
                                {dAamt}
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row md:gap-4 items-center'>
                        <div className="flex col-span-2 items-center gap-3 m-2 text-gray-600 ">
                            <div className='text-themeBlue-1'><BiRupee /></div>
                            <div className='text-zinc-700 font-semibold'>Technician Fee</div>
                        </div>
                        <div className='flex items-center m-2 '>
                            <div className='text-gray-600'>
                                {technicalFee}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Charges