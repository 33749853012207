import React from 'react';
import { useForm } from "react-hook-form";
import BtnOutlined, { BtnDefault } from '../../Components/BtnComponent';
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import { addRequestDescription } from '../../StateMgmt/Actions/reportActions';



const AddDescription = ({handleChange, id}) => {
    const {
        register,
        handleSubmit,
    } = useForm();
    const onSubmit = async(data) => {
        data.callId = id;
        const resData = await addRequestDescription(data)
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)} className='space-y-4 w-full'>
            <div className="grid ">
                <CustomizeTextField
                    placeholder='Please enter description'
                    variant='outlined'
                    multiline
                    rows={4}
                    label='Add Description'
                    name='description'
                    {...register("description", { required: 'Please add permission description' })}

                />

            </div>
            <div className="grid gap-4 md:grid-cols-2 grid-cols-1 gap-x-8 ">
                <BtnDefault
                    variant="contained"
                    color="primary"
                    size="medium"
                    text="Add Description"
                    type="submit"
                />
                <BtnOutlined
                    variant="outlined"
                    color="error"
                    size="medium"
                    text="Cancel"
                    border='#016fe2'
                    onClick={handleChange('panel1')}
                />
            </div>

        </form>
    )
}

export default AddDescription