import React, { useState } from "react";
import Add_self_product from "./EnterpriseForms/Add_self_product";
import Add_other_product from "./EnterpriseForms/Add_other_product";
import api from "../utils/axios";

const AddProductTabs = ({ setOpenPopup, data }) => {
    const [openTab, setOpenTab] = useState(1);
    const [productList, setProductList] = useState([]);


    const onSubmit = async () => {
        console.log(data)
        const reqObj = productList;
        reqObj.map(productObj => {
            productObj.invoice = data.invoice
            productObj.dealerName = data.dealerName
            productObj.id = data.id
            productObj.ProductId = productObj.pId ? productObj.pId : ''
            productObj.productName = productObj.pName ? productObj.pName : ''
            productObj.brand = productObj.brand ? productObj.brand : ''
            productObj.price = productObj.price ? productObj.price : ''
            productObj.sessionId = data.sessionId ? data.sessionId : ''
        })
        const req = { products: JSON.stringify(reqObj) }
        const res = await api.post('/api/v1/customer/product', req);

        console.log(res)

        setOpenPopup(false);
    }

    return (
        <>
            <div className="flex flex-col">
                <ul className="flex mb-0 list-none flex-wrap pb-4 flex-row" role="tablist">
                    <li className="-mb-px  last:mr-0 flex-auto text-center ">
                        <a className={"text-xs font-bold px-5 pr-2 py-3 shadow-md border-0  block hover:no-underline " + (openTab === 1 ? "bg-" + "themeBlue-1" : "bg-white hover:bg-[#ebf4fd]")}
                            onClick={e => {
                                e.preventDefault();
                                setOpenTab(1);
                            }}
                            data-toggle="tab"
                            href="#link1"
                            role="tablist"
                        >
                            <div className='justify-center'>
                                <span className={'text-lg uppercase font-normal ' + (openTab === 1 ? " text-white" : " text-themeBlue-1")}>
                                    Own Product
                                </span>
                            </div>
                        </a>
                    </li>
                    <li className="last:mr-0 flex-auto text-center">
                        <a className={"text-xs font-bold border-0 px-5 pr-2 py-3 shadow-md block hover:no-underline " + (openTab === 2 ? "bg-" + "themeBlue-1" : "bg-white hover:bg-[#ebf4fd]")}
                            onClick={e => {
                                e.preventDefault();
                                setOpenTab(2);
                            }}
                            data-toggle="tab"
                            href="#link1"
                            role="tablist"
                        >
                            <div className='justify-center'>
                                <span className={'text-lg uppercase font-normal ' + (openTab === 2 ? " text-white" : " text-themeBlue-1")}>
                                    Other Product
                                </span>
                            </div>
                        </a>
                    </li>
                </ul>
                <div className=" bg-white shadow-md ">
                    <div className="px-4 py-5 flex-auto">
                        <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                            <Add_self_product setOpenPopup={setOpenPopup} productList={productList} setProductList={setProductList} onSubmit={onSubmit} />
                        </div>
                        <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                            <Add_other_product setOpenPopup={setOpenPopup} productList={productList} setProductList={setProductList} onSubmit={onSubmit} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddProductTabs;