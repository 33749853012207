import React from 'react';
import { useForm } from "react-hook-form";
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import BtnOutlined, { BtnDefault } from '../BtnComponent';
import { editCustomer } from '../../StateMgmt/Actions/customerActions';

const Edit_SMS_creds = ({ setOpenPopup }) => {
    // const { name, phoneNumber, id } = currentData;
    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const onSubmit = async (data) => {
        const reqBody = data;
        // data.id = id;
        await editCustomer(reqBody);
        setOpenPopup(false);
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <CustomizeTextField
                    className='w-full'
                    placeholder='Please enter username'
                    // defaultValue={name}
                    variant='outlined'
                    label='Username'
                    name='name'
                    {...register("name", { required: true })}

                />
                <CustomizeTextField
                    className='w-full'
                    placeholder='Please enter password'
                    // defaultValue={phoneNumber}
                    variant='outlined'
                    label='Password'
                    name='phoneNumber'
                    {...register("phoneNumber", { required: true })}

                />

            </div>
            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <BtnDefault
                    variant="contained"
                    color="primary"
                    size="medium"
                    text="Save Changes"
                    type="submit"
                />
                <BtnOutlined
                    variant="outlined"
                    color="error"
                    size="medium"
                    text="Cancel"
                    onClick={() => setOpenPopup(false)}
                />
            </div>

        </form>
    )
}

export default Edit_SMS_creds