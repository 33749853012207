import React from 'react';
import { MdOutlineModeEditOutline } from 'react-icons/md'

const DisplayName = (props) => {
    return (
        // Render a <span> element
        <span className='text-2xl'>
            {
                // Use JavaScript's ternary operator to specify <span>'s inner content
                props.showInputEle ? (
                    <input
                        type="text"
                        value={props.value}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        autoFocus
                    />
                ) : (
                    <div className='flex space-x-6'>
                        <span className=''>{props.value}</span>
                        <span onClick={props.handleDoubleClick}><MdOutlineModeEditOutline fontSize='large'/></span>
                    </div>


                )
            }
        </span>
    );
}

export default DisplayName