import React, { useEffect, useState } from 'react';
import { Chart } from "react-google-charts";


const TechnicianAvgRating = ({ ratingData }) => {
    console.log(ratingData);
    const [data, setData] = useState(ratingData);

    useEffect(() => {
        if (!ratingData[1].length)
            setData(defaultData);
    }, [])

    const options = {
        title: "Average Rating of Technicians",
        chartArea: { width: "50%", height: "30%" },
        hAxis: {
            title: "Rating Count",
            minValue: 0,
        },
        vAxis: {
            title: "Rating",
        },
        bar: { groupWidth: "30%" },
        legend: { position: 'none' },

    };
    return (
        <Chart
            chartType="BarChart"
            width="100%"
            height="400px"
            data={data}
            options={options}
            
        />
    );
}

export default TechnicianAvgRating

// const data = [
//     ["Star", "Rating"],
//     ["5 Star", 20],
//     ["4 Star", 25],
//     ["3 Star", 39],
//     ["2 Star", 28],
//     ["1 Star", 17],
// ];

const defaultData = [
    ["Star", "Rating"],
    ["5 Star", 0],
    ["4 Star", 0],
    ["3 Star", 0],
    ["2 Star", 0],
    ["1 Star", 0],
];