import React, { useState } from 'react';
import CallStats from '../Components/Stats/CallStats';
import CustomerStats from '../Components/Stats/CustomerStats';
import RevenueStats from '../Components/Stats/RevenueStats';
import { FaRegBell } from 'react-icons/fa';
import { HiOutlineUsers } from 'react-icons/hi';
import { BiRupee } from "react-icons/bi";



const ReportTabs = () => {
    const [openTab, setOpenTab] = useState(1);

    return (
        <>
            <div className="flex flex-col h-auto mt-4">
                <ul className="flex list-none flex-wrap pb-4 flex-row mb-4" role="tablist">
                    <li className="-mb-px  last:mr-0 flex-auto text-center ">
                        <a className={"text-xs font-bold px-5 pr-2 py-3 shadow-md shadow-themeBlue-5 border block hover:no-underline " + (openTab === 1 ? "bg-" + "themeBlue-1" : "bg-white hover:bg-[#ebf4fd]")}
                            onClick={e => {
                                e.preventDefault();
                                setOpenTab(1);
                            }}
                            data-toggle="tab"
                            href="#link1"
                            role="tablist"
                        >
                            <div className="flex justify-center">
                                <div className={'rounded-full h-auto w-auto flex items-center justify-center'}>
                                    <FaRegBell className={'text-2xl' + (openTab === 1 ? " text-white" : " text-[#153B8C]")} />
                                </div>
                                <div className='pl-4'>
                                    <span className={'text-xl uppercase font-normal ' + (openTab === 1 ? " text-white" : " text-[#153B8C]")}>
                                        Complaint Based Report
                                    </span>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li className="last:mr-0 flex-auto text-center">
                        <a className={"text-xs font-bold border  px-5 pr-2 py-3 shadow-md shadow-themeBlue-5 block hover:no-underline " + (openTab === 2 ? "bg-" + "themeBlue-1" : "bg-white hover:bg-[#ebf4fd]")}
                            onClick={e => {
                                e.preventDefault();
                                setOpenTab(2);
                            }}
                            data-toggle="tab"
                            href="#link1"
                            role="tablist"
                        >
                            <div className="flex justify-center">
                                <div className={'rounded-full h-auto w-auto flex items-center justify-center'}>
                                    <HiOutlineUsers className={'text-2xl' + (openTab === 2 ? " text-white" : " text-[#153B8C]")} />
                                </div>
                                <div className='pl-4'>
                                    <span className={'text-xl uppercase font-normal ' + (openTab === 2 ? " text-white" : " text-[#153B8C]")}>
                                        Customer Feedback Based Report
                                    </span>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li className=" last:mr-0 flex-auto text-center">
                        <a className={"text-xs font-bold border px-5 pr-2 py-3 shadow-md shadow-themeBlue-5 block hover:no-underline " + (openTab === 3 ? "bg-" + "themeBlue-1" : "bg-white hover:bg-[#ebf4fd]")}
                            onClick={e => {
                                e.preventDefault();
                                setOpenTab(3);
                            }}
                            data-toggle="tab"
                            href="#link1"
                            role="tablist"
                        >
                            <div className="flex justify-center">
                                <div className={'rounded-full h-auto w-auto flex items-center justify-center'}>
                                    <BiRupee className={'text-2xl' + (openTab === 3 ? " text-white" : " text-[#153B8C]")} />
                                </div>
                                <div className='pl-4'>
                                    <span className={'text-xl uppercase font-normal ' + (openTab === 3 ? " text-white" : " text-[#153B8C]")}>
                                        Revenue and Cost Based Report
                                    </span>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
                <div className="shadow-md mt-4 overflow-y-scroll h-[77vh]">
                    <div className="px-4 py-5  bg-white">
                        <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                            <CallStats />
                        </div>
                        <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                            <CustomerStats />
                        </div>
                        <div className={openTab === 3 ? "block" : "hidden"} id="link2">
                            <RevenueStats />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportTabs