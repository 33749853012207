import api from "../../utils/axios";
import { successResponse } from "../../utils/utils";

export const getUserProfile = async () => {

    const res = await api.get('/api/v1/user/getProfile');
    const { entDetail, paymentDetail, userDetail } = res.data.data
    const { firstName, lastName, phoneNo, role, addressLine, city, country, state, pinCode, profilePic } = userDetail;
    const userName = [firstName, lastName].filter(Boolean).join(' ');
    const userAddress = `${addressLine}, \n ${city}, \n ${pinCode} ${state}, \n ${country}`;
    const { upiId } = paymentDetail
    const userData = { entDetail, paymentDetail, userDetail, profilePic }
    const profileData = { userName, role, userAddress, phoneNo, upiId, userData }
    return {
        type: "GET_USER_PROFILE",
        payload: profileData,
    }
};


export const editUserProfile = async (data, accountId) => {
    const formattedPayload = {
        "entDetail": {
            "accountName": data.entName,
            "description": data.description,
            "logo": data.logoImage
        },
        "userDetail": {
            "firstName": data.firstName,
            "lastName": data.lastName,
            "pinCode": data.pin,
            "city": data.city,
            "state": data.state,
            "addressLine": data.address,
            "profilePic": data.profileImage
        },
        "paymentDetail": {
            "upiId": data.upi
        }
    }
    const payload = { accountDetail: JSON.stringify(formattedPayload), accountId: accountId }
    const res = await api.post('/api/v1/account/updateAccount', payload);
    successResponse("User updated successfully!");

    return {
        type: "EDIT_USER_PROFILE",
        payload: res.data.data,
    }
};

export const getUserSmsTemplates = async () => {

    const res = await api.get('/api/v1/user/getSmsDetail');
    const {
        authkey,
        agentNewCallTemp,
        customerNewCallTemp,
        customerAgentAssTemp,
        newCustomerRequestTemp,
        signUpOtpTemp,
        feedbaclTemp,
        id,
        AccountId
    } = res.data.data

    const smsData =
    {
        authkey,
        agentNewCallTemp,
        customerNewCallTemp,
        customerAgentAssTemp,
        newCustomerRequestTemp,
        signUpOtpTemp,
        feedbaclTemp,
        id,
        AccountId
    }

    return {
        type: "GET_USER_SMS_TEMPLATES",
        payload: smsData,
    }
};

export const upsertSmsDetails = async (smsDetails) => {
    console.log(smsDetails)
    const payload = { data: JSON.stringify(smsDetails) }
    const res = await api.post('/api/v1/user/upsertSmsDetail', smsDetails);
    console.log(res)
    // successResponse("Details updated successfully!");

    return {
        type: "EDIT_SMS_DETAILS"
    }
};

