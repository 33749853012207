import axios from "axios";
import { toast } from 'react-toastify';
// const apiUrl = `${'http://3.7.226.102:8090'}`
// const apiUrl = `${'http://localhost:8090'}`
// const apiUrl = `https://api.sahayaks.com`;
// const apiUrl = `${'https://uatapi.sahayaks.com'}`


const headers = {
    'Content-Type': 'application/json'
}
console.log(process.env)
const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token') || null;
        config.headers.Authorization = 'Bearer ' + token;
        return config;
    });
api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    toast.error(`${error.response.data.message}`, {
        position: "top-right",
        padding: '30px 20px',
        width: '34vw',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        fontSize: '20px',
    });
    if (error.response.status === 401) {
        window.location.href = '/login';
      }
    return Promise.reject(error);
});
export default api;
