import React from 'react';
import { MdAssignmentLate, MdOutlineNature, MdReportProblem, MdPieChart } from "react-icons/md"
import { GiAutoRepair } from "react-icons/gi"

const ComplaintInfo = ({ complainAndStatus={} }) => {
  const { affectedArea, natureOfComplain, probleamObserved, serviceType, status } = complainAndStatus;

  return (
    <div className="max-w-md mx-auto bg-white rounded-xl shadow-sm overflow-hidden md:max-w-2xl">
      <div className='w-full grid md:grid-cols-2 grid-cols-1 gap-4'>
        <div className='flex flex-col'>
          <div className='flex md:flex-row flex-col md:gap-4'>
            <div className="flex items-center gap-3 m-2">
              <div className='text-themeBlue-1'><MdAssignmentLate /></div>
              <div className='text-zinc-700 font-semibold'>Status</div>
            </div>
            <div className='flex items-center m-2 col-span-2'>
              <div className='text-gray-600'>
                {status}
              </div>
            </div>
          </div>
          <div className='flex flex-row md:gap-4'>
            <div className="flex col-span-2 items-center gap-3 m-2 text-gray-600 ">
              <div className='text-themeBlue-1'><MdOutlineNature /></div>
              <div className='text-zinc-700 font-semibold'>Nature of Complaint</div>
            </div>
            <div className='flex items-center m-2 '>
              <div className='text-gray-600'>
                {natureOfComplain}
              </div>
            </div>
          </div>
          <div className='flex flex-row md:gap-4'>
            <div className="flex col-span-2 items-center gap-3 m-2 text-gray-600 ">
              <div className='text-themeBlue-1'> <MdPieChart /></div>
              <div className='text-zinc-700 font-semibold'>Affected Area</div>
            </div>
            <div className='flex items-center m-2'>
              <div className='text-gray-600'>
                {affectedArea}
              </div>
            </div>
          </div>

        </div>
        <div className=''>
          <div className='flex flex-row  md:gap-4'>
            <div className="flex items-center gap-3 m-2 text-gray-600 ">
              <div className='text-themeBlue-1'><GiAutoRepair /></div>
              <div className='text-zinc-700 font-semibold'>Service Type</div>
            </div>
            <div className='flex items-center m-2 col-span-2'>
              <div className='text-gray-600'>
                {serviceType}
              </div>
            </div>
          </div>

          <div className='flex md:flex-row flex-col md:gap-4'>
            <div className="flex col-span-2 items-center gap-3 m-2 text-gray-600 ">
              <div className='text-themeBlue-1'> <MdReportProblem /></div>
              <div className='text-zinc-700 font-semibold'>Problem Observed</div>
            </div>
            <div className='flex col-span-2 items-center md:m-2'>
              <div className='text-gray-600'>
                {probleamObserved}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default ComplaintInfo