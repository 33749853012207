import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import SectionHeader from '../Components/Headers/SectionHeader';
import { HiOutlineDocumentReport } from "react-icons/hi";
import ReportTabs from '../Components/ReportTabs';


const Reports = () => {
    return (
        <div className='flex flex-col px-2 '>
            <div className='mt-7 py-4 bg-white h-17 flex items-center justify-between p-2 lg:px-4 shadow-sm shadow-themeBlue-5 border'>
                <div className='flex gap-4 py-2'>
                    <span className='text-3xl text-themeBlue-1'>
                        <HiOutlineDocumentReport />
                    </span>
                    <h2 className='text-themeBlue-1 text-2xl font-semibold'>
                        Reports / Stats
                    </h2>
                </div>
            </div>
            <ReportTabs />
        </div>
    )
}

export default Reports