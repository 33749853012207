import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { TextField } from '@mui/material';
import api from '../../utils/axios';
import ReCAPTCHA from "react-google-recaptcha";



const Login = () => {
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) navigate(`/home`);
    }, []);

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();
    const navigate = useNavigate();
    const [phone, setPhone] = useState('');
    const [disabled, setDisabled] = useState(true);
    const captchaRef = useRef(null)


    const onCaptchaChange = () => {
        console.log('checked');
        // const token = captchaRef.current.getValue();
        console.log(captchaRef);
        setDisabled(!disabled)
    }

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            onSubmit();
        }
    }
    const onSubmit = (data) => {
        setPhone(data.mobile)
        api.post('/api/v1/auth/user/login', { phoneNo: data.mobile })
            .then(response => {
                if (response.data.data.code === "OTP") {
                    navigate('/otplogin', {
                        state: {
                            phone: data.mobile,
                            user_id: response.data.data.userId
                        }
                    });
                }
                else {
                    navigate('/pinlogin', {
                        state: {
                            user_id: response.data.data.userId,
                            phone: data.mobile
                        }
                    });
                }
            });
    }

    return (
        <div className='grid h-screen w-full'>
            <div className='flex flex-col justify-center pt-64'>
                <form onSubmit={handleSubmit(onSubmit)} className='max-w-[400px] w-full mx-auto bg-gray-50 shadow-xl shadow-slate-400 p-8 px-8 rounded-lg'>
                    <h2 className='text-3xl text-slate-800 font-bold text-center pb-5'>SIGN IN</h2>
                    <div className='flex flex-col text-gray-600 py-2'>
                        <label>Phone Number</label>
                        <TextField
                            className='rounded-lg bg-gray-200 border mt-2 p-2 focus:border-blue-300 focus:bg-blue-50 focus:outline-none'
                            type='text'
                            placeholder='Enter Your Mobile Number'
                            // value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            onKeyDown={handleKeyDown}
                            name='mobile'
                            {...register("mobile", { required: 'Mobile Number is required.' })}
                            error={Boolean(errors.mobile)}
                            helperText={errors.mobile?.message}
                        />

                    </div>
                    {/* <div className='py-2 my-2'>
                        <ReCAPTCHA
                            sitekey='6LdkK-4pAAAAAJ8ZkRXZZVssYntIvllFI9InQSF_'
                            onChange={onCaptchaChange}
                        />
                    </div> */}
                    <button
                        className={"w-full my-5 py-2 bg-themeBlue-1 hover:shadow-blue-500/30 text-white font-semibold rounded-lg "}
                        type="submit"
                    >
                        Next
                    </button>
                    {/* <button
                        className={"w-full my-5 py-2 hover:shadow-blue-500/30 text-white font-semibold rounded-lg " + (disabled ? "bg-gray-400" : "bg-" + "[#153B8C]")}
                        type="submit"
                        disabled={disabled}
                    >
                        Next
                    </button> */}
                </form>
            </div>
        </div>
    )
}


export default Login;
