import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { useForm } from "react-hook-form";
import Autocomplete from '@mui/material/Autocomplete';
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import BtnOutlined, { BtnDefault } from '../BtnComponent';
import getPinValue from '../../CommonFunctions/getPinValue';
import getRoles from '../../CommonFunctions/getRoles';
import { editTeamMember } from '../../StateMgmt/Actions/teamActions';


const idTypes = [
  { label: 'Aadhar Card', value: "ADHAR" },
  { label: 'PAN Card', value: "PAN" },
  { label: 'Voter ID Card', value: "VOTER" },
]

const EditMember = ({ setOpenPopup, memberData }) => {
  const { name, city, pinCode, roleName, isActive, phoneNumber, id, state, bankAccountNumber, bankName, idNumber, idType, ratePerVist, addressLine, RoleId, ifscCode } = memberData;
  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm();

  const [role, setRole] = useState(RoleId);
  const [roles, setRoles] = useState([]);
  const [id_type, setId_type] = useState(idType);
  const [options, setOptions] = useState([]);
  const [stateValue, setStateValue] = useState(state);
  const [districtValue, setDistrictValue] = useState(city);
  const [pinValue, setPinValue] = useState(pinCode);

  useEffect(() => {
    getRoles().then((data) => {
      setRoles(data)
      getPinValue(pinCode.slice(0,6), setOptions);
    });
  }, [])

  const handleSelectRole = (_, value, reason) => {
    setRole(value.id);
  }
  const handleSelectIDType = (_, value, reason) => {
    setId_type(value.value);
  }

  const onInputChange = (event, value, reason) => {
    if (value) {
      getPinValue(value, setOptions);
    } else {
      setOptions([]);
    }
  };

  const getValue = (value, field) => {
    setPinValue(value.label);
    setDistrictValue(value.value.district);
    setStateValue(value.value.state)
  }

  const onSubmit = async(data) => {
    const reqBody = data;
    reqBody.city = districtValue;
    reqBody.state = stateValue;
    reqBody.id_type = id_type;
    reqBody.role = role;
    reqBody.pin = pinValue;
    reqBody.id = id;
    await editTeamMember(reqBody);
    setOpenPopup(false);

  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
      <div className="grid gap-4 grid-cols-3 gap-x-8 ">
        <CustomizeTextField
          className='w-full'
          defaultValue={name}
          placeholder='Please enter member name'
          variant='outlined'
          label='Member Name'
          name='name'
          {...register("name", { required: 'Name is required.' })}
          error={Boolean(errors.firstName)}
          helperText={errors.firstName?.message}
        />
        <Stack spacing={2} sx={{ color: '#851851' }}>
          <Autocomplete
            defaultValue={roleName}
            disableClearable
            onChange={handleSelectRole}
            options={roles}
            renderInput={(params) => (
              <CustomizeTextField
                {...params}
                label='Member Role'
                name='role'
              />
            )}

          />
        </Stack>
        <CustomizeTextField
            className='w-full'
            defaultValue={phoneNumber}
            placeholder='Please enter member phone'
            variant='outlined'
            label='Member Phone'
            name='phone'
            inputProps={
              { readOnly: true, }
            }
          />
      </div>      
      <div className="grid gap-4 grid-cols-2 gap-x-8 ">
        <div className='flex flex-col gap-3'>
          <Stack spacing={2} sx={{ color: '#851851' }}>
            <Autocomplete
              error={Boolean(errors.role)}
              freeSolo
              options={options}
              onInputChange={onInputChange}
              onChange={(event, value) => {
                getValue(value)
              }}
              getOptionLabel={(option) => option.label}
              defaultValue={{label: pinCode}}
              renderInput={(params) => (
                <CustomizeTextField
                  {...params}
                  label='PIN Code'
                  name='pin'
                  // {...register("pin", { required: 'PIN is required.' })}
                  // error={Boolean(errors.pin)}
                  // helperText={errors.pin?.message}
                />
              )}

            />
          </Stack>
          <CustomizeTextField
          className='w-full'
          placeholder='Autofill'
          variant='outlined'
          value={districtValue}
          label='City / District'
          name='city'
          {...register("city", { required: 'City is required.' })}
        
        />
        <CustomizeTextField
          className='w-full'
          placeholder='Autofill'
          variant='outlined'
          value={stateValue}
          label='State'
          name='state'
          {...register("state", { required: 'State is required.' })}

        />
        </div>

        <CustomizeTextField
          placeholder='Please enter member address'
          variant='outlined'
          defaultValue={addressLine}
          multiline
          rows={7}
          // label='Member Address'
          name='address'
          {...register("address", { required: true })}
          error={Boolean(errors.address)}
          helperText={errors.address?.message}
        />

      </div>
      <div className="grid gap-4 grid-cols-3 gap-x-8 ">
        <CustomizeTextField
            className='w-full'
            defaultValue={bankName}
            placeholder='Please enter member bank account name'
            variant='outlined'
            label='Bank Account Name'
            name='bank'
            {...register("bank")}
            error={Boolean(errors.bank)}
            helperText={errors.bank?.message}
          />
           <CustomizeTextField
          className='w-full'
          defaultValue={bankAccountNumber}
          placeholder='Please enter member bank account number'
          variant='outlined'
          label='Bank A/C Number'
          name='ac_number'
          {...register("ac_number")}
        error={Boolean(errors.ac_number)}
        helperText={errors.ac_number?.message}
        />
        <CustomizeTextField
          className='w-full'
          defaultValue={ifscCode}
          placeholder='Please enter member bank IFSC Code'
          variant='outlined'
          label='IFSC Code'
          name='ifsc'
          {...register("ifsc")}
        error={Boolean(errors.ifsc)}
        helperText={errors.ifsc?.message}
        />

      </div>
      <div className="grid gap-4 grid-cols-3 gap-x-8 ">
        <Stack spacing={2} sx={{ color: '#851851' }}>
            <Autocomplete
              disableClearable
              defaultValue={id_type}
              onChange={handleSelectIDType}
              options={idTypes}
              renderInput={(params) => (
                <CustomizeTextField
                  {...params}
                  label='Member ID Type'
                  name='id_type'
                  {...register("id_type")}
                />
              )}
            />
          </Stack>
          <CustomizeTextField
            className='w-full'
            defaultValue={idNumber}
            placeholder='Please enter selected ID number'
            variant='outlined'
            label='ID Number'
            name='id_number'
            {...register("id_number")}
            error={Boolean(errors.id_number)}
            helperText={errors.id_number?.message}
          />
           <CustomizeTextField
            className='w-full'
            defaultValue={ratePerVist}
            placeholder='Please enter member rate/visit'
            variant='outlined'
            label='Rate Per Visit'
            name='rate'
            {...register("rate")}
            error={Boolean(errors.rate)}
            helperText={errors.rate?.message}
          />
      </div>
      <div className="grid gap-4 grid-cols-2 gap-x-8 ">
        <BtnDefault
          variant="contained"
          color="primary"
          size="medium"
          text="Submit"
          type="submit"
        />
        <BtnOutlined
          variant="outlined"
          color="error"
          size="medium"
          text="Cancel"
        onClick={() => setOpenPopup(false)}
        />
      </div>

    </form>
  )
}

export default EditMember