import React, { useMemo, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import RevenueData from '../../lib/RevenueData';
import { getTechnicianRevenueTable } from '../../StateMgmt/Actions/StatChartActions';
import TechnicianAvgRating from './TechnicianAvgRating';


const RevenueTable = () => {
    const [pageSize, setPageSize] = useState(10);
    const [rowId, setRowId] = useState(null);
    const [tableData, setTableData] = useState([])
    useEffect(() => {
        getTechnicianRevenueTable().then(({ payload }) => {
            setTableData(payload);
        });
    }, []);
    const columns = useMemo(() => [
        {
            field: 'userId',
            headerName: 'ID',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'phoneNumber',
            headerName: 'Phone',
            width: 180,
            headerAlign: 'center',
            align: 'center'
        }, 
        {
            field: 'totalCallCount',
            headerName: 'Total Call Count',
            width: 180,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'technicain_charge',
            headerName: 'Technician Fee',
            width: 300,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'total_dAamt',
            headerName: 'DA Amount',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'total_tAamt',
            headerName: 'TA Amount',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        // {
        //     field: 'Total_payment',
        //     headerName: 'Total Amount',
        //     width: 200,
        //     headerAlign: 'center',
        //     align: 'center'
        // },
        // {
        //     field: 'bank',
        //     headerName: 'Bank Details',
        //     width: 180,
        //     headerAlign: 'center',
        //     align: 'center'
        // }
    ], [rowId])

    return (
        <div className=''>
            <Box
                sx={gridStyle}>

                <DataGrid
                    columns={columns}
                    rows={tableData}
                    getRowId={(row) => row.id}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowSpacing={params => ({
                        top: params.isFirstVisible ? 0 : 5,
                        bottom: params.isLastVisible ? 0 : 5
                    })}
                />
            </Box>
        </div>
    )
}

export default RevenueTable

const gridStyle = {

    backgroundColor: '#fff',
    height: 600,
    padding: 2,
    width: '100%',
    '& .MuiDataGrid-root': {
        borderColor: '#153b8c',
        '& .MuiDataGrid-row': {
            borderColor: '#aed1f6',
            '&:hover': {
                bgcolor: '#ebf4fd',
            },
            '&.Mui-selected': {
                bgcolor: '#c2ddf8',
                '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: '#aed1f6',
                    borderColor: '#aed1f6',
                },
            }

        }
    },
    '& .MuiDataGrid-columnHeaders': {
        borderColor: '#153b8c',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        color: '#153b8c',
        fontSize: 16,
        fontWeight: 500,
        fontFamily: "sans-serif",
    },
}