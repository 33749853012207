import Stack from '@mui/material/Stack';
import CustomTextField from '../lib/Styles/textField_style';


export default function ProductSearchBar(props) {
   
    const { setProductFilter } = props;
    const search = (event) => {
        setProductFilter(event.target.value);
      }
    return (
        <Stack spacing={2} sx={{ width: 300, color: '#fff' }}>
           <CustomTextField
                sx={{
                    "& .MuiInputBase-root": {
                        color: '#fff'
                    }
                }}
                placeholder='Search Product'
                variant='outlined'
                label='Search Product'
                name='prodName'
                onChange={(event) => { search(event) }}
              />
        </Stack>
    );
}

