import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import Stack from '@mui/material/Stack';
import BtnOutlined, { BtnDefault } from '../BtnComponent';
import Autocomplete from '@mui/material/Autocomplete';
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import { updateCallDetails } from '../../StateMgmt/Actions/reportActions';
import GetOpenCallFormOptions from '../../CommonFunctions/GetOpenCallFormOptions';
import { successResponse } from '../../utils/utils';


const ReplacementForm = (props) => {

    const { setOpenPopup, status, id } = props;
    // const [role, setRole] = useState('');
    // const [team, setTeam] = useState('');
    const [value, setValue] = useState(null);
    const [productId, setProductId] = useState(null);
    const [productList, setProductList] = useState([]);
    const [query, setQuery] = useState('');


    useEffect(() => {
        GetOpenCallFormOptions(query).then(({ product }) => {
            setProductList(product)
        });
    }, [query])


    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const handleAutocompleteTextChange = (event) => {
        const productQuery = event.target.value;
        setQuery(productQuery);
    }
    const handleSelectProduct = (_, value, reason) => {
        setProductId(value.id);
    }
    const onSubmit = async (data) => {
        console.log(data)
        data.replacementProductId = productId;
        data.callId = id;
        data.status = status;
        console.log(data)
        const resData = await updateCallDetails(data);
        successResponse('Part replacment updated successfully!')
        setOpenPopup(false)
    }


    return (
        <div className='mt-5'>
            <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
                <div className="grid gap-4 md:grid-cols-3 grid-cols-1 gap-x-8 ">
                    <Stack spacing={2} sx={{ color: '#851851' }}>
                        <Autocomplete
                            error={Boolean(errors.role)}
                            disableClearable
                            onChange={handleSelectProduct}
                            options={productList}
                            renderInput={(params) => (
                                <CustomTextField
                                    {...params}
                                    label='Select Replacement Product'
                                    name='replacementProductId'
                                    {...register("replacementProductId", { required: 'Please select a product' })}
                                    error={Boolean(errors.replacementProductId)}
                                    helperText={errors.replacementProductId?.message}
                                    onChange={handleAutocompleteTextChange}

                                />
                            )}

                        />
                    </Stack>
                    <CustomizeTextField
                        className='w-full'
                        placeholder='Enter Product Quantity'
                        variant='outlined'
                        label='Replacement Product Quantity'
                        name='replacementProductQty'
                        {...register("replacementProductQty", { required: 'Please enter product replacment Quantity.' })}
                        error={Boolean(errors.replacementProductQty)}
                        helperText={errors.replacementProductQty?.message}
                    />
                    <CustomizeTextField
                        className='w-full'
                        placeholder='Enter Approver`s Name'
                        variant='outlined'
                        label='Approved By'
                        name='replacemntApprovedBy'
                        {...register("replacemntApprovedBy", { required: 'Please enter aprover name.' })}
                        error={Boolean(errors.replacemntApprovedBy)}
                        helperText={errors.replacemntApprovedBy?.message}
                    />
                </div>
                <div className="grid gap-4 md:grid-cols-2 grid-cols-1 gap-x-8 ">
                    <BtnDefault
                        variant="contained"
                        color="primary"
                        size="medium"
                        text="Save Changes"
                        type="submit"
                    />
                    <BtnOutlined
                        variant="outlined"
                        color="error"
                        size="medium"
                        text="Cancel"
                        border='#016fe2'
                        onClick={() => setOpenPopup(false)}
                    />
                </div>

            </form>
        </div>

    )
}

export default ReplacementForm


const CustomTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#153B8C'
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#153b8c',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#153b8c',
        },
        '&:hover fieldset': {
            borderColor: '#153b8c',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#153b8c',
        },
    },
});

const radioCss = {
    color: '#153b8c',
    '&.Mui-checked': {
        color: '#153b8c',
    },
};