import api from "../../utils/axios";
import { successResponse } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';



export const GetAllCustomers = async (filter) => {

    const res = await api.get(`/api/v1/customer/getAll?filter=${filter}`);
    return {
        type: "GET_ALL_CUSTOMERS",
        payload: res.data
    }
};

export const GetQRCustomers = async (filter) => {
    const res = await api.get(`/api/v1/customer/list?filter=${filter}`);
    return {
        type: "GET_QR_CUSTOMERS",
        payload: res.data
    }
};


export const getCustomerDetail = async (id) => {
    const res = await api.post('/api/v1/customer/getDetail', { id: id });
    return {
        type: "CUSTOMER_DETAIL",
        payload: res.data.data
    }
};

export const addNewCustomer = async (data) => {
    const { name, phoneNumber, pinCode, city, state, addressLine } = data
    const formattedResponse = {
        customer: {
            name,
            phoneNumber,
        },
        address: {
            pinCode,
            city,
            state,
            addressLine
        }
    };
    const payload = { customerDetail: JSON.stringify(formattedResponse) }
    const res = await api.post('/api/v1/customer/add', payload);
    successResponse("Customer added successfully!");
    return {
        type: "ADD_NEW_CUSTOMER",
        payload: res.data.data
    }
};

export const editCustomer = async (data) => {

    const res = await api.post('/api/v1/customer/update', data);
    successResponse("Customer updated successfully!");
    return {
        type: "EDIT_CUSTOMER",
        payload: res.data.data
    }
};

export const addCustomerAddress = async (data, customerId) => {

    const res = await api.post(`/api/v1/address/add?customerId=${customerId}`, { ...data });
    successResponse("Customer address added successfully!");

    return {
        type: "ADD_CUSTOMER_ADDRESS",
        payload: res.data.data
    }
};

export const addCustomerCall = async (data) => {

    const res = await api.post('/api/v1/call/create', { ...data });
    successResponse("Call opened successfully!");
    // return {
    //     type: "ADD_CUSTOMER_CALL",
    //     payload: res.data.data
    // }
};

export const searchCustomer = async (input, flag, navigate) => {

    let query = flag ? `phoneNumber=${input}` : ``;
    if (flag === 'CALL_ID') query = `callId=${input.toUpperCase()}`;

    const res = await api.get(`/api/v1/customer/search?${query}`);
    if (res?.data?.data?.CUST_ID) {
        const { payload } = await getCustomerDetail(res.data.data.CUST_ID);
        navigate(`/customerdetails/${res.data.data.CUST_ID}`, { state: payload })
    }
    return {
        type: "SEARCH_CUSTOMER",
        payload: res.data.data
    }
};

