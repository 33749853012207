import api from "../utils/axios";


const GetOpenCallFormOptions = async (query = '') => {

    const res = await api.get(`/api/v1/filter/call/create?productFilter=${query}`);
    const { billSatus, natureOfComplain, product, serviceCategory } = res.data.data;

    const formOptions = { billSatus, natureOfComplain, product, serviceCategory };
    return formOptions
}

export default GetOpenCallFormOptions;