import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { useForm } from "react-hook-form";
import { CustomizeTextField } from '../../../../lib/Styles/textField_style';
import BtnOutlined, { BtnDefault } from '../../../../Components/BtnComponent';
import { uploadImage } from '../../../../CommonFunctions/GetStepperData';
import { getTechnicianIDCard, updateTechnicianProfileDetails } from '../../../../StateMgmt/Actions/technicianActions';

const EditTechProfileForm = ({ setOpenPopup }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [name, setName] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [profilePic, setProfilePic] = useState('');


    useEffect(() => {
        getTechnicianIDCard().then(({ payload }) => {
            const { firstName, lastName, phoneNo, profilePic } = payload;
            const regName = [firstName, lastName].filter(Boolean).join(' ');
            setName(regName);
            setPhoneNo(phoneNo);
            setProfilePic(profilePic);
        })
    }, [])

    const [imageReader, setImageReader] = useState('');
    const [imageValue, setImageValue] = useState(profilePic);
    const [source, setSource] = useState('');

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const handleFile = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        setImageReader(reader);
        previewFile(reader);
    }

    const previewFile = (reader) => {
        reader.onloadend = () => {
            setSource(reader.result);
            setImageValue(reader.result)
        }
    }

    const onSubmit = async (data) => {
        const [firstName, ...lastName] = data.techName.split(' ');
        data.firstName = firstName;
        data.lastName = lastName.join(' ');
        if (imageReader) {
            const { imageUrl } = await uploadImage(imageReader, 'Profile');
            data.profilePic = imageUrl;
        } else {
            data.profilePic = imageValue;
        }
        await updateTechnicianProfileDetails(data);
        setOpenPopup(false)
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
            <div className="grid gap-4 md:grid-cols-2 grid-cols-1 gap-x-8 ">
                <div className='flex flex-col gap-4'>
                    <CustomizeTextField
                        placeholder='Please enter technician name'
                        value={name}
                        variant='outlined'
                        label='Technician Name'
                        name='techName'
                        {...register("techName", { required: 'Please enter technician name' })}
                        error={Boolean(errors.techName)}
                        helperText={errors.techName?.message}
                        disabled
                    />
                    <CustomizeTextField
                        placeholder='Please enter technician number'
                        value={phoneNo}
                        variant='outlined'
                        label='Technician Number'
                        name='techNumber'
                        error={Boolean(errors.techNumber)}
                        helperText={errors.techNumber?.message}
                        disabled

                    />
                </div>
                <Stack spacing={2} sx={{ color: '#851851' }}>
                    <div class="flex items-center justify-center">
                        <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 hover:border-[#016FE2] border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                            <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Profile Image</span></p>
                                <svg aria-hidden="true" class="w-10 h-4 text-[#016FE2]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                </svg>
                                <p class=" text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span></p>
                                {/* <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p> */}
                                {imageValue && (
                                    <div className='bg-themeBlue-5'>
                                        <img className='w-16 h-10' src={imageValue} alt='image' />
                                    </div>
                                )}
                            </div>
                            <input
                                id="dropzone-file"
                                type="file"
                                class="hidden"
                                onChange={(e) => {
                                    handleFile(e)
                                    setImageReader(e.target.files[0]);
                                }}
                            />
                        </label>
                    </div>
                </Stack>
            </div>


            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <BtnDefault
                    variant="contained"
                    color="primary"
                    size="medium"
                    text="Submit"
                    type="submit"
                />
                <BtnOutlined
                    variant="outlined"
                    color="error"
                    size="medium"
                    text="Cancel"
                    onClick={() => setOpenPopup(false)}
                />
            </div>

        </form>
    )
}

export default EditTechProfileForm