
const initialState = [];

const productReducers = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ALL_PRODUCTS":
            const products = action.payload
            return [...state, products];

        case "ADD_NEW_PRODUCT":
            const newProduct = action.payload
            return [...state, newProduct];

        case "DELETE_PRODUCT":
            return [...state];

        default: return state;
    }
}

export default productReducers;