import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form';


const ImageHandler = () => {
    const [source, setSource] = useState('');
    const
        { control,
            formState: { errors }
        } = useFormContext();


    const handleFile = (e) => {
        console.log(e)
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        previewFile(reader);
    }

    const previewFile = (reader) => {
        reader.onloadend = () => {
            setSource(reader.result)
        }
    }

    return (
        <div class="flex items-center justify-center w-80">
            <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 hover:border-[#016FE2] border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">

                <div class="flex flex-col items-center justify-center pt-2">
                    {source && (
                        <div className='bg-themeBlue-5'>
                            <img className='w-16 h-12' src={source} alt='image' />
                        </div>
                    )}
                    <div className='flex flex-col items-center justify-center pt-1 pb-6'>
                        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Enterprise Logo</span></p>
                        <svg aria-hidden="true" class="w-10 h-8 mb-3 text-[#016FE2]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                        </svg>
                        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                        <p class="text-xs text-gray-500 dark:text-gray-400">PNG, JPG or JPEG (MAX. 1 MB)</p>
                    </div>
                </div>
                <Controller
                    control={control}
                    name='logo'
                    rules={{ required: 'Please upload logo for your enterprise' }}
                    render={({ field: { ref,value,setValue, onChange, ...field } }) => (
                        <input
                            id="dropzone-file"
                            type="file"
                            accept="image/*"
                            class="hidden"
                            onChange={(e) => {
                                onChange(e.target.files[0]);
                                handleFile(e)
                            }}
                            error={Boolean(errors.logo)}
                            helperText={errors.logo?.message}
                        />


                    )} />
            </label>
        </div>
    )
}

export default ImageHandler