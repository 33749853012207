import React from 'react';


const ComplaintStatus = ({ params }) => {
    const isDue = params.row.isDue;

    return (
        <>
        {isDue === true && <div className= 'bg-red-300'
        >
            {params.row.status}
        </div>}
        {isDue !== true && <div >
            {params.row.status}
        </div>}
        
        </>
    )
}

export default ComplaintStatus;


