import { Slide, IconButton } from '@mui/material';
import React from 'react'
import ProfileCard from '../../../../Components/ProfileCard';
import { useUIContext } from '../../NavContext/Context'
import { ProfileContainer } from '../../Styles/Profile';
import CloseIcon from '@mui/icons-material/Close';

const Profile = () => {
    const { showProfile, setShowProfile } = useUIContext();

    return (
        <Slide direction='down' in={showProfile} timeout={500}>
            <ProfileContainer>
                <ProfileCard setShowProfile={setShowProfile}/>
                <IconButton
                    onClick={() => setShowProfile(false)}
                    sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10
                    }}>
                    <CloseIcon sx={{ fontSize: '4rem' }} color='secondary' />
                </IconButton>
            </ProfileContainer>
        </Slide>
    )
}

export default Profile