import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const ThankYouPage = () => {
  const location = useLocation();
  let message = location?.state?.message || '';
  return (
    <div className='grid h-screen w-full'>
      <div className='flex flex-col justify-center'>
        <div class="flex items-center justify-center h-screen">
          <div>
            <div class="flex flex-col items-center space-y-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="text-green-600 w-28 h-28" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <h1 class="text-4xl font-bold">Thank You !</h1>
              <p>{message}</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default ThankYouPage;
