import { ListItemButton, ListItemIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { NavList } from '../../Styles/AppBar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { ActionIconsContainerMobile, ActionIconsContainerDesktop } from '../../Styles/AppBar';
import { Colors } from '../../Styles/TechnicianTheme';
import { successResponse } from '../../../../utils/utils';
import { Avatar } from '@mui/material';



const ProfileAction = ({ matches, profilePic, setShowProfile }) => {
    const navigate = useNavigate();
    const Component = matches ? ActionIconsContainerMobile : ActionIconsContainerDesktop;


    return (
        <Component>
            <NavList>
                <ListItemButton
                    sx={{
                        justifyContent: 'flex-end',
                    }}
                >
                    <ListItemIcon
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            color: matches && Colors.secondary
                        }}
                    >
                        <div  onClick={() => setShowProfile(true)} >
                            <Avatar src={profilePic} />  
                        </div>
                        {/* <AccountCircleIcon fontSize='large' onClick={() => setShowProfile(true)} /> */}
                    </ListItemIcon>
                    <ListItemIcon
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            color: matches && Colors.secondary
                        }}
                    >
                        <LogoutIcon fontSize='large' onClick={() => { 
                            localStorage.clear(); 
                            successResponse('Successfully Logout!');
                            navigate('/login'); 
                            }} />
                    </ListItemIcon>
                </ListItemButton>
            </NavList>
        </Component>

    )
}

export default ProfileAction