import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./Components/Layouts/Layout";
import OnboardingLayout from './Components/Layouts/OnboardingLayout'
import Login from "./Components/Onboarding/Login";
import OTPLogin from "./Components/Onboarding/OTPLogin";
import CreatePIN from "./Components/Onboarding/CreatePIN";
import PINLogin from "./Components/Onboarding/PINLogin";
import Error from "./Components/Error";
import FormStepper from "./Components/FormStepper";
import Dashboard from "./Pages/Dashboard";
import Products from "./Pages/Products";
import Teams from "./Pages/Teams";
import Customers from "./Pages/Customers";
import Complaints from "./Pages/Complaints";
import CustomerDetails from "./Pages/CustomerDetails";
import Reports from "./Pages/Reports";
import RegisterComplaint from "./Pages/RegisterComplaint";
import Profile from "./Pages/Profile";
import { getUser } from "./utils/utils";
import TechnicianLanding from "./Pages/Technician/TechnicianLanding";
import CustomerComplaints from "./Pages/CustomerComplaints";
import ThankYou from "./Pages/ThankYouPage";
import CustomerFeedback from "./Pages/CustomerFeedback";
import MobileLogin from "./Pages/QRScanSystem/MobileLogin";
import EnterOTP from "./Pages/QRScanSystem/EnterOTP";
import GrafdoerLayout from "./Components/Layouts/GrafdoerLayout";
import ProductScanInfo from "./Pages/QRScanSystem/ProductScanInfo";
import ProductQRData from "./Pages/QRScanSystem/ProductQRData";
import ThankYouPage from "./Pages/ThankYouPage";
import ExternalUsers from "./Pages/ExternalUsers";
import RenderPdf from "./Pages/RenderPdf";
import Settings from "./Pages/Settings";
import Test from "./Pages/QRScanSystem/Test";
import MessageSettings from "./Pages/MessageSettings";
import ProductCategories from "./Pages/ProductCategories";
import ProductBrands from "./Pages/ProductBrands";
import ProductSettings from "./Pages/ProductSettings";
import { useState } from "react";
import ResetPIN from "./Components/Onboarding/ResetPIN";


function App() {
  const user = getUser();
  const role = user?.role || 'Admin';
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route exact path='/home' element={role === 'Admin' && <Dashboard />} />
          <Route exact path='/products' element={role === 'Admin' && <Products />} />
          <Route exact path='/categories' element={role === 'Admin' && <ProductCategories />} />
          <Route exact path='/brands' element={role === 'Admin' && <ProductBrands />} />
          <Route exact path='/teams' element={role === 'Admin' && <Teams />} />
          <Route exact path='/customers' element={role === 'Admin' && <Customers />} />
          <Route exact path='/customerdetails/:customerId' element={<CustomerDetails />} />
          <Route exact path='/complaints' element={<Complaints />} />
          <Route exact path='/cust_complaints' element={<CustomerComplaints />} />
          <Route exact path='/registercomplaints' element={<RegisterComplaint />} />
          <Route exact path='/reports' element={role === 'Admin' && <Reports />} />
          <Route exact path='/external_users' element={<ExternalUsers />} />
          <Route exact path='/open-invoice/:invoice' element={<RenderPdf />} />
          <Route exact path='/profile' element={<Profile />} />
          {/* <Route exact path='/settings' element={<MessageSettings />} /> */}
          <Route exact path='/settings' element={<ProductSettings />} />
          <Route path="*" element={<Error />} />
        </Route>

        <Route path='/'>
          <Route path='/technician' element={role !== 'Customer Executive' && <TechnicianLanding />} />
        </Route>

        <Route path='/' element={<OnboardingLayout />}>
          <Route exact path='/customer_feedback/:feedback_trackid' element={<CustomerFeedback />} />
          <Route exact path='/customer_feedback/thanks' element={<ThankYou />} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/reset_pin' element={<ResetPIN />} />
          <Route exact path='/otplogin' element={<OTPLogin />} />
          <Route exact path='/createpin' element={<CreatePIN />} />
          <Route exact path='/pinlogin' element={<PINLogin />} />
          <Route exact path='/ent_details' element={<FormStepper />} />

          {/* <Route exact path='/test' element={<Test />} /> */}

        </Route>

        {/* <Route path='/' element={<GrafdoerLayout />}> */}
        <Route path='/' element={<GrafdoerLayout />}>
          <Route exact path='/productQRData' element={<ProductQRData />} />
          <Route exact path='/QRScan' element={<MobileLogin />} />
          <Route exact path='/enterOtp' element={<EnterOTP />} />
          <Route exact path='/productInfo' element={<ProductScanInfo />} />
          <Route exact path='/thankyou' element={<ThankYouPage />} />
        </Route>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
// className='min-h-screen bg-cover bg-no-repeat bg-fixed bg-center bg-pack-train'