import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import BtnOutlined, { BtnDefault, BtnText } from '../BtnComponent';
import { IconButton, Tooltip, Button } from '@mui/material';
import { Delete, Add } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { addNewProductCategory } from '../../StateMgmt/Actions/productActions';

const Create_category = ({ setOpenPopup }) => {
    const dispatch = useDispatch()
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [subCategories, setSubCategories] = useState([]);

    const addCategory = () => {
        setSubCategories([...subCategories, subCategory]);
        setSubCategory();
    }

    const removeSubCategory = (sub_category) => {
        const index = subCategories.indexOf(sub_category);
        console.log(index)
        const filteredSubCategories = [...subCategories];
        filteredSubCategories.splice(index, 1);
        console.log(filteredSubCategories)
        setSubCategories(filteredSubCategories);
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const onSubmit = async (data) => {
        addCategory();
        const reqBody = data;
        reqBody.categoryName = category;
        reqBody.subCategories = subCategories;
        const resData = await addNewProductCategory(reqBody)
        setOpenPopup(false);
        dispatch(resData.payload);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
            <div className="grid gap-4 grid-cols-1 gap-x-8 ">
                <CustomizeTextField
                    className='w-full'
                    placeholder='Please enter category name'
                    variant='outlined'
                    label='Category Name'
                    name='category'
                    onChange={e => setCategory(e.target.value)}
                />
            </div>

            {/* to display entered subcategories with remove options */}
            {
                subCategories.map((subc, index) => (
                    <div className="grid gap-4 grid-cols-4 gap-x-3" key={index}>

                        <div className='col-span-3'>
                            <CustomizeTextField
                                disabled
                                variant='outlined'
                                value={subc}
                                className='w-full'
                                label='Subcategory Name'

                            />
                        </div>

                        <div className='ml-2'>
                            <Tooltip title='Remove Inputs' placement="right">
                                <IconButton
                                    sx={{
                                        width: 55,
                                        height: 55,
                                        color: '#153b8c',
                                        '&:hover': { bgcolor: '#153b8c', color: grey[50] }
                                    }}
                                    onClick={() => removeSubCategory(subc)}
                                >
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                ))
            }



            <div className="grid gap-4 grid-cols-4 gap-x-3">
                <div className='col-span-3'>
                    <CustomizeTextField
                        // disabled
                        value={subCategory || ''}
                        className='w-full'
                        variant='outlined'
                        placeholder='Please enter subcategory'
                        label='Subcategory Name'
                        name='subcategory'
                        onChange={e => setSubCategory(e.target.value)}
                    />
                </div>
                <div className='ml-2 mt-2' >
                    <BtnText
                        disabled={category && subCategory ? false : true}
                        variant="text"
                        size="medium"
                        text="Add More"
                        type="submit"
                        startIcon={<Add />}
                        onClick={addCategory}
                        color='#153b8c'
                    />
                </div>
            </div>


            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <BtnDefault
                    variant="contained"
                    color="primary"
                    size="medium"
                    text="Submit"
                    type="submit"
                />
                <BtnOutlined
                    variant="outlined"
                    color="error"
                    size="medium"
                    text="Cancel"
                    onClick={() => setOpenPopup(false)}
                />
            </div>

        </form>
    )
}

export default Create_category