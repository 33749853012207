import { Button as MUIButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react'

const BtnOutlined = (props) => {

    const { text, size, color, variant, onClick, border, startIcon, ...other } = props;

    const Button = styled(MUIButton)({
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        color: border,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        borderColor: border,
        '&:hover': {
            backgroundColor: '#5b76af',
            color: '#348ce8',
            borderColor: '#348ce8',
            boxShadow: 'none',
        }
    });

    return (
        <Button
            variant={variant}
            size={size}
            color={color}
            onClick={onClick}
            startIcon={startIcon}
            {...other}
        >
            {text}
        </Button>
    )
}

export const BtnDefault = (props) => {

    const { text, size, color, variant, onClick, startIcon, ...other } = props;

    const Button = styled(MUIButton)({
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#153b8c',
        borderColor: '#153b8c',
        '&:hover': {
            backgroundColor: '#016fe2',
            borderColor: '#153b8c',
            boxShadow: 'none',
        }
    });
    return (
        <Button
            variant={variant}
            onClick={onClick}
            startIcon={startIcon}
            {...other}
        >
            {text}
        </Button>

    )
}

export default BtnOutlined

export const BtnText = (props) => {

    const { text, size, color, variant, onClick, startIcon, ...other } = props;

    const Button = styled(MUIButton)({
        color: color,
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        lineHeight: 1.5,
        '&:hover': {
            backgroundColor: '#e8ebf4',
            borderColor: '#153b8c',
            boxShadow: 'none',
        }
    });
    return (
        <Button
            variant={variant}
            onClick={onClick}
            startIcon={startIcon}
            {...other}
        >
            {text}
        </Button>

    )
}