import React, { useEffect, useState } from 'react';
import { Chart } from "react-google-charts";
import { getTechnicianAvgRating } from '../../StateMgmt/Actions/StatChartActions';


const AvgRatingLine = () => {

    const [avgRatingData, setAvgRatingData] = useState([]);

    useEffect(() => {
        getTechnicianAvgRating().then(({ payload }) => {
            setAvgRatingData(payload);
        });
    }, []);

    const options = {
        chart: {
            title: "Averge Rating of Technicians",
            subtitle: "where each line represents a technician",
        },
    };
    return (
        <Chart
            chartType="Line"
            width="100%"
            height="400px"
            data={avgRatingData}
            options={options}
        // spreadSheetUrl="https://docs.google.com/spreadsheets/d/1jN0iw0usssnsG1_oi-NXtuKfsUsGme09GsFidbqxFYA"
        // toolbarItems={[
        //     {
        //         type: "csv",
        //         datasource:
        //             "https://spreadsheets.google.com/tq?key=1jN0iw0usssnsG1_oi-NXtuKfsUsGme09GsFidbqxFYA",
        //     },
        // ]}
        />
    );
}

export default AvgRatingLine

// const data = [
//     [
//         "Rating",
//         "Jordan",
//         "Eric",
//         "Wilson",
//         "Gary"
//     ],
//     [1, 37.8, 80.8, 41.8, 60.2],
//     [2, 30.9, 69.5, 32.4, 53.2],
//     [3, 25.4, 57, 25.7, 38.5],
//     [4, 11.7, 18.8, 10.5, 42.6],
//     [5, 11.9, 17.6, 10.4, 28.6],
//     [6, 8.8, 13.6, 7.7, 45.6],
//     [7, 7.6, 12.3, 9.6, 65.3],
//     [8, 12.3, 29.2, 10.6, 27.8],
//     [9, 16.9, 42.9, 14.8, 43.2],
//     [10, 12.8, 30.9, 11.6, 23.4],
//     [11, 5.3, 7.9, 4.7, 45.5],
//     [12, 6.6, 8.4, 5.2, 34.2],
//     [13, 4.8, 6.3, 3.6, 32.1],
//     [14, 4.2, 6.2, 3.4, 13.5],
// ];

// const data = [
//     [
//         "Months",
//         "testhar",
//         "Customer User"
//     ],
//     [
//         "May 2023",
//         4.0000,
//         3.0000
//     ],
//     [
//         "Nov 2023",
//         5.0000,
//         0
//     ],
//     [
//         "Dec 2023",
//         3.0000,
//         0
//     ]
// ]
