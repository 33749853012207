import api from "../utils/axios";


const downloadGenericQR = async () => {
    const res = await api.get(`/api/v1/customer/QR`);
    const genericQR = res.data.data.url;
    const filename = genericQR.split('/').pop();
    const link = document.createElement('a');
    link.href = genericQR;
    link.setAttribute('download', filename); //or any other extension
    link.click();
    return genericQR
}

export default downloadGenericQR;