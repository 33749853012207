import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';

const ActionIcon = ({title, icon, handler}) => {
    return (
        <>
            <Tooltip title={title}>
                <IconButton
                    sx={{
                        width: 40,
                        height: 40,
                        color: '#153b8c',
                        '&:hover': { bgcolor: '#153b8c', color: grey[50] }
                    }}
                    onClick={handler}
                    >
                    {icon}
                </IconButton>
            </Tooltip>
        </>
    )
}

export default ActionIcon