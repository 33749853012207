import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { BiCommentCheck } from 'react-icons/bi';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddDescription from './AddDescription';

const VerticalStepper = ({ requestDescription, id }) => {

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <div className='flex flex-col gap-4'>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                <AccordionSummary
                    // sx={{ background: '#a1b1d1' }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        <span className='text-sm font-semibold md:w-1/3'>Add Description</span>
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                        <span className='text-sm md:block hidden'>Click to expand</span>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <AddDescription handleChange={handleChange} id={id} />
                </AccordionDetails>
            </Accordion>
            <VerticalTimeline lineColor='#153B8C' layout='1-column-left' className=''>
                {requestDescription.map((desc, i) => (
                    <VerticalTimelineElement
                        key={i}
                        className=""
                        contentStyle={{ background: '#153B8C', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  #153B8C' }}
                        date={desc.createdAt}
                        iconStyle={{ background: '#153B8C', color: '#fff' }}
                        icon={<BiCommentCheck />}
                    >
                        {/* <h3 className="vertical-timeline-element-title">Admin</h3> */}
                        <h4 className="vertical-timeline-element-subtitle">{desc.createdBy}</h4>
                        <p>
                            {desc.description}
                        </p>
                    </VerticalTimelineElement>
                ))}
            </VerticalTimeline>


        </div>
    )
}

export default VerticalStepper




// <VerticalTimeline lineColor='#153B8C' layout='1-column-left' className=''>
//                 <VerticalTimelineElement
//                     className=""
//                     contentStyle={{ background: '#153B8C', color: '#fff' }}
//                     contentArrowStyle={{ borderRight: '7px solid  #153B8C' }}
//                     date="Thursday Feb 02 2023"
//                     iconStyle={{ background: '#153B8C', color: '#fff' }}
//                     icon={<BiCommentCheck />}
//                 >
//                     <h3 className="vertical-timeline-element-title">Admin</h3>
//                     <h4 className="vertical-timeline-element-subtitle">Nishant Deb</h4>
//                     <p>
//                         Complaint Description
//                     </p>
//                 </VerticalTimelineElement>
//                 <VerticalTimelineElement
//                     className=""
//                     contentStyle={{ background: '#153B8C', color: '#fff' }}
//                     contentArrowStyle={{ borderRight: '7px solid  #153B8C' }}
//                     date="Thursday Feb 02 2023"
//                     iconStyle={{ background: '#153B8C', color: '#fff' }}
//                     icon={<BiCommentCheck />}
//                 >
//                     <h3 className="vertical-timeline-element-title">Admin</h3>
//                     <h4 className="vertical-timeline-element-subtitle">Nishant Deb</h4>
//                     <p>
//                         Complaint Description
//                     </p>
//                 </VerticalTimelineElement>
//                 <VerticalTimelineElement
//                     className=""
//                     contentStyle={{ background: '#153B8C', color: '#fff' }}
//                     contentArrowStyle={{ borderRight: '7px solid  #153B8C' }}
//                     date="Thursday Feb 02 2023"
//                     iconStyle={{ background: '#153B8C', color: '#fff' }}
//                     icon={<BiCommentCheck />}
//                 >
//                     <h3 className="vertical-timeline-element-title">Admin</h3>
//                     <h4 className="vertical-timeline-element-subtitle">Nishant Deb</h4>
//                     <p>
//                         Complaint Description
//                     </p>
//                 </VerticalTimelineElement>

//             </VerticalTimeline>























// import React from 'react';
// import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
// import 'react-vertical-timeline-component/style.min.css';
// import { FaGraduationCap } from 'react-icons/fa';

// const VerticalStepper = () => {
//     return (
//         <div className='experience'>
//             <VerticalTimeline lineColor='#000'>
//                 <VerticalTimelineElement
//                     className='vertical-timeline-element'
//                     iconStyle={{ background: '#000', color: '#fff' }}
//                     icon={<FaGraduationCap />}
//                     date='18/09/2013'
//                 >
//                     <h3>Description</h3>
//                     <p>Admin Comments and Description</p>
//                 </VerticalTimelineElement>
//                 <VerticalTimelineElement
//                     className='vertical-timeline-element'
//                     iconStyle={{ background: '#000', color: '#fff' }}
//                     icon={<FaGraduationCap />}
//                     date='18/09/2013'
//                 >
//                     <h3>Description</h3>
//                     <p>Admin Comments and Description</p>
//                 </VerticalTimelineElement>
//                 <VerticalTimelineElement
//                     className='vertical-timeline-element'
//                     iconStyle={{ background: '#000', color: '#fff' }}
//                     icon={<FaGraduationCap />}
//                     date='18/09/2013'
//                 >
//                     <h3>Description</h3>
//                     <p>Admin Comments and Description</p>
//                 </VerticalTimelineElement>
//             </VerticalTimeline>
//         </div>
//     )
// }

// export default VerticalStepper