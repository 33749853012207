import { Divider, Drawer, lighten, List, ListItemButton, ListItemText, styled } from '@mui/material'
import React from 'react'
import { useUIContext } from '../../NavContext/Context'
import { DrawerCloseButton } from '../../Styles/AppBar'
import CloseIcon from '@mui/icons-material/Close'
import { Colors } from '../../Styles/TechnicianTheme'

const SideNav = () => {
    const { drawerOpen, setDrawerOpen } = useUIContext()
    return (
        <>
            {drawerOpen && (
                <DrawerCloseButton onClick={() => setDrawerOpen(false)}>
                    <CloseIcon sx={{
                        fontSize: '2.5rem',
                        color: lighten(Colors.secondary, 0.09)
                    }} />
                </DrawerCloseButton>
            )}
            <Drawer open={drawerOpen}>
                <List>
                    <ListItemButton>
                        <ListItemText>Home</ListItemText>
                    </ListItemButton>
                    <MiddleDivider />
                    <ListItemButton>
                        <ListItemText>Assigned Complaints</ListItemText>
                    </ListItemButton>
                    <MiddleDivider />

                </List>
            </Drawer>
        </>

    )
}

export default SideNav

const MiddleDivider = styled((props) => (
    <Divider variant='middle' {...props} />
))``;