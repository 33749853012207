import { Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import React from 'react';
import BtnOutlined, { BtnDefault } from '../BtnComponent';
import { useNavigate } from 'react-router-dom';


const IDCardModal = (props) => {

    const navigate = useNavigate();
    const { openPopup, setOpenPopup, data, icon, header, details, id, avatar } = props;

    const handleProfileClick = () => {
        setOpenPopup(false)

    }

    const closeDialog = () => setOpenPopup(false);

    return (
        <Dialog open={openPopup} onClose={closeDialog} width='800px' sx={design}>
            <Box>
                <DialogTitle className='flex justify-center space-x-2 text-themeBlue-2 bg-themeBlue-4'>
                    {icon}
                    <Typography className='text-sm font-extrabold tracking-wide'>
                        {header}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers className='flex justify-center space-y-3'>
                    <DialogContentText className='flex flex-col my-5 space-y-2'>
                        {details}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='flex space-x-3'>
                    <BtnOutlined
                        variant="outlined"
                        color="error"
                        size="medium"
                        text="Close"
                        onClick={() => setOpenPopup(false)}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default IDCardModal




const design = {
    '& .MuiDialogContent-root': {
        justifyContent: 'center',
        alignItems: 'center'
    },
    '& .MuiDialogActions-root': {
        justifyContent: 'center',
        alignItems: 'center'
    }
}
