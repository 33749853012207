import React, { useEffect, useState } from 'react';
import { Avatar, Tooltip } from '@mui/material';
import { Message, Edit, Preview, Lock, Password } from '@mui/icons-material';
import SectionHeader from '../Components/Headers/SectionHeader'
import { FiSettings } from 'react-icons/fi'
import ProductCategories from './ProductCategories';
import ProductBrands from './ProductBrands';


const ProductSettings = () => {

    const [openPopup, setOpenPopup] = useState(false);
    const [action, setAction] = useState('');
    const [messageId, setMessageId] = useState('');
    const [openTab, setOpenTab] = useState(1);

    useEffect(() => {

    }, [])



    return (
        <>
            {/* <SectionHeader
                icon={<FiSettings />}
                headingText='Product Settings and Categories'
                column='name'
                searchFlag={false}
                addBtnFlag={false}
            /> */}

            <div className='flex flex-wrap justify-center w-full mt-2 py-1 gap-6 -z-10'>

                <a className={"flex flex-col p-6 w-96 rounded-lg gap-y-3 border shadow-themeBlue-4 shadow-md hover:shadow-lg hover:shadow-themeBlue-5 " + (openTab === 1 ? "bg-themeBlue-1" : "bg-white hover:bg-themeBlue-3")}
                    onClick={e => {
                        e.preventDefault();
                        setOpenTab(1);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                >
                    <div className='flex items-center gap-5'>
                        <div className={'p-2 rounded-lg' + (openTab === 1 ? " bg-white" : " bg-themeBlue-1")}>
                            <span className={'text-3xl' + (openTab === 1 ? " text-themeBlue-1" : " text-white")}><FiSettings /></span>
                        </div>
                        <div className={'text-xl font-semibold tracking-wide text-gray-700 ' + (openTab === 1 ? " text-white" : " text-themeBlue-1")}>Categories</div>
                    </div>
                </a>

                <a className={"flex flex-col p-6 w-96 rounded-lg gap-y-3 border shadow-themeBlue-4 shadow-md hover:shadow-lg hover:shadow-themeBlue-5 " + (openTab === 2 ? "bg-themeBlue-1" : "bg-white hover:bg-themeBlue-3")}
                    onClick={e => {
                        e.preventDefault();
                        setOpenTab(2);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                >
                    <div className='flex items-center gap-5'>
                        <div className={'p-2 rounded-lg' + (openTab === 2 ? " bg-white" : " bg-themeBlue-1")}>
                            <span className={'text-3xl' + (openTab === 2 ? " text-themeBlue-1" : " text-white")}><FiSettings /></span>
                        </div>
                        <div className={'text-xl font-semibold tracking-wide text-gray-700 ' + (openTab === 2 ? " text-white" : " text-themeBlue-1")}>Brands</div>
                    </div>
                </a>


            </div>

            <>
                <div className="flex flex-wrap px-0 py-0" >
                    <div className="w-full">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full h-full mb-6 shadow-md  ">
                            <div className="px-4 py-0 flex-auto">
                                <div className="tab-content tab-space h-100">
                                    <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                                        <ProductCategories />
                                    </div>
                                    <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                                        <ProductBrands />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        </>

    )
}

export default ProductSettings