import { BtnDefault } from "../Components/BtnComponent";
import { AssignmentInd, Preview, PostAdd, YoutubeSearchedFor, MarkChatRead } from '@mui/icons-material';



const getActionBtn = (status, setOpenPopup, setAction, setStatus) => {

    switch (status) {
        case 'UNASSIGNED':
            return <BtnDefault
                variant="contained"
                color="primary"
                size="medium"
                text="View"
                startIcon=<Preview />
                onClick={() => {
                    setAction('Assign');
                    setOpenPopup(true);
                    setStatus('UNASSIGNED');
                }}
            />

        case 'ASSIGNED':
            return <BtnDefault
                variant="contained"
                color="primary"
                size="medium"
                text="View"
                startIcon=<Preview />
                onClick={() => {
                    setAction('CheckStatus');
                    setOpenPopup(true);
                    setStatus('ASSIGNED');
                }}
            />

        case 'PART PENDING':
            return <BtnDefault
                variant="contained"
                color="primary"
                size="medium"
                text="View"
                startIcon=<Preview />
                onClick={() => {
                    setAction('PartPendingInputs');
                    setOpenPopup(true);
                    setStatus('PART PENDING');
                }}
            />

        case 'REPLACEMENT':
            return <BtnDefault
                variant="contained"
                color="primary"
                size="medium"
                text="View"
                startIcon=<Preview />
                onClick={() => {
                    setAction('ReplacementInputs');
                    setOpenPopup(true);
                    setStatus('REPLACEMENT');
                }}
            />

        case 'COMPLETED':
            return <BtnDefault
                variant="contained"
                color="primary"
                size="medium"
                text="View"
                startIcon=<Preview />
                onClick={() => {
                    setAction('Completed');
                    setOpenPopup(true);
                    setStatus('COMPLETED');
                }}
            />

        case 'CLOSED':
            return <BtnDefault
                variant="contained"
                color="primary"
                size="medium"
                text="View"
                startIcon=<Preview />
                onClick={() => {
                    setAction('Closed');
                    setOpenPopup(true);
                    setStatus('CLOSED');
                }}
            />

        case 'REJECTED':
            return <BtnDefault
                variant="contained"
                color="primary"
                size="medium"
                text="View"
                startIcon=<Preview />
                onClick={() => {
                    setAction('Rejected');
                    setOpenPopup(true);
                    setStatus('REJECTED');
                }}
            />
        default:
            return <div>Default Modal, no action received</div>
    }
}

export default getActionBtn;