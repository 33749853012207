import api from "../../utils/axios";
import { successResponse } from "../../utils/utils";


export const getTechnicianCallList = async () => {
    const res = await api.get(`/api/v1/technician/getCalls`);

    return {
        type: "GET_TECHNICIAN_CALL_LIST",
        payload: res.data.data,
    }
};

export const getCompletedCallList = async () => {
    const res = await api.get(`/api/v1/technician/getCompletedCall`);

    return {
        type: "GET_TECHNICIAN_CALL_LIST",
        payload: res.data.data,
    }
};

export const getTechnicianIDCard = async () => {
    const res = await api.get(`/api/v1/user/getDetail`);

    return {
        type: "GET_TECHNICIAN_ID_CARD",
        payload: res.data.data,
    }
};

export const updateTechnicianCallDetails = async (details) => {
    const res = await api.post('/api/v1/technician/updateCall', { ...details });
    successResponse("Call data updated successfully!");

    // return {
    //     type: "ADD_REQUEST_DESCRIPTION",
    //     payload: res.data.data,
    // }
};

export const updateTechnicianProfileDetails = async (details) => {
    const res = await api.post('/api/v1/user/UpdateDetail', { ...details });
    successResponse("Call data updated successfully!");

    // return {
    //     type: "ADD_REQUEST_DESCRIPTION",
    //     payload: res.data.data,
    // }
};