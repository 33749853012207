import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import BtnOutlined, { BtnDefault, BtnText } from '../BtnComponent';
import { Stack, Autocomplete, IconButton, Tooltip } from '@mui/material';
import { Delete, Add } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { GetAllProducts } from '../../StateMgmt/Actions/productActions';

const Add_self_product = ({ setOpenPopup, productList, setProductList, onSubmit }) => {

    const [productOptions, setProductOptions] = useState([]);
    const [product, setProduct] = useState();
    const [price, setPrice] = useState();
    const [quantity, setQuantity] = useState();
    const [value, setValue] = useState({});

    useEffect(() => {
        GetAllProducts().then((data) => {
            console.log(data.payload.data)
            const list = (data.payload.data || []).reduce((prev, curr) => {
                prev.push(
                    {
                        id: curr.productId,
                        label: curr.subCategoryName
                    }
                )
                return prev
            }, [])
            setProductOptions(list)
        });
    }, [])

    const addProduct = () => {
        setProductList([...productList, { pId: product, quantity, value, type: 'Self', id: productList.length, price }]);
        setValue({});
        setProduct();
        setPrice();
        setQuantity();
    }

    const removeProduct = (id) => {
        const index = productList.map(e => e.id).indexOf(id);
        console.log(index)
        const filteredProducts = [...productList];
        filteredProducts.splice(index, 1);
        console.log(filteredProducts)
        setProductList(filteredProducts);
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
            {
                productList.filter(x => x.type === 'Self').map((product, index) => (
                    <div className='grid gap-3' key={index}>
                        <div className="grid gap-4 grid-cols-7 gap-x-3">
                            <div className='col-span-7'>
                                <CustomizeTextField
                                    disabled
                                    variant='outlined'
                                    value={product?.value?.label}
                                    className='w-full'
                                    label='Product'

                                />
                            </div>
                        </div>
                        <div className="grid gap-4 grid-cols-7 gap-x-3">
                            <div className='col-span-3'>
                                <CustomizeTextField
                                    disabled
                                    value={product?.quantity}
                                    className='w-full'
                                    variant='outlined'
                                    label='Quantity'
                                />
                            </div>
                            <div className='col-span-3'>
                                <CustomizeTextField
                                    disabled
                                    variant='outlined'
                                    value={product?.price}
                                    className='w-full'
                                    label='Product Price'

                                />
                            </div>
                            <div className='ml-2'>
                                <Tooltip title='Remove Inputs' placement="right">
                                    <IconButton
                                        sx={{
                                            width: 55,
                                            height: 55,
                                            color: '#153b8c',
                                            '&:hover': { bgcolor: '#153b8c', color: grey[50] }
                                        }}
                                        onClick={() => removeProduct(product?.id)}
                                    >
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>


                    </div>
                ))
            }
            {

                <div className='grid gap-3'>
                    <div className="grid gap-4 grid-cols-7 gap-x-3">
                        <div className='col-span-7'>
                            <Autocomplete
                                options={productOptions}
                                onChange={(e, selectedObject) => {
                                    if (selectedObject !== null) {
                                        setValue(selectedObject);
                                        setProduct(selectedObject.id);
                                    }
                                }}
                                value={value?.label || ""}
                                renderInput={(params) => (
                                    <CustomizeTextField
                                        {...params}
                                        label='Select Product'
                                        name='pId'
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="grid gap-4 grid-cols-7 gap-x-3">
                        <div className='col-span-3'>
                            <CustomizeTextField
                                type="number"
                                className='w-full'
                                value={quantity || ""}
                                placeholder='Please enter product quantity'
                                variant='outlined'
                                label='Product Quantity'
                                name='quantity'
                                onChange={e => setQuantity(e.target.value)}
                            />
                        </div>
                        <div className='col-span-3'>
                            <CustomizeTextField
                                type="number"
                                className='w-full'
                                value={price || ""}
                                placeholder='Please enter product price'
                                variant='outlined'
                                label='Product Price'
                                name='price'
                                onChange={e => setPrice(e.target.value)}
                            />
                        </div>
                        <div className='ml-2' >
                            <BtnText
                                disabled={quantity && product ? false : true}
                                variant="text"
                                size="medium"
                                text="Add"
                                type="submit"
                                startIcon={<Add />}
                                onClick={addProduct}
                                color='#153b8c'

                            />
                        </div>
                    </div>


                </div>

            }
            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <BtnDefault
                    variant="contained"
                    color="primary"
                    size="medium"
                    text="Save"
                    type="submit"
                />
                <BtnOutlined
                    variant="outlined"
                    color="error"
                    size="medium"
                    text="Cancel"
                    onClick={() => setOpenPopup(false)}
                />
            </div>

        </form>
    )
}

export default Add_self_product