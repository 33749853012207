import React from 'react'

const Footer = () => {
    return (
        <div className='flex flex-col'>
            <div className=' mb-0 bg-gray-800 h-15 flex items-center  lg:px-4 justify-center'>
                <div className='flex p-2 pb-6 text-white'>Indoquanta Technology private limited © 2024. Sahayaks is a product by Indoquanta Technology private limited </div>

            </div>

        </div>
    )
}

export default Footer