import React from 'react';
import { MdAssignmentLate, MdOutlineNature, MdReportProblem, MdPieChart } from "react-icons/md"


const PartPendingInputs = ({ partPending }) => {
    const { partId, partName, partQty, partWarranty } = partPending
    return (
        <div className="max-w-md mx-auto bg-white rounded-xl shadow-sm overflow-hidden md:max-w-2xl">
            <div className='w-full grid md:grid-cols-2 grid-cols-1 gap-4'>
                <div className='flex flex-col '>
                    <div className='flex flex-row  md:gap-4'>
                        <div className="flex items-center gap-3 m-2">
                            <div className='text-themeBlue-1'><MdAssignmentLate /></div>
                            <div className='text-zinc-700 font-semibold'>Part Name</div>
                        </div>
                        <div className='flex items-center m-2 col-span-2'>
                            <div className='text-gray-600'>
                                {partName}
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row md:gap-4'>
                        <div className="flex col-span-2 items-center gap-3 m-2 text-gray-600 ">
                            <div className='text-themeBlue-1'><MdOutlineNature /></div>
                            <div className='text-zinc-700 font-semibold'>Part Quantity</div>
                        </div>
                        <div className='flex items-center m-2 '>
                            <div className='text-gray-600'>
                                {partQty}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col'>
                    <div className='flex flex-row md:gap-4'>
                        <div className="flex col-span-2 items-center gap-3 m-2 text-gray-600 ">
                            <div className='text-themeBlue-1'> <MdPieChart /></div>
                            <div className='text-zinc-700 font-semibold'>Part ID</div>
                        </div>
                        <div className='flex items-center m-2'>
                            <div className='text-gray-600'>
                                {partId}
                            </div>
                        </div>
                    </div>
                    <div className='flex md:flex-row  md:gap-4'>
                        <div className="flex col-span-2 items-center gap-3 m-2 text-gray-600 ">
                            <div className='text-themeBlue-1'> <MdReportProblem /></div>
                            <div className='text-zinc-700 font-semibold'>Part Warranty</div>
                        </div>
                        <div className='flex col-span-2 items-center md:m-2'>
                            <div className='text-gray-600'>
                                {partWarranty}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PartPendingInputs