import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { TextField } from '@mui/material';
import api from '../../utils/axios';
import { successResponse } from '../../utils/utils';


const CreatePIN = () => {

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const location = useLocation();
    let pin_token = location?.state?.pinToken || '';
    let user_id = location?.state?.user_id || '';
  

    const navigate = useNavigate();

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            onSubmit();
        }
    }
    const onSubmit = (data) => {

        api.post('/api/v1/auth/user/setPin', { userId: user_id, pin: data.pin, pinToken: pin_token })
            .then(response => {
                if (response.data.data.accountId) {
                    successResponse('Pin successfully created. Login to continue!')
                    navigate('/pinlogin', {
                        state: {
                            user_id: response.data.data.userId,
                            phone: response.data.data.phoneNo
                        }
                    });
                }
            });
    }

    return (
        <div className='grid h-screen w-full'>
            <div className='flex flex-col justify-center'>
                <form onSubmit={handleSubmit(onSubmit)} className='max-w-[400px] w-full mx-auto bg-gray-50 shadow-xl shadow-slate-400 p-8 px-8 rounded-lg'>
                    <h2 className='text-3xl text-slate-800 font-bold text-center pb-5'>SET PIN</h2>
                    <h4 className='text-slate-500 font-bold text-center'>Create a 6 digit pin of your choice</h4>
                    <div className='flex flex-col text-gray-600 py-2'>
                        <label>PIN</label>
                        <TextField
                            className='rounded-lg bg-gray-200 border mt-2 p-2 focus:border-blue-300 focus:bg-blue-50 focus:outline-none'
                            type='text'
                            placeholder='Enter 6 digit PIN'
                            // onChange={(e) => setPhone(e.target.value)}
                            onKeyDown={handleKeyDown}
                            name='pin'
                            {...register("pin", { required: 'Please enter PIN.' })}
                            error={Boolean(errors.pin)}
                            helperText={errors.pin?.message}
                        />
                    </div>

                    <div className='flex flex-col text-gray-600 py-2'>
                        <label>Confirm PIN</label>
                        <TextField
                            className='rounded-lg bg-gray-200 border mt-2 p-2 focus:border-blue-300 focus:bg-blue-50 focus:outline-none'
                            type='text'
                            placeholder='Enter 6 digit PIN'
                            // onChange={(e) => setPhone(e.target.value)}
                            onKeyDown={handleKeyDown}
                            name='repin'
                            {...register("repin", { required: 'Please re-enter PIN.' })}
                            error={Boolean(errors.repin)}
                            helperText={errors.repin?.message}
                        />
                    </div>
                    <button
                        className='w-full my-5 py-2 bg-themeBlue-1	 shadow-lg shadow-blue-500/50 hover:shadow-blue-500/30 text-white font-semibold rounded-lg'
                        type="submit"
                    >
                        Create PIN
                    </button>
                </form>
            </div>
        </div>
    )
}


export default CreatePIN;
