import api from "../utils/axios";

const getCustomerAddress = async (addressId) => {
    const response = await api.get(`/api/v1/address/getDetail?addressId=${addressId}`);
    const { pinCode, addressLine, city, country, state } = response.data.data;
    const customerAddress = `${addressLine}, \n ${city}, \n ${pinCode} ${state}, \n ${country}`;
    return customerAddress;
};

export default getCustomerAddress

