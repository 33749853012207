import api from "../utils/axios";


const GetProductFormOptions = async () => {
    const res = await api.post('/api/v1/filter/product/getOptionsList');
    
    const category = res.data.data.categoryOpts.map(obj => {
        obj.label = obj.categoryName;
        return obj;
    });
   
    const range = res.data.data.rangeOpts.map(obj => {
        obj.label = obj.rangeName;
        return obj;
    })

    const brand = res.data.data.brandOpts.map(obj => {
        obj.label = obj.brandName;
        return obj;
    })

    const service = res.data.data.serviceOpts
    const productOptions = {
        category,
        range,
        brand,
        service
    };
    return productOptions;
}

export default GetProductFormOptions;