import api from "../utils/axios";

const getPinValue = async (searchTerm, setOptions) => {

    const filterValue = searchTerm ? { filterValue: searchTerm } : {};

    const response =  await api.post('/api/v1/filter/pinCode/getList', filterValue);
    const updatedOptions = response.data.data.rows.map((p) => {
      return {
        label: p.pinCode + ', ' + p.postOffice,
        value: p
      };
    });
    setOptions(updatedOptions);
    return updatedOptions;
  };

  export default getPinValue