import { styled, Box, Typography } from '@mui/material';
import { Colors } from './TechnicianTheme';

export const TableContainer = styled(Box)(({ matches, theme }) => ({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    padding: "0px 0px",
    background: Colors.light_gray,
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center",
    },
}))

export const TableContent = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // maxWidth: 420,
    padding: "30px",
}));

export const TableTitle = styled(Typography)(({ matches, theme }) => ({
    lineHeight: 1.5,
    fontSize: "20px",
    marginBottom: "20px",
    [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
    }
}));