
const initialState = [];

const customerReducers = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ALL_CUSTOMERS":
            const customers = action.payload
            return [...state, customers];

        case "ADD_NEW_CUSTOMER":
            const new_member = action.payload
            return [...state, new_member];

        case "EDIT_CUSTOMER":
            const edited_member = action.payload
            return [...state, edited_member];

        case "ADD_CUSTOMER_ADDRESS":
            const added_address = action.payload

        default: return state;
    }
}

export default customerReducers;