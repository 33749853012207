import React, { useMemo, useState, useEffect } from 'react';
import { HiOutlineUserGroup } from "react-icons/hi";
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SectionHeader from '../Components/Headers/SectionHeader';
import ComplaintActions from '../Components/ComplaintActions';
import ModalOpener from '../Components/ModalOpener';
import complaints from '../lib/complaints';
import gridStyle from '../lib/Styles/grid_style';
import { getCustomerCalls } from '../StateMgmt/Actions/reportActions';


const CustomerComplaints = () => {

    const [complaintList, setComplaintList] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [rowId, setRowId] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [action, setAction] = useState('');
    const [status, setStatus] = useState('');
    const [rowData, setRowData] = useState({});
    const [filter, setFilter] = useState([]);

    useEffect(() => {
        getCustomerCalls().then(({ payload }) => {
            setComplaintList(payload)
        });
    }, [])

    const columns = useMemo(() => [
        {
            field: 'callId',
            headerName: 'Call ID',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'createdDate',
            headerName: 'Created On',
            width: 180,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'customerName',
            headerName: 'Customer Name',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'product',
            headerName: 'Product',
            width: 300,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'serviceType',
            headerName: 'Type of Service',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 180,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'createdBy',
            headerName: 'Created By',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'actions',
            headerName: 'Action',
            width: 140,
            renderCell: params => <ComplaintActions {...{ params, setOpenPopup, setAction, setStatus }} />,
            headerAlign: 'center',
            headerClassName: 'table--header',
            align: 'center'
        },
    ], [rowId])
    // rowId is passed as a dependency in useMemo since we've to render the btn if there is a change in the row

    return (

        <>
            <SectionHeader icon={<HiOutlineUserGroup />} headingText='Calls / Complaints' setFilter={setFilter} data={complaintList} searchLabel='Search Complaint By Status' column='status' addBtnFlag={false} searchFlag={true} />


            <Box
                sx={gridStyle}>
                <DataGrid
                    columns={columns}
                    rows={complaintList}
                    getRowId={(row) => row.id}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowSpacing={params => ({
                        top: params.isFirstVisible ? 0 : 5,
                        bottom: params.isLastVisible ? 0 : 5
                    })}
                    onCellEditCommit={params => setRowId(params.id)}
                    onRowClick={params => { setRowId(params.id); setRowData(params.row) }}
                    filterModel={{
                        items: filter
                    }}
                />
            </Box>
            {action &&
                <ModalOpener
                    action={action}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    data={rowData}
                    id={rowId}
                    status={status}
                />

            }

        </>
    )
}

export default CustomerComplaints;

