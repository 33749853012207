
const initialState = [];

const teamReducers = (state = initialState, action) => {
    switch (action.type) {
        case "GET_TEAM_MEMBERS":
            const team_members = action.payload
            return [...state, team_members];

        case "ADD_TEAM_MEMBER":
            const new_member = action.payload
            return [...state, new_member];

        case "EDIT_TEAM_MEMBER":
            const edited_member = action.payload
            return [...state, edited_member];

        default: return state;
    }
}

export default teamReducers;