import React, { useMemo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HiOutlineUserGroup } from "react-icons/hi";
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SectionHeader from '../Components/Headers/SectionHeader';
import DetailCard from '../Components/DetailCard';
import RadioGrp from '../Components/RadioGrp';
import gridStyle from '../lib/Styles/grid_style';
import { setUserAddressId } from '../CommonFunctions/setAddressId';
import CustomerProductDetails from '../Components/CustomerProductDetails';
import { getCustomerDetail } from '../StateMgmt/Actions/customerActions';
import ModalOpener from '../Components/ModalOpener'


const CustomerDetails = () => {
    let { state } = useLocation();

    const [pageSize, setPageSize] = useState(10);
    const [rowId, setRowId] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [action, setAction] = useState('');
    const [stateData, setStateData] = useState(state);
    const [rowData, setRowData] = useState({});
    const [filter, setFilter] = useState([]);
    const [softRelaod, setSoftReaload] = useState(new Date());
    const [selectedProduct, setSelectedProduct] = useState({});
    const [ProductInvoice, setProductInvoice] = useState('');
    const [address, setAddress] = useState(state?.addresses);
    useEffect(() => {
        getCustomerDetail(state.id).then(({ payload }) => {
            setStateData(payload);
            setUserAddressId(payload?.addresses[0]?.id);
            setAddress(payload.addresses);
        });
    }, [softRelaod]);
    const columns = useMemo(() => [
        {
            field: 'callId',
            headerName: 'Call ID',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'createdBy',
            headerName: 'Created By',
            width: 250,
            headerAlign: 'center',
            align: 'center'
        },

        {
            field: 'createdDate',
            headerName: 'Date',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'product',
            headerName: 'Product',
            width: 400,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'serviceType',
            headerName: 'Service Type',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'status',
            headerName: 'Call Status',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
    ], [rowId])
    // rowId is passed as a dependency in useMemo since we've to render the btn if there is a change in the row

    return (

        <>
            <SectionHeader icon={<HiOutlineUserGroup />} headingText='Customer Record' setFilter={setFilter} flag='customer_record' searchFlag={false} addBtnFlag={false} />
            <DetailCard cardData={stateData} selectedProduct={selectedProduct} setProductInvoice={setProductInvoice}/>
            <RadioGrp labelText='Service Address' options={address} customerId={stateData?.id} onChange={(index) => console.log(index)} setSoftReaload={setSoftReaload} />
            <div className="grid grid-cols-3 pt-3">
                <div class="col-span-1">
                    <CustomerProductDetails ProductData={stateData?.products.filter(x=> x.productSubCategory)} setSelectedProduct={setSelectedProduct} setOpenPopup={setOpenPopup} setAction={setAction} setProductInvoice={setProductInvoice}/>
                </div>
                <div class="col-span-2">
                    <Box
                        sx={{ ...gridStyle, height: '55vh' }}>
                        <DataGrid
                            columns={columns}
                            rows={stateData?.calls || []}
                            getRowId={(row) => row.id}
                            rowsPerPageOptions={[5, 10, 15, 20]}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            getRowSpacing={params => ({
                                top: params.isFirstVisible ? 0 : 5,
                                bottom: params.isLastVisible ? 0 : 5
                            })}
                            onCellEditCommit={params => setRowId(params.id)}
                            onRowClick={params => { setRowId(params.id); setRowData(params.row) }}
                            filterModel={{
                                items: filter
                            }}
                        />
                    </Box>
                </div>

            </div>

            {action &&
                <ModalOpener
                    action={action}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    data={rowData}
                    id={rowId}
                    ProductInvoice={ProductInvoice}
                />
            }
        </>
    )
}

export default CustomerDetails;

