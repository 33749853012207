import React, { useState, useEffect } from 'react';



import { getTechnicianCallList, getCompletedCallList } from '../../../../StateMgmt/Actions/technicianActions';
import { RiFoldersFill } from "react-icons/ri";
import ComplaintCards from './ComplaintCards';

const Complaints = () => {
    // const [readMore, setReadMore] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [openPopupTwo, setOpenPopupTwo] = useState(false);

    const [openTab, setOpenTab] = useState(1);
    const [complaintsData, setComplaintsData] = useState([]);
    const [completedComplaintsData, setCompletedComplaintsData] = useState([]);

    useEffect(() => {
        getTechnicianCallList().then(({ payload }) => {
            setComplaintsData(payload);
        });
        getCompletedCallList().then(({ payload }) => {
            setCompletedComplaintsData(payload);
        });
    }, [openPopup])

    // const isSmallScreen = window.innerWidth < 640;
    // const linkName = readMore ? 'Read Less << ' : 'Read More >> '
    return (
        <div className='flex flex-col mb-20'>
            <div className="flex flex-wrap px-0 py-4" >
                <div className="w-full">
                    <ul className="flex mb-0 list-none pb-4 flex-row " role="tablist">
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                            <a className={"text-xs font-bold px-5 pr-2 py-3 shadow-md block hover:no-underline " + (openTab === 1 ? "bg-[#153B8C]" : "bg-white")}
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(1);
                                }}
                                data-toggle="tab"
                                href="#link1"
                                role="tablist"
                            >
                                <div className="flex">
                                    <div className={'hidden lg:flex md:flex rounded-full h-12 w-12  items-center justify-center' + (openTab === 1 ? " bg-white" : " bg-[#153B8C]")}>
                                        <RiFoldersFill className={'text-2xl' + (openTab === 1 ? " text-[#153B8C]" : " text-white")} />
                                    </div>
                                    <div className='pl-4'>
                                        <span className={'lg:text-lg md:text-lg text-sm uppercase font-normal' + (openTab === 1 ? " text-white" : " text-[#153B8C]")}>
                                            Assigned Complaints
                                        </span>
                                        <div className='flex items-center'>
                                            <strong className={'text-xs md:text-sm font-thin' + (openTab === 1 ? " text-white" : " text-gray-700")}>
                                                Number of Complaints :
                                            </strong>
                                            <span className={'text-sm pl-2' + (openTab === 1 ? " text-white" : " text-[#153B8C]")}>{complaintsData.length}</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                            <a className={"text-xs font-bold px-5 pr-2 py-3 shadow-md block hover:no-underline " + (openTab === 2 ? "bg-[#153B8C]" : "bg-white")}
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(2);
                                }}
                                data-toggle="tab"
                                href="#link1"
                                role="tablist"
                            >
                                <div className="flex">
                                    <div className={'hidden lg:flex md:flex rounded-full h-12 w-12 items-center justify-center' + (openTab === 2 ? " bg-white" : " bg-[#153B8C]")}>
                                        <RiFoldersFill className={'text-2xl' + (openTab === 2 ? " text-[#153B8C]" : " text-white")} />
                                    </div>
                                    <div className='pl-4'>
                                        <span className={'lg:text-lg md:text-lg text-sm uppercase font-normal ' + (openTab === 2 ? " text-white" : " text-[#153B8C]")}>
                                            Closed/Complted
                                        </span>
                                        <div className='flex items-center'>
                                            <strong className={'text-xs md:text-sm font-thin' + (openTab === 2 ? " text-white" : " text-gray-700")}>
                                                Number of Complaints :
                                            </strong>
                                            <span className={'text-sm pl-2' + (openTab === 2 ? " text-white" : " text-[#153B8C]")}>{completedComplaintsData.length}</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>


                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full h-full mb-6 shadow-md  ">
                        <div className="px-4 py-5 flex-auto">
                            <div className="tab-content tab-space h-100">
                                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                                    <ComplaintCards
                                        setOpenPopup={setOpenPopup}
                                        openPopup={openPopup}
                                        complaintsData={complaintsData}
                                    />
                                </div>
                                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                                    <ComplaintCards
                                        setOpenPopup={setOpenPopupTwo}
                                        openPopup={openPopupTwo}
                                        complaintsData={completedComplaintsData}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Complaints



// <Container>
//             <Grid container justifyContent={"center"} sx={{ margin: '20px 4px 10px 4px' }}>
//                 {renderComplaints}
//             </Grid>
//         </Container>


// const renderComplaints = complaints.map(complaint => (
//     <Grid item key={complaint.id} display='flex' flexDirection={'column'} alignItems="center">
//         <SingleComplaint complaint={complaint} matches={matches} />
//     </Grid>
// ));