const gridStyle = {

    backgroundColor: '#fff',
    height: '90vh',
    padding: 2,
    width: '100%',
    '& .MuiDataGrid-root': {
        borderColor: '#153b8c',
        '& .MuiDataGrid-row': {
            borderColor: '#aed1f6',
            '&:hover': {
                bgcolor: '#ebf4fd',
            },
            '&.Mui-selected': {
                bgcolor: '#c2ddf8',
                '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: '#aed1f6',
                    borderColor: '#aed1f6',
                },
            }

        }
    },
    '& .MuiDataGrid-columnHeaders': {
        borderColor: '#153b8c',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        color: '#153b8c',
        fontSize: 16,
        fontWeight: 500,
        fontFamily: "sans-serif",
    },
}

export default gridStyle;