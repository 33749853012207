import React from 'react';
import { Dialog, DialogContent, DialogTitle, Box, Typography } from '@mui/material';
import RenderPdf from '../../Pages/RenderPdf';
import { FaFileInvoiceDollar } from 'react-icons/fa';
// https://sahayakv2.s3.ap-south-1.amazonaws.com/test_Product/Product-4-4-1db911d1-f555-4785-ba25-ff70a2f3d66d.jpeg
const design = {
    '& .MuiDialogContent-root': {
        justifyContent: 'center',
        alignItems: 'center',
    },
}

const InvoiceModal = (props) => {
    const { openInvoice, setOpenInvoice, id, ProductInvoice } = props;
    console.log(ProductInvoice)
    const closeDialog = () => setOpenInvoice(false);
    return (
        <Dialog open={openInvoice} onClose={closeDialog} fullWidth maxWidth="xl" sx={design}>
            <div className={`grid grid-cols-3`}>
                <div className='col-span-3'>
                    <div className='flex flex-col mt-2 py-4 text-themeBlue-2 bg-themeBlue-4 w-full'>
                        <div className='flex space-x-2 justify-center w-full'>
                            <FaFileInvoiceDollar />
                            <Typography className='text-sm font-extrabold tracking-wide'>
                                Invoice
                            </Typography>
                        </div>
                        {id && <div className='text-[#b67497] text-xs font-bold tracking-wide'>{id}</div>}
                    </div>
                    <DialogContent dividers className='justify-center space-y-2'>
                        <RenderPdf pdfLink={ProductInvoice} />
                    </DialogContent>
                </div>
            </div>
        </Dialog>
    )
}

export default InvoiceModal