import React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Box, Typography } from '@mui/material';
import BtnOutlined, { BtnDefault } from '../BtnComponent';

const QRModal = ({ openPopup, setOpenPopup, data, icon, header, id }) => {


    const handleDownloadClick = () => {
        setOpenPopup(false);
    }
    const closeDialog = () => setOpenPopup(false);

    return (
        <Dialog open={openPopup} onClose={closeDialog} width='800px' sx={design}>
            <div className='w-max'>
                <DialogTitle className='flex justify-center space-x-2 text-themeBlue-2 bg-themeBlue-4'>
                    {icon}
                    <Typography className='text-sm font-extrabold tracking-wide'>
                        {header}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers className='flex flex-col justify-center space-y-3'>
                    <Typography variant="inherit" style={{ fontWeight: 700 }}>{id}</Typography>
                </DialogContent>
                <DialogContent dividers className='flex justify-center space-y-3'>
                    <img src={data} />
                </DialogContent>
                <DialogActions className='flex space-x-3'>

                    <a href={data} download='qrcode.png'>
                        <BtnDefault
                            variant="contained"
                            color="primary"
                            size="medium"
                            text="Download"
                            onClick={handleDownloadClick}

                        />
                    </a>
                    <BtnOutlined
                        variant="outlined"
                        color="error"
                        size="medium"
                        text="Close"
                        onClick={() => setOpenPopup(false)}
                    />
                </DialogActions>
            </div>
        </Dialog>
    )
}

export default QRModal

const design = {
    '& .MuiDialogContent-root': {
        justifyContent: 'center',
        alignItems: 'center'
    },
    '& .MuiDialogActions-root': {
        justifyContent: 'center',
        alignItems: 'center'
    }
}