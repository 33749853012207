import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BtnOutlined from '../BtnComponent';
import SearchBar from '../SearchBar';
import ProductSearchBar from '../ProductSearchbar';
import downloadGenericQR from '../../CommonFunctions/downloadGenericQR';
import SearchInput from '../SearchInput';
import { IconButton, Tooltip } from '@mui/material';
import Badge from '@mui/material/Badge';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import GetAppIcon from '@mui/icons-material/GetApp';
import { exportComplaintData } from '../../StateMgmt/Actions/complaintActions';

const SectionHeader = ({
  icon,
  headingText,
  btnText,
  setFilter,
  data,
  searchFlag,
  addBtnFlag,
  filterFlag,
  exportFlag,
  searchLabel,
  column,
  setOpenPopup,
  setAction,
  productSearch,
  genericQR,
  setProductFilter,
  action,
  filter,
  origin,
  statusFilter,
  complaintFilter,
  addSetting,
  settingIcon,
  categoryText,
  productBrand,
  brandText
}) => {
  if (productSearch) searchLabel = 'Product Search';
  // useEffect(() => {
  const navigate = useNavigate();
  const endDatetemp = localStorage.getItem('endingDate');
  const endDate = endDatetemp === 'null' ? null : endDatetemp;
  const startDatetemp = localStorage.getItem('startingDate');
  const startDate = startDatetemp === 'null' ? null : startDatetemp;
  const tempstatus = localStorage.getItem('selectedStatus');
  const temp = tempstatus ? JSON.parse(tempstatus) : [];
  const tempTech = localStorage.getItem('selectedTechinican');
  const tech = tempstatus ? JSON.parse(tempTech) : [];
  // }, [])
  const count = (endDate ? 1 : 0) + (startDate ? 1 : 0) + (temp.length ? 1 : 0) + (tech?.length ? 1 : 0)
  const [filterCount, setFilterCount] = useState(count);
  useEffect(() => {
    setFilterCount(count);
  }, [count])


  return (
    <div className='flex flex-col px-2'>
      <div className='mt-7 bg-themeBlue-1 h-17 flex items-center justify-between p-2 lg:px-4'>
        <div className='flex gap-4 py-2'>
          <span className='text-2xl text-gray-100'>{icon}</span>
          <h2 className='text-white text-xl font-semibold'>{headingText}</h2>
        </div>
        <div className='flex gap-x-4'>
          {addBtnFlag && <BtnOutlined
            variant="outlined"
            startIcon={icon}
            color="error"
            size="large"
            border='#fff'
            text={btnText}
            onClick={() => {
              if (genericQR) {
                downloadGenericQR();
                return;
              }
              setOpenPopup(true);
              setAction(action)
            }}
          />}
          {/* {searchFlag && <div className="">
            <SearchBar data={data} label={searchLabel} setFilter={setFilter} column={column} />
          </div>} */}
          {filterFlag && <div className="">
            <Tooltip title={`Filter By Status`}>
              <IconButton
                sx={{
                  width: 50,
                  height: 50,
                  color: '#fff',
                  '&:hover': { bgcolor: '#fff', color: '#153B8C' }
                }}
                onClick={() => {
                  setAction('StatusFilter');
                  setOpenPopup(true)
                }}>
                <Badge badgeContent={filterCount} color="secondary">
                  <FilterAltIcon fontSize='large' />
                </Badge>

              </IconButton>
            </Tooltip>
          </div>}
          {exportFlag && <div className="">
            <Tooltip title={`Export Complaint Data`}>
              <IconButton
                sx={{
                  width: 50,
                  height: 50,
                  color: '#fff',
                  '&:hover': { bgcolor: '#fff', color: '#153B8C' }
                }}
                onClick={() => {
                  exportComplaintData(complaintFilter, statusFilter);
                }}>
                <GetAppIcon fontSize='large' />
              </IconButton>
            </Tooltip>
          </div>}

          {searchFlag && <div className="">
            <SearchInput label={searchLabel} filter={filter} />
          </div>}
          {productSearch && <div className="">
            <ProductSearchBar label={searchLabel} setProductFilter={setProductFilter} />
          </div>}
          {addSetting && <BtnOutlined
            variant="outlined"
            startIcon={settingIcon}
            color="error"
            size="large"
            border='#fff'
            text={categoryText}
            onClick={() => {
              navigate(`/categories`)
            }}
          />}
          {productBrand && <BtnOutlined
            variant="outlined"
            startIcon={settingIcon}
            color="error"
            size="large"
            border='#fff'
            text={brandText}
            onClick={() => {
              navigate(`/brands`)
            }}
          />}

        </div>

      </div>

    </div>
  )
}

export default SectionHeader