import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { TextField } from '@mui/material';
import api from '../../utils/axios';
import { successResponse } from '../../utils/utils';



const OTPLogin = () => {

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();
    const location = useLocation();
    let phone = location?.state?.phone || '';
    let id = location?.state?.user_id || '';

    const navigate = useNavigate();

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            onSubmit();
        }
    }
    const onSubmit = (data) => {
        api.post('/api/v1/auth/user/validateOtp', { userId: id, otp: data.otp })
            .then(response => {
                if (response.data.data.pinToken) {
                    successResponse('Otp validation success!')
                    navigate('/createpin', {
                        state: {
                            pinToken: response.data.data.pinToken,
                            user_id: response.data.data.userId,
                        }
                    });
                }
            });
    }

    return (
        <div className='grid h-screen w-full'>
            <div className='flex flex-col justify-center pt-64'>
                <form onSubmit={handleSubmit(onSubmit)} className='max-w-[400px] w-full mx-auto bg-gray-100 shadow-lg shadow-slate-400 p-8 px-8 rounded-lg'>
                    <h2 className='text-3xl text-slate-800 font-bold text-center pb-5'>SIGN IN</h2>
                    <div className='flex flex-col text-gray-600 py-2'>
                        <label>Phone Number</label>
                        <TextField
                            className='rounded-lg bg-gray-200 border mt-2 p-2 focus:border-blue-300 focus:bg-blue-50 focus:outline-none font-semibold tracking-wider text-zinc-400	'
                            type='text'
                            placeholder='Enter Your Mobile Number'
                            value={phone}
                            disabled
                        />
                    </div>
                    <button
                        className='w-full my-5 py-2 bg-themeBlue-1	 shadow-lg shadow-blue-500/50 hover:shadow-blue-500/30 text-white font-semibold rounded-lg'
                        onClick={() => { navigate(-1) }}
                    >
                        Go Back
                    </button>
                    <div className='flex flex-col text-gray-600 py-2'>
                        <label>OTP</label>
                        <TextField
                            className='rounded-lg bg-gray-200 border mt-2 p-2 focus:border-blue-300 focus:bg-blue-50 focus:outline-none'
                            type='text'
                            placeholder='Enter OTP received'
                            // onChange={(e) => setPhone(e.target.value)}
                            onKeyDown={handleKeyDown}
                            name='otp'
                            {...register("otp", { required: 'Please enter OTP.' })}
                            error={Boolean(errors.otp)}
                            helperText={errors.otp?.message}
                        />
                    </div>
                    <button
                        className='w-full my-5 py-2 bg-themeBlue-1	 shadow-lg shadow-blue-500/50 hover:shadow-blue-500/30 text-white font-semibold rounded-lg'
                        type="submit"
                    >
                        Submit OTP
                    </button>
                </form>
            </div>
        </div>
    )
}


export default OTPLogin;
