import React from 'react';
import { TextField } from "@mui/material";
import { Controller, useFormContext } from 'react-hook-form';

const Payment_details = () => {
    const
        { control,
            formState: { errors }
        } = useFormContext();

    return (
        <div className=''>
            <div className='justify-center w-full'>
                <form className='mx-auto bg-gray-50 shadow-xl shadow-slate-400 p-8 px-8 rounded-lg'>
                    <h2 className='text-xl text-slate-800 font-bold text-center pb-5'>PAYMENT DETAILS</h2>
                    <div className='flex-col space-y-4 text-gray-600 px-16 py-2'>
                        <Controller
                            control={control}
                            name='upi'
                            rules={{ required: 'UPI Address is required' }}
                            render={({ field }) => (
                                <TextField
                                    className='w-full'
                                    placeholder='Please enter UPI address'
                                    variant='outlined'
                                    label='UPI Address'
                                    {...field}
                                    error={Boolean(errors.upi)}
                                    helperText={errors.upi?.message}
                                />
                            )} />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Payment_details




























{/* <span className=''>
                            <Typography className='' variant='caption'>BANK DETAILS</Typography>
                        </span>
                        <div className="flex flex-wrap gap-8">
                            <div className='flex flex-col gap-4'>
                                <TextField
                                    className='w-80'
                                    placeholder='Please enter a/c holder name'
                                    variant='outlined'
                                    label='Enterprise Account'
                                    name='entAcc'
                                />
                                <TextField
                                    className='w-80'
                                    placeholder='Please enter bank name'
                                    variant='outlined'
                                    label='Bank'
                                    name='bank'
                                />
                            </div>
                            <div className='flex flex-col gap-4'>

                                <TextField
                                    className='w-80'
                                    placeholder='Please enter a/c number'
                                    variant='outlined'
                                    label='Account Number'
                                    name='accNumber'
                                />
                                <TextField
                                    className='w-80'
                                    placeholder='Please enter bank IFSC'
                                    variant='outlined'
                                    label='IFSC'
                                    name='ifsc'
                                />
                            </div>
                        </div> */}