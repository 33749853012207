import React, { useEffect, useState } from 'react';
import { Chart } from "react-google-charts";
import { getMonthlyCallStatus } from '../../StateMgmt/Actions/StatChartActions';


const CallStatusPie = () => {
    const [callStatusData, setCallStatusData] = useState([]);

    useEffect(() => {
        getMonthlyCallStatus().then(({ payload }) => {
            setCallStatusData(payload);
        });
    }, []);

    const options = {
        title: "Call Status",
    };
    return (
        <Chart
            chartType="PieChart"
            data={callStatusData}
            options={options}
            width="100%"
            height="400px"
            // spreadSheetUrl="https://docs.google.com/spreadsheets/d/1jN0iw0usssnsG1_oi-NXtuKfsUsGme09GsFidbqxFYA"
            // toolbarItems={[
            //     {
            //         type: "csv",
            //         datasource:
            //             "https://spreadsheets.google.com/tq?key=1jN0iw0usssnsG1_oi-NXtuKfsUsGme09GsFidbqxFYA",
            //     },
            // ]}
        />
    )
}

export default CallStatusPie

// const data = [
//     ["Call Status", "Counts"],
//     ["Parts Pending", 11],
//     ["Parts Replacement", 2],
//     ["Completed", 2],
//     ["Assigned", 2],
//     ["Unassigned", 7],
// ];