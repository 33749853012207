import React from 'react';
import { BsAsterisk } from "react-icons/bs";
import { FaUserCog } from "react-icons/fa";
import { BiRupee } from "react-icons/bi";


const TechnicianDetails = ({ technician = {} }) => {
    const { technicianId, charge, technicianName } = technician;
    return (
        <div className="max-w-md mx-auto bg-white rounded-xl shadow-sm overflow-hidden md:max-w-2xl">
            <div className='w-full grid md:grid-cols-1 grid-cols-1 gap-4'>
                <div className='flex flex-col '>
                    <div className='flex flex-row md:gap-4 items-center md:pl-8'>
                        <div className="flex col-span-2 items-center gap-3 m-2 text-gray-600 ">
                            <div className='text-themeBlue-1'><FaUserCog /></div>
                            <div className='text-zinc-700 font-semibold'>Techician Name</div>
                        </div>
                        <div className='flex items-center m-2 '>
                            <div className='text-gray-600'>
                                {technicianName}
                            </div>
                        </div>
                    </div>
                    <div className='flex md:flex-row md:gap-48 md:pl-8 flex-col'>
                        <div className='flex flex-row md:gap-4 items-center'>
                            <div className="flex col-span-2 items-center gap-3 m-2 text-gray-600 ">
                                <div className='text-themeBlue-1'><BsAsterisk /></div>
                                <div className='text-zinc-700 font-semibold'>Techician ID</div>
                            </div>
                            <div className='flex items-center m-2 '>
                                <div className='text-gray-600'>
                                    {technicianId}
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row md:gap-4 items-center'>
                            <div className="flex col-span-2 items-center gap-3 m-2 text-gray-600 ">
                                <div className='text-themeBlue-1'> <BiRupee /></div>
                                <div className='text-zinc-700 font-semibold'>Charges</div>
                            </div>
                            <div className='flex items-center m-2'>
                                <div className='text-gray-600'>
                                    {charge}
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default TechnicianDetails