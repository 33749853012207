import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { useForm } from "react-hook-form";
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import BtnOutlined, { BtnDefault } from '../BtnComponent';
import getPinValue from '../../CommonFunctions/getPinValue';
import { addCustomerAddress, addNewCustomer } from '../../StateMgmt/Actions/customerActions';

const Add_customer_address = ({ setOpenPopup, customerId, setSoftReaload }) => {

    const [options, setOptions] = useState([]);
    const [stateValue, setStateValue] = useState('');
    const [districtValue, setDistrictValue] = useState('');

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const onInputChange = (event, value, reason) => {
        if (value) {
            getPinValue(value, setOptions);
        } else {
            setOptions([]);
        }
    };

    const getValue = (value, field) => {
        setDistrictValue(value.value.district);
        setStateValue(value.value.state)
    }

    const onSubmit = async(data) => {
        const reqBody = data;
        reqBody.city = districtValue;
        reqBody.state = stateValue;
        await addCustomerAddress(data, customerId);
        setOpenPopup(false);
        setSoftReaload(new Date());
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <div className='flex flex-col gap-3'>
                    <Stack spacing={2} sx={{ color: '#851851' }}>
                        <Autocomplete
                            error={Boolean(errors.role)}
                            freeSolo
                            options={options}
                            onInputChange={onInputChange}
                            onChange={(event, value) => {
                                getValue(value)
                            }}

                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                                <CustomizeTextField
                                    {...params}
                                    label='PIN Code'
                                    name='pinCode'
                                    {...register("pinCode", { required: 'PIN is required.' })}
                                    error={Boolean(errors.pinCode)}
                                    helperText={errors.pinCode?.message}
                                />
                            )}

                        />
                    </Stack>
                    <CustomizeTextField
                        className='w-full'
                        variant='outlined'
                        value={districtValue}
                        label='City / District'
                        name='city'
                    />
                    <CustomizeTextField
                        className='w-full'
                        variant='outlined'
                        value={stateValue}
                        label='State'
                        name='state'
                    />
                </div>

                <CustomizeTextField
                    placeholder='Please enter customer address'
                    variant='outlined'
                    multiline
                    rows={7}
                    label='Customer Address'
                    name='addressLine'
                    {...register("addressLine", { required: 'Address is required.' })}
                    error={Boolean(errors.addressLine)}
                    helperText={errors.addressLine?.message}
                />

            </div>


            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <BtnDefault
                    variant="contained"
                    color="primary"
                    size="medium"
                    text="Add Address"
                    type="submit"
                />
                <BtnOutlined
                    variant="outlined"
                    color="error"
                    size="medium"
                    text="Cancel"
                    onClick={() => setOpenPopup(false)}
                />
            </div>

        </form>
    )
}

export default Add_customer_address