import React from 'react';
import { Typography } from '@mui/material';

const MemberDetails = ({ data }) => {
    return (
        <div>
            <div className='grid grid-cols-4 space-x-2'>
                <Typography variant="h6" className='grid justify-items-start'>Name </Typography>
                <Typography variant="h6" className='grid justify-items-end'> :</Typography>
                <div className='grid justify-items-center col-span-2 self-center text-md text-gray-600'>{data.name}</div>
            </div>
            <div className='grid grid-cols-4 space-x-2'>
                <Typography variant="h6" className='grid justify-items-start'>Email </Typography>
                <Typography variant="h6" className='grid justify-items-end'> :</Typography>
                <div className='grid justify-items-center col-span-2 self-center text-md text-gray-600'>{data.email}</div>
            </div>
            {/* <div className='grid grid-cols-4 space-x-2'>
                <Typography variant="h6" className='grid justify-items-start'>Team </Typography>
                <Typography variant="h6" className='grid justify-items-end'> :</Typography>
                <div className='grid justify-items-center col-span-2 self-center text-md text-gray-600'>{data.ashghalTeam.name}</div>
            </div> */}
            <div className='grid grid-cols-4 space-x-2'>
                <Typography variant="h6" className='grid justify-items-start'>Role </Typography>
                <Typography variant="h6" className='grid justify-items-end'> :</Typography>
                <div className='grid justify-items-center col-span-2 self-center text-md text-gray-600'>{data.role}</div>
            </div>
            <div className='grid grid-cols-4 space-x-2'>
                <Typography variant="h6" className='grid justify-items-start'>Status </Typography>
                <Typography variant="h6" className='grid justify-items-end'> :</Typography>
                <div className='grid justify-items-center col-span-2 self-center text-md text-gray-600'>{data.active}</div>
            </div>
            <div className='grid grid-cols-4 space-x-2'>
                <Typography variant="h6" className='grid justify-items-start'>Created On </Typography>
                <Typography variant="h6" className='grid justify-items-end'> :</Typography>
                {/* <div className='grid justify-items-center col-span-2 self-center text-md text-gray-600'>{data.createdOn}</div> */}
            </div>
            {/* <div className='grid grid-cols-4 space-x-2'>
                <Typography variant="h6" className='grid justify-items-start'>Mobile </Typography>
                <Typography variant="h6" className='grid justify-items-end'> :</Typography>
                <div className='grid justify-items-center col-span-2 self-center text-md text-gray-600'>7355256619</div>
            </div> */}
            
            {/* <div className='grid grid-cols-4 space-x-2'>
                <Typography variant="h6" className='grid justify-items-start'>Description </Typography>
                <Typography variant="h6" className='grid justify-items-end'> :</Typography>
                <div className='grid justify-items-center col-span-2 self-center text-md text-gray-600'>No Description</div>
            </div> */}
        </div>
    )
}

export default MemberDetails