import { styled, Typography, Box, List, IconButton } from '@mui/material';
import { Colors } from './TechnicianTheme';
import '@fontsource/montez';

export const AppBarContainer = styled(Box)(() => ({
    display: 'flex',
    marginTop: 4,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2px 8px'
}));

export const AppBarHeader = styled(Typography)(() => ({
    padding: "4px",
    flexGrow: 1,
    fontSize: "4em",
    fontFamily: '"Montez", "cursive"',                                            //npm install @fontsource/montez
    color: Colors.secondary,
}));

export const NavList = styled(List)(({ type }) => ({
    display: type === "row" ? "flex" : "block",
    flexGrow: 0,
    justifyContent: "center",
    alignItems: "center",
}));

export const ActionIconsContainerDesktop = styled(Box)(() => ({
    flexGrow: 0
}))

export const ActionIconsContainerMobile = styled(Box)(() => ({
    display: 'flex',
    background: Colors.shaft,
    position: "fixed",
    bottom: 0,
    left: 0,
    width: '100%',
    alignItems: 'center',
    zIndex: 99,
    borderTop: `1px solid ${Colors.border}`
}))

export const DrawerCloseButton = styled(IconButton)(() => ({
    position: 'absolute',
    top: 10,
    left: '250px',
    zIndex: 1999,
}))


