import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import { useForm } from "react-hook-form";
import Autocomplete from '@mui/material/Autocomplete';
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import BtnOutlined, { BtnDefault } from '../BtnComponent';
import getPinValue from '../../CommonFunctions/getPinValue';
import { uploadImage } from '../../CommonFunctions/GetStepperData';
import { editUserProfile } from '../../StateMgmt/Actions/userProfileActions';
import { getUser } from '../../utils/utils';

const EditProfile = ({ userData, setOpenPopup }) => {
    const { entDetail, paymentDetail, userDetail } = userData;
    const { accountName, description, logo } = entDetail;
    const { upiId } = paymentDetail;
    const { addressLine, city, firstName, lastName, pinCode, state, profilePic } = userDetail;
    const regName = `${firstName} ${lastName}`;
    const { accountId } = getUser();

    const [options, setOptions] = useState([]);
    const [stateValue, setStateValue] = useState(state);
    const [districtValue, setDistrictValue] = useState(city);
    const [logoReader, setLogoReader] = useState('');
    const [profilePicReader, setProfilePicReader] = useState('');
    const [imageValue, setImageValue] = useState(logo);
    const [profile_pic, setProfile_pic] = useState(profilePic);


    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const handleLogo = (e) => {
        const file = e.target.files[0];
        const logo_reader = new FileReader();
        logo_reader.readAsDataURL(file);
        setLogoReader(logo_reader)
        previewLogo(logo_reader);
    }
    const handleProfilePic = (e) => {
        const file = e.target.files[0];
        const dp_reader = new FileReader();
        dp_reader.readAsDataURL(file);
        setProfilePicReader(dp_reader)
        previewProfile(dp_reader);
    }

    const previewLogo = (logo_reader) => {
        logo_reader.onloadend = () => {
            setImageValue(logo_reader.result)
        }
    }

    const previewProfile = (dp_reader) => {
        dp_reader.onloadend = () => {
            setProfile_pic(dp_reader.result)
        }
    }

    const onInputChange = (event, value, reason) => {
        if (value) {
            getPinValue(value, setOptions);
        } else {
            setOptions([]);
        }
    };

    const getValue = (value, field) => {
        setDistrictValue(value.value.district);
        setStateValue(value.value.state)
    }
    const onSubmit = async (data) => {
        const [firstName, ...lastName] = data.regName.split(' ');
        data.firstName = firstName;
        data.lastName = lastName.join(' ');
        if (logoReader) {
            const { imageUrl } = await uploadImage(logoReader, 'Enterprise Logo');
            data.logoImage = imageUrl;
        } else {
            data.logoImage = imageValue;
        }
        if (profilePicReader) {
            const { imageUrl } = await uploadImage(profilePicReader, 'Profile Pic');
            data.profileImage = imageUrl;
        } else {
            data.profileImage = profile_pic;
        }
        const updatedData = await editUserProfile(data, accountId);
        const sessionData = getUser();
        const toUpdate = {
            profileImg: updatedData.payload.userDetail.profilePic,
            logo: updatedData.payload.entDetail.logo,
            name: [updatedData.payload.userDetail.firstName, updatedData.payload.userDetail.lastName].filter(Boolean).join(' ')
        }
        const temp = Object.assign(sessionData, toUpdate);
        localStorage.setItem('userData', JSON.stringify(temp));
        setOpenPopup(false)
        window.location.reload();
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <div className='flex flex-col gap-4'>
                    <CustomizeTextField
                        placeholder='Please enter enterprise name'
                        defaultValue={accountName}
                        variant='outlined'
                        label='Enterprise Name'
                        name='entName'
                        {...register("entName", { required: 'Please add enterprise name' })}
                        error={Boolean(errors.entName)}
                        helperText={errors.entName?.message}

                    />
                    <CustomizeTextField
                        placeholder='Please enter description'
                        defaultValue={description}
                        variant='outlined'
                        multiline
                        rows={3}
                        label='Short Description'
                        name='description'
                        {...register("description", { required: 'Please add description' })}
                        error={Boolean(errors.description)}
                        helperText={errors.description?.message}
                    />
                </div>
                <div className='flex gap-4'>

                    <div class="flex items-center justify-center">
                        <label for="dropzone-file_dp" class="flex flex-col items-center justify-center w-full h-30 border-2 border-gray-300 hover:border-[#016FE2] border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                            <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Profile Image</span></p>
                                <svg aria-hidden="true" class="w-10 h-6 text-[#016FE2]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                </svg>
                                <p class=" text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span></p>
                                <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                {profile_pic && (
                                    <div className='bg-themeBlue-5'>
                                        <img className='w-16 h-10' src={profile_pic} alt='image' />
                                    </div>
                                )}
                            </div>
                            <input
                                id="dropzone-file_dp"
                                type="file"
                                class="hidden"
                                onChange={(e) => {
                                    handleProfilePic(e)
                                    setProfilePicReader(e.target.files[0]);
                                }}
                            />
                        </label>
                    </div>
                    <div class="flex items-center justify-center">
                        <label for="dropzone-file_logo" class="flex flex-col items-center justify-center w-full h-30 border-2 border-gray-300 hover:border-[#016FE2] border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                            <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Enterprise Logo</span></p>
                                <svg aria-hidden="true" class="w-10 h-6 text-[#016FE2]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                </svg>
                                <p class=" text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span></p>
                                <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                {imageValue && (
                                    <div className='bg-themeBlue-5'>
                                        <img className='w-16 h-10' src={imageValue} alt='image' />
                                    </div>
                                )}
                            </div>
                            <input
                                id="dropzone-file_logo"
                                type="file"
                                class="hidden"
                                onChange={(e) => {
                                    handleLogo(e)
                                    setLogoReader(e.target.files[0]);
                                }}
                            />
                        </label>
                    </div>

                </div>

            </div>

            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <div className='flex flex-col gap-3'>
                    <CustomizeTextField
                        placeholder='Please enter your name'
                        defaultValue={regName}
                        variant='outlined'
                        label='Registrant Name'
                        name='regName'
                        {...register("regName", { required: 'Please add registrant name' })}
                        error={Boolean(errors.regName)}
                        helperText={errors.regName?.message}

                    />
                    <CustomizeTextField
                        className='w-full'
                        placeholder='Autofill'
                        variant='outlined'
                        value={districtValue}
                        label='City / District'
                        name='city'
                        {...register("city", { required: 'City is required.' })}

                    />
                </div>

                <div className='flex flex-col gap-3'>
                    <Stack spacing={2} sx={{ color: '#851851' }}>
                        <Autocomplete
                            error={Boolean(errors.pin)}
                            freeSolo
                            options={options}
                            onInputChange={onInputChange}
                            onChange={(event, value) => {
                                getValue(value)
                            }}

                            getOptionLabel={(option) => option.label}
                            defaultValue={{ label: pinCode }}
                            renderInput={(params) => (
                                <CustomizeTextField
                                    {...params}
                                    label='PIN Code'
                                    name='pin'
                                    {...register("pin", { required: 'PIN is required.' })}
                                    error={Boolean(errors.pin)}
                                    helperText={errors.pin?.message}
                                />
                            )}

                        />
                    </Stack>
                    <CustomizeTextField
                        className='w-full'
                        placeholder='Autofill'
                        variant='outlined'
                        value={stateValue}
                        label='State'
                        name='state'
                        {...register("state", { required: 'State is required.' })}

                    />
                </div>

            </div>
            <div className="grid gap-4">
                <CustomizeTextField
                    className='w-full'
                    defaultValue={addressLine}
                    placeholder='Please enter your address'
                    variant='outlined'
                    multiline
                    rows={2}
                    label='Address'
                    name='address'
                    {...register("address", { required: 'Please add address' })}
                    error={Boolean(errors.address)}
                    helperText={errors.address?.message}
                />
                <CustomizeTextField
                    className='w-full'
                    defaultValue={upiId}
                    placeholder='Please enter UPI address'
                    variant='outlined'
                    label='UPI Address'
                    name='upi'
                    {...register("upi", { required: 'Please add upi address' })}
                    error={Boolean(errors.upi)}
                    helperText={errors.upi?.message}

                />
            </div>

            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <BtnDefault
                    variant="contained"
                    color="primary"
                    size="medium"
                    text="Submit"
                    type="submit"
                />
                <BtnOutlined
                    variant="outlined"
                    color="error"
                    size="medium"
                    text="Cancel"
                    onClick={() => setOpenPopup(false)}
                />
            </div>

        </form>
    )
}

export default EditProfile