import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { useForm } from "react-hook-form";
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import BtnOutlined, { BtnDefault } from '../BtnComponent';
import getPinValue from '../../CommonFunctions/getPinValue';
import { addNewCustomer, getCustomerDetail } from '../../StateMgmt/Actions/customerActions';
import { useNavigate } from 'react-router-dom';

const Add_customer = ({ setOpenPopup, customerNumber, searchFlag }) => {
    const navigate = useNavigate();
    const [options, setOptions] = useState([]);
    const [stateValue, setStateValue] = useState('');
    const [districtValue, setDistrictValue] = useState('');

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const onInputChange = (event, value, reason) => {
        if (value) {
            getPinValue(value, setOptions);
        } else {
            setOptions([]);
        }
    };

    const getValue = (value, field) => {
        setDistrictValue(value.value.district);
        setStateValue(value.value.state)
    }

    const onSubmit = async (data) => {
        const reqBody = data;
        reqBody.phoneNumber = data.phoneNumber ?? customerNumber;
        reqBody.city = districtValue;
        reqBody.state = stateValue;
        const customerRes = await addNewCustomer(reqBody);
        const { payload } = await getCustomerDetail(customerRes.payload.id);
        navigate(`/customerdetails/${customerRes.payload.id}`, { state: payload })
        setOpenPopup(false);
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <CustomizeTextField
                    className='w-full'
                    placeholder='Please enter customer name'
                    variant='outlined'
                    label='Customer Name'
                    name='name'
                    {...register("name", { required: 'Name is required.' })}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                />
                <CustomizeTextField
                    // disabled={searchFlag === 'PHONE_NUMBER' ? true : false}
                    className='w-full'
                    placeholder='Please enter customer phone'
                    variant='outlined'
                    label='Customer Number'
                    defaultValue={customerNumber}
                    name='phoneNumber'
                    {...register("phoneNumber", { required: 'Phone number is required.' })}
                    error={Boolean(errors.phoneNumber)}
                    helperText={errors.phoneNumber?.message}
                />

            </div>
            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <div className='flex flex-col gap-3'>
                    <Stack spacing={2} sx={{ color: '#851851' }}>
                        <Autocomplete
                            error={Boolean(errors.role)}
                            freeSolo
                            options={options}
                            onInputChange={onInputChange}
                            onChange={(event, value) => {
                                getValue(value)
                            }}

                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                                <CustomizeTextField
                                    {...params}
                                    label='PIN Code'
                                    name='pinCode'
                                    {...register("pinCode", { required: 'PIN is required.' })}
                                    error={Boolean(errors.pinCode)}
                                    helperText={errors.pinCode?.message}
                                />
                            )}

                        />
                    </Stack>
                    <CustomizeTextField
                        className='w-full'
                        variant='outlined'
                        value={districtValue}
                        label='City / District'
                        name='city'
                    />
                    <CustomizeTextField
                        className='w-full'
                        variant='outlined'
                        value={stateValue}
                        label='State'
                        name='state'
                    />
                </div>

                <CustomizeTextField
                    placeholder='Please enter customer address'
                    variant='outlined'
                    multiline
                    rows={7}
                    label='Customer Address'
                    name='addressLine'
                    {...register("addressLine", { required: 'Address is required.' })}
                    error={Boolean(errors.addressLine)}
                    helperText={errors.addressLine?.message}
                />

            </div>


            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <BtnDefault
                    variant="contained"
                    color="primary"
                    size="medium"
                    text="Submit"
                    type="submit"
                />
                <BtnOutlined
                    variant="outlined"
                    color="error"
                    size="medium"
                    text="Cancel"
                    onClick={() => setOpenPopup(false)}
                />
            </div>

        </form>
    )
}

export default Add_customer