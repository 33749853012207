import React, { useState, useEffect } from 'react'
import user_img from '../../../../Assets/user.jpg'
import brandLogo from '../../../../Images/grafdoer.jpg'
import { getTechnicianIDCard } from '../../../../StateMgmt/Actions/technicianActions'


const IDCard = () => {
    const [cardDetails, setCardDetails] = useState({});
    useEffect(() => {
        getTechnicianIDCard().then(({ payload }) => {
            setCardDetails(payload);
        })
    }, [])
    const { logo, entName, firstName, lastName, phoneNo, pinCode, city, state, country, addressLine, profilePic, idType, idnumber } = cardDetails;
    const address = `${addressLine} ${city} ${pinCode} ${state}`;
    const Name = [firstName, lastName].filter(Boolean).join(' ');
    return (
        <div className='flex flex-col items-center bg-white rounded-lg px-12 border border-themeBlue-2 hover:bg-themeBlue-6 hover:shadow-themeBlue-2 shadow-xl shadow-themeBlue-4'>
            <div className='flex pb-6'>
                <img src={logo} alt='' />
            </div>
            <div className='relative'>
                <img src={profilePic || user_img}
                    alt='avatar'
                    className='h-28 ring-2 ring-offset-4 hover:ring-offset-8 hover:ring-themeBlue-1 rounded-full bg-slate-200 cursor-pointer' />
            </div>
            <div className='text-gray-600 font-semibold text-xl mt-4'>{Name}</div>
            <div className='text-gray-400 text-md'></div>
            <div className='bg-themeBlue-4 dark:bg-gray-600 h-0.5 mt-2'></div>

            {/* <div className='flex justify-between items-center my-4 gap-5'></div> */}
            <div className='flex flex-col my-4 gap-2'>
                <div className='flex mx-4 space-x-5 justify-center items-center font-semibold'>
                    {/* <div className='text-gray-500'>Phone</div> */}
                    <div className='text-themeBlue-7 font-bold'>{phoneNo}</div>
                </div>
                <div className='flex mx-4 space-x-5 justify-center items-center font-semibold'>
                    {/* <div className='text-gray-500'>Address</div> */}
                    <div className='text-themeBlue-7 font-bold'>{address}</div>
                </div>
                <div className='flex mx-4 space-x-5 justify-center items-center font-semibold'>
                    <div className='text-gray-500'>{idType}</div>
                    <div className='text-themeBlue-7 font-bold'>{idnumber}</div>
                </div>
                <div className='bg-themeBlue-4 dark:bg-gray-600 w-full h-0.5 mt-2'></div>

                <div className='flex mx-4 space-x-5 justify-center items-center font-semibold'>
                    {/* <div className='text-gray-500'>UPI Address</div> */}
                    <div className='text-themeBlue-7 text-xs font-bold'>{entName}</div>
                </div>
            </div>
        </div>

    )
}

export default IDCard