import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import BtnOutlined, { BtnDefault } from '../../Components/BtnComponent';
import downloadGeneratedQR from '../../CommonFunctions/downloadGeneratedQR';


const GenerateQRForm = ({ setOpenPopup, productId }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const onSubmit = async (data) => {
        data.count = +data.count;
        const reqBody = data;
        reqBody.productId = +productId;
        const resData = await downloadGeneratedQR(reqBody)
        console.log(resData)
        setOpenPopup(false)

    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-2/3'>
            <div className="grid gap-4 grid-cols-1 gap-x-8 ">
                <CustomizeTextField
                    type="number"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    placeholder='Enter QR count'
                    variant='outlined'
                    label='Enter the number of QR to be generated'
                    name='count'
                    {...register("count", { required: 'Number is required' })}
                    error={Boolean(errors.count)}
                    helperText={errors.count?.message}
                />
            </div>

            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <BtnDefault
                    variant="contained"
                    color="primary"
                    size="medium"
                    text="Generate"
                    type="submit"
                />
                <BtnOutlined
                    variant="outlined"
                    color="error"
                    size="medium"
                    text="Cancel"
                    onClick={() => setOpenPopup(false)}
                />
            </div>
        </form>
    )
}

export default GenerateQRForm