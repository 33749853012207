import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const CustomTextField = styled(TextField)({
    '& .MuiInputLabel-root':{
        color: '#fff'
    },
    '& label.Mui-focused': {
        color: '#fff',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#fff',
    },
    '& .MuiAutocomplete-input': {
        color: '#fff',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#fff',
        },
        '&:hover fieldset': {
            borderColor: '#fff',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#fff',
        },
    },
});


export default CustomTextField;

export const CustomizeTextField = styled(TextField)({
    '& .MuiInputLabel-root':{
        color: '#153B8C'
    },
    '& label.Mui-focused': {
        color: '#153B8C',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#153B8C',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#153B8C',
        },
        '&:hover fieldset': {
            borderColor: '#153B8C',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#153B8C',
        },
    },
});
