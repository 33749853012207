import React, { useMemo, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getTechnicianCallPendingTable } from '../../StateMgmt/Actions/StatChartActions';



const CallPendingTable = () => {
    const [pageSize, setPageSize] = useState(10);
    const [rowId, setRowId] = useState(null);
    const [tableData, setTableData] = useState([])
    useEffect(() => {
        getTechnicianCallPendingTable().then(({ payload }) => {
            setTableData(payload);
        });
    }, []);
    const columns = useMemo(() => [
       
        {
            field: 'name',
            headerName: 'Technicia Name',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'completedInTime',
            headerName: '3 Days ',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'completedOutTime',
            headerName: '3+ Days',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'totalCount',
            headerName: 'Total Calls',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        
    ], [rowId])

    return (
        <div className='col-span-1'>
            <span className='flex justify-center text-2xl font-sans font-bold'>Techinican Calls Completed Time</span>
            <Box
                sx={gridStyle}>

                <DataGrid
                    columns={columns}
                    rows={tableData}
                    getRowId={(row) => row.id}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowSpacing={params => ({
                        top: params.isFirstVisible ? 0 : 5,
                        bottom: params.isLastVisible ? 0 : 5
                    })}
                />
            </Box>
        </div>
    )
}

export default CallPendingTable

const gridStyle = {

    backgroundColor: '#fff',
    height: 600,
    padding: 2,
    width: '100%',
    '& .MuiDataGrid-root': {
        borderColor: '#153b8c',
        '& .MuiDataGrid-row': {
            borderColor: '#aed1f6',
            '&:hover': {
                bgcolor: '#ebf4fd',
            },
            '&.Mui-selected': {
                bgcolor: '#c2ddf8',
                '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: '#aed1f6',
                    borderColor: '#aed1f6',
                },
            }

        }
    },
    '& .MuiDataGrid-columnHeaders': {
        borderColor: '#153b8c',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        color: '#153b8c',
        fontSize: 16,
        fontWeight: 500,
        fontFamily: "sans-serif",
    },
}