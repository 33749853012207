import React from 'react';
import { Box } from '@mui/material';
import { AssignmentInd, Preview, PostAdd, YoutubeSearchedFor, MarkChatRead } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ActionIcon from './ActionIcon';



const ComplaintActions = ({ setOpenPopup, setAction, setStatus, params }) => {
    const iconFlag = params.row.status;

    const handleAction = () => {
        if (iconFlag === 'UNASSIGNED') {
            setAction('Assign');
            setOpenPopup(true);
            setStatus('UNASSIGNED');
        };
        if (iconFlag === 'ASSIGNED') {
            setAction('CheckStatus');
            setOpenPopup(true);
            setStatus('ASSIGNED');
        }
        if (iconFlag === 'PART PENDING') {
            setAction('PartPendingInputs');
            setOpenPopup(true);
            setStatus('PART PENDING');
        }
        if (iconFlag === 'REPLACEMENT') {
            setAction('ReplacementInputs');
            setOpenPopup(true);
            setStatus('REPLACEMENT');
        }
        if (iconFlag === 'IN PROGRESS') {
            setAction('CheckStatus');
            setOpenPopup(true);
            setStatus('IN PROGRESS');
        }
        if (iconFlag === 'COMPLETED') {
            setAction('Completed');
            setOpenPopup(true);
            setStatus('COMPLETED');
        }
        if (iconFlag === 'CLOSED') {
            setAction('Closed');
            setOpenPopup(true);
            setStatus('CLOSED');
        }
        if (iconFlag === 'REJECTED') {
            setAction('Rejected');
            setOpenPopup(true);
            setStatus('REJECTED');
        }

    };

    return (
        <>
            <Box
                sx={{
                    m: 1,   //margin will be from all side
                    position: 'relative'
                }}
            >
                {iconFlag === 'UNASSIGNED' && <ActionIcon title='Assign Complaint' icon={<AssignmentInd />} handler={handleAction} />}
                {iconFlag === 'CLOSED' && <ActionIcon title='View Details' icon={<Preview />} handler={handleAction} />}
                {iconFlag === 'COMPLETED' && <ActionIcon title='Add Inputs & Close Call' icon={<MarkChatRead />} handler={handleAction} />}
                {iconFlag === 'REJECTED' && <ActionIcon title='View Details' icon={<Preview />} handler={handleAction} />}
                {iconFlag === 'PART PENDING' && <ActionIcon title='Add Inputs' icon={<PostAdd />} handler={handleAction} />}
                {iconFlag === 'ASSIGNED' && <ActionIcon title='Check Status' icon={<YoutubeSearchedFor />} handler={handleAction} />}
                {iconFlag === 'IN PROGRESS' && <ActionIcon title='Check Status' icon={<YoutubeSearchedFor />} handler={handleAction} />}
                {iconFlag === 'REPLACEMENT' && <ActionIcon title='Add Inputs' icon={<PostAdd />} handler={handleAction} />}
                {/* {iconFlag === 'pending' && <Action Item, pending , haii +++ 'icon'/>} */}

            </Box>

        </>
    )
}

export default ComplaintActions;


